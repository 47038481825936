import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { RoleService } from './role.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [RoleService]
})
export class SecurityModule {}
