import { formatCurrency, formatDate, formatNumber } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import parsePhoneNumberFromString from 'libphonenumber-js';

/**
 * Transforms values for every table cell into the appropriate data according to it's type (blank, date, etc...)
 */
@Pipe({
  name: 'tableCell'
})
export class TableCellPipe implements PipeTransform {
  constructor() {}

  transform(value: any, keyName: string): any {
    if (value === undefined) return '-'; // always keep it first as a guard for missing field in API response.
    if (
      keyName === 'title' ||
      keyName === 'name' ||
      keyName === 'template_name' ||
      keyName === 'description' ||
      keyName === 'first_name' ||
      keyName === 'last_name' ||
      keyName === 'email'
    ) {
      return value;
    }
    if (keyName === 'fax') {
      return value ? parsePhoneNumberFromString(value).formatInternational() : value;
    }
    if (keyName === 'source' && value === 'raven-cloud') return 'raven cloud';
    if (keyName === 'total_file_size') return `${value.total} GB`;
    if (keyName === 'size_in_mb') return `${value.toFixed(2)} MB`;
    if (keyName === 'total') return formatCurrency(value, 'en', '$');
    if (keyName === 'mime_type') return value.slice(value.indexOf('/') + 1).toUpperCase(); // PDF, JPEG, TIFF
    if (typeof value === 'number') return formatNumber(value, 'en');
    if (!this.isDate(value)) return value;
    return formatDate(value, 'MMMM d, y, h:mm a', 'en-US');
  }

  /**
   * Determines if a value is in the date format.
   * Date Format: 2017-06-07
   *
   * @param value The value to check.
   * @return true if a date, false if not.
   */
  private isDate(value: string): boolean {
    return /^([0-9]{4}-[0-9]{2}-[0-9]{2})(.*)$/.test(value);
  }
}
