import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Folder } from '@api/types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ArchiveService {
  constructor(private http: HttpClient) {}

  /**
   * Archive the folder
   *
   * @summary `POST /folders/{folder_id}/archive`
   */
  post(folderId: string): Observable<Folder> {
    return this.http.post<Folder>(`/folders/${folderId}/archive`, {});
  }

  /**
   * Unarchive the folder
   *
   * @summary `PATCH /folders/{folder_id}/archive`
   */
  patch(folderId: string): Observable<Folder> {
    return this.http.patch<Folder>(`/folders/${folderId}/archive`, { folderId });
  }
}
