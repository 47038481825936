import { CollectionViewer, SelectionChange } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Injector } from '@angular/core';
import { ApiService } from '@api/api.service';
import { HttpParamsHelper } from '@api/http-params-helper';
import { Folder, FolderPaginator, FolderWithPaginatedChildrenAndDocuments } from '@api/types';
import { FolderReferenceService } from '@shared/services/folder-reference.service';
import { HttpMethod, HttpQueueService } from '@shared/services/http-queue.service';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { ensure } from '@shared/utils';
import { BehaviorSubject, merge, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class FolderNode {
  constructor(
    public id: string,
    public title: string,
    public level = 1,
    public parentId?: string,
    public children?: FolderNode[],
    public isActive?: boolean
  ) {}
}

export class FolderMenuDataSource {
  api: ApiService;
  folderReference: FolderReferenceService;
  localStorage: LocalStorageService;
  httpQueue: HttpQueueService;

  getParams = { per_page: 1000, order_by: 'title', asc: 'asc', type: 'folder' };

  dataChange = new BehaviorSubject<FolderNode[]>([]);
  nodes: FolderNode[] = [];
  rootNode: FolderNode;
  inboxNode: FolderNode;

  get data(): FolderNode[] {
    return this.dataChange.value;
  }
  set data(value: FolderNode[]) {
    this.treeControl.dataNodes = value;
    this.dataChange.next(value);
  }

  constructor(injector: Injector, private treeControl: FlatTreeControl<FolderNode>) {
    this.api = injector.get(ApiService);
    this.folderReference = injector.get(FolderReferenceService);
    this.localStorage = injector.get(LocalStorageService);
    this.httpQueue = injector.get(HttpQueueService);
    this.setInbox();
  }

  async setInbox() {
    const inbox = await this.folderReference.getInbox();
    this.inboxNode = new FolderNode(inbox.id, 'Inbox', 0, '', []);
  }

  /**
   * Used by MatTree to connect to the dataSource
   *
   * @param collectionViewer TreeControl's view of data
   */
  connect(collectionViewer: CollectionViewer): Observable<FolderNode[]> {
    this.treeControl.expansionModel.changed.subscribe((change) => {
      if ((change as SelectionChange<FolderNode>).added || (change as SelectionChange<FolderNode>).removed) {
        this.handleTreeControl(change as SelectionChange<FolderNode>);
      }
    });
    return merge(collectionViewer.viewChange, this.dataChange).pipe(
      map(() => this.data)
    );
  }

  /**
   * Handle expand/collapse behaviors
   *
   * @param change Event emitted when the value of a MatSelectionModel has changed.
   */
  handleTreeControl(change: SelectionChange<FolderNode>) {
    if (change.added) {
      change.added.forEach((node) => this.toggleNode(node, true));
    }
    if (change.removed) {
      change.removed
        .slice()
        .reverse()
        .forEach((node) => this.toggleNode(node, false));
    }
  }

  /**
   * Toggle the node, remove from display list
   *
   * @param node node to toggle
   * @param expand whether we are expanding or collapsing
   */
  toggleNode(node: FolderNode, expand: boolean) {
    const index = this.data.indexOf(node);
    if (!node.children || index < 0) {
      // If no children, or cannot find the node, no op
      return;
    }

    // If we are expanding the node and the node's children's children haven't been loaded yet, asynchronously
    // load them so the tree control knows whether or not the child nodes are expandable.
    if (expand) {
      node.children.forEach((childNode) => {
        if (!childNode.children) this.loadChildren(childNode);
      });
    }

    if (expand) {
      const nodes = node.children;
      this.data.splice(index + 1, 0, ...nodes);
    } else {
      let count = 0;
      for (let i = index + 1; i < this.data.length && this.data[i].level > node.level; i++, count++) {}
      this.data.splice(index + 1, count);
    }

    // notify the change
    this.dataChange.next(this.data);
  }

  /**
   * Loads the top folder of the folder tree structure
   */
  loadTopFolder(forceReload: boolean = false) {
    this.nodes = [];
    this.api.accounts.byId.topFolder
      .get(this.getParams, this.localStorage.userWithToken.account_id)
      .subscribe((paginatedGet: FolderWithPaginatedChildrenAndDocuments) => {
        this.rootNode = new FolderNode(paginatedGet.id, paginatedGet.title, 0, null, []);
        this.nodes.push(this.rootNode);
        this.localStorage.folderTree = this.nodes;
        // Loop through all the 1st level children and loads it's children
        paginatedGet.content.data.forEach((child) => {
          this.addChildNode(child, this.rootNode);
        });
        if (forceReload || this.data.length === 0) {
          this.data = [this.rootNode];
        }
      });
  }

  /**
   * Loads children folders of inputted node. Adds requests to queue to be executed to
   * not bog down the rest of the app. 10 requests will be processed at a time.
   *
   * @param parent FolderNode to load children for
   */
  loadChildren(parent: FolderNode): void {
    this.httpQueue
      .invoke(
        `/folders/${parent.id}/children`,
        HttpMethod.GET,
        {},
        { params: HttpParamsHelper.getHttpQueryParams(this.getParams) }
      )
      .subscribe((paginatedGet: FolderPaginator) => {
        parent.children = [];
        paginatedGet.data.forEach((child) => {
          this.addChildNode(child, parent);
        });
      });
  }

  /**
   * Creates new FolderNode to add to the tree structure. A dataChange event is triggered and then we update the
   * folderTree in localStorage. Then, we load the children of the newly created child node.
   *
   * @param child folder to create new FolderNode for
   * @param parent parent FolderNode to add the new child FolderNode reference to
   */
  addChildNode(child: Folder, parent: FolderNode): FolderNode {
    if (!parent || child.type !== 'folder') {
      return;
    }
    const newNode = new FolderNode(child.id, child.title, parent.level + 1, parent.id);
    parent.children = ensure.array(parent.children);
    parent.children.push(newNode);
    parent.children.sort((a, b) => (a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1));
    this.nodes.push(newNode);
    this.localStorage.folderTree = this.nodes;
    this.dataChange.next(this.data);
    return newNode;
  }

  /**
   * Adds new folder node to the tree view
   *
   * @param child folder node to add to view
   * @param parent parent FolderNode to add the new child FolderNode reference to
   */
  addNodeToView(child: FolderNode, parent: FolderNode) {
    const treeNode = this.treeControl.dataNodes.find((node) => node.id === parent.id);
    if (!treeNode) return;
    if (treeNode !== parent && child) {
      treeNode.children.push(child);
      treeNode.children.sort((a, b) => (a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1));
      this.dataChange.next(this.data);
    }
    this.refreshNodeView(treeNode);
  }

  /**
   * Removes node from the data after deletion/archival
   *
   * @param child folder to find node for to remove
   * @param parent parent FolderNode to remove child reference from
   */
  removeChildNode(child: Folder, parent: FolderNode) {
    if (!parent || child.type !== 'folder') return;
    parent.children = parent.children.filter((node) => node.id !== child.id);
    this.nodes = this.nodes.filter((node) => node.id !== child.id);
    this.localStorage.folderTree = this.nodes;
    this.dataChange.next(this.data);
  }

  /**
   * Removes node from the tree view
   *
   * @param child folder to find node for to remove
   * @param parent parent FolderNode to remove child reference from
   */
  removeNodeFromView(child: Folder, parent: FolderNode) {
    this.treeControl.dataNodes.filter((node) => node.id !== child.id);
    const treeNode = this.treeControl.dataNodes.find((node) => node.id === parent.id);
    if (treeNode) treeNode.children = treeNode.children.filter((node) => node.id !== child.id);
    this.dataChange.next(this.data);
    this.refreshNodeView(treeNode);
  }

  /**
   * For the inputted node, gets a reference to the tree control's node of the same ID and expands it.
   * (We must get reference to tree control's version of node in case they aren't aligned in memory)
   *
   * @param nodeToExpand The node to expand
   */
  expandNode(nodeToExpand: FolderNode) {
    if (!nodeToExpand) {
      return;
    }

    const treeControlNode = this.treeControl.dataNodes.find((node) => node.id === nodeToExpand.id);
    if (treeControlNode && treeControlNode.children && treeControlNode.children.length > 0) {
      this.treeControl.expand(treeControlNode);
    }
  }

  /**
   * Refreshes the tree's UI by collapsing and expanding an open parent node. This is how we update the tree
   * when a node is added or removed to the tree structure due to limitations in the MatTree's change detection.
   * We don't want to toggle the nodes recursively as it causes a UI bug (the nodes show as expanded when they are really collapsed)
   *
   * @param node The tree node that we are toggling to display its children
   */
  refreshNodeView(node: FolderNode) {
    // collapse children first to avoid arrow pointing wrong way bug
    node.children.forEach((child) => {
      this.treeControl.collapse(child);
    });
    this.treeControl.toggle(node);
    this.treeControl.expand(node);
  }

  /**
   * Recursively expand all parents of the passed node.
   *
   * @param node The node to expand parents for
   */
  expandParentsRecursively(node: FolderNode) {
    if (node && node.parentId) {
      const parentNode = this.getNodeById(node.parentId);
      this.expandParentsRecursively(parentNode);
      this.expandNode(parentNode);
      return parentNode;
    }
  }

  /**
   * Gets the reference to a FolderNode by folderId. Loads the node from storage if it has not yet been processed by the loadChildren tree.
   *
   * @param folderId id of folder of desired FolderNode
   */
  getNodeById(folderId: string): FolderNode {
    return (
      this.nodes.find((node) => node.id === folderId) ||
      this.localStorage.folderTree.find((node) => node.id === folderId)
    );
  }

  /**
   * Gets the root node. Gets it from storage and sets if it has not yet been set.
   */
  getRootNode(): FolderNode {
    this.rootNode = this.rootNode || this.localStorage.folderTree.find((node) => node.level === 0);
    return this.rootNode;
  }

  /**
   * Rename the folder name on the side bar
   *
   * @param folderNode Current folder node
   */
  renameNode(folderNode: FolderNode) {
    this.expandParentsRecursively(folderNode);

    let folderToRename: FolderNode = null;
    let treeNode: FolderNode = null;

    folderToRename = this.nodes.find((node) => node.id === folderNode.id);

    if (folderToRename) {
      folderToRename.title = folderNode.title;
    }

    treeNode = this.treeControl.dataNodes.find((node) => node.id === folderToRename.id);

    if (treeNode) {
      treeNode.title = folderNode.title;
    }

    this.localStorage.folderTree = this.nodes;
    this.dataChange.next(this.data);
  }
}
