import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { EnvironmentType } from '@shared/types/environment';
import { createClient, Entry } from 'contentful';

@Injectable({
  providedIn: 'root'
})
export class ContentfulService {
  private client = createClient({
    space: environment.contentful.space,
    accessToken: environment.contentful.accessToken,
    environment: environment.type === EnvironmentType.Production ? 'master' : 'dev'
  });

  constructor() {}

  getContactInfo(): Promise<Entry<any>[]> {
    return this.client
      .getEntries({
        content_type: 'contact'
      })
      .then((res) => res.items);
  }

  getSocialMediaLinks() {
    return this.client.getEntries({ content_type: 'socialMediaItem' }).then((res) => res.items);
  }

  getCloudPlans(): Promise<any> {
    return this.client.getEntries({ content_type: 'cloudFeaturesPage' }).then((res) => res.items[0].fields['cloudPlans']);
  }

  getTrialNoticeMessage(): Promise<string> {
    return this.client.getEntry('5Na2ykr7eAnQiCd7RYxmSZ').then((res) => res.fields['description']);
  }

  getSubscriptionExpiredMessage(): Promise<string> {
    return this.client.getEntry('3WLyMzUcMeRf7taWj7RYJc').then((res) => res.fields['description']);
  }
}
