import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AccountE2eEncryption } from '@api/types';
import { EncryptionHelperService } from '@shared/services/encryption-helper.service';
import * as Sodium from 'libsodium-wrappers';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const URLS_TO_ENCRYPT: Array<string> = [`https:\/\/storage\.googleapis\.com\/\S*`, `\/gcs_upload\/[a-zA-Z0-9]*`];
const URLS_TO_DECRYPT: Array<string> = [`\/documents\/[a-zA-Z0-9]*\/download`];

@Injectable()
export class EncryptionInterceptor implements HttpInterceptor {
  constructor(private encryptionHelper: EncryptionHelperService) {}

  /**
   * Interceptor that when e2e Encryption is enabled, it takes the file attached to it
   * and encrypts/ decrypts it accordingly
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const skipE2EEHeader = request.headers.get('skipE2EE');
    const newReq = request.clone({ headers: request.headers.delete('skipE2EE') });

    if (
      this.encryptionHelper.accountEncryptionEnabled === AccountE2eEncryption.Active &&
      !(skipE2EEHeader === 'true')
    ) {
      switch (newReq.method) {
        case 'GET':
          for (const address of URLS_TO_DECRYPT) {
            if (new RegExp(address).test(newReq.url)) {
              // capture and decrypts RESPONSE
              return from(this.handleDecryption(newReq, next));
            }
          }
          break;
        case 'POST':
          if (new RegExp(`\/documents\/uploads`).test(newReq.url)) {
            return from(this.changeUploadFileType(newReq, next));
          }
          break;
        case 'PUT':
          for (const address of URLS_TO_ENCRYPT) {
            if (new RegExp(address).test(newReq.url)) {
              // capture and encrypts REQUEST
              return from(this.handleEncryption(newReq, next));
            }
          }
          break;
        default:
          break;
      }
    }
    return next.handle(newReq);
  }

  /**
   * This method subscribes to a request's response, takes its file and replaces it with a decrypted version of it.
   * The mime_type of the file is taken from a custom header intended to be used only in this method, so it is deleted
   * before continuing.
   */
  private async handleDecryption(request: HttpRequest<any>, next: HttpHandler) {
    const mimeType = request.headers.get('mime_type');
    const newReq = request.clone({
      headers: new HttpHeaders({ mime_type: 'application/octet-stream' })
    });
    return next
      .handle(newReq)
      .pipe(
        map(async (response: HttpEvent<any>) => {
          if (response instanceof HttpResponse) {
            const encryptionKey = response.headers.get('x-encryption-key');
            const fileBlob = await this.encryptionHelper.handleFileDecryption(
              response.body,
              mimeType ? mimeType : 'application/pdf',
              encryptionKey
            );
            response = response.clone({ body: fileBlob });
          }
          return response;
        })
      )
      .toPromise();
  }

  /**
   * This method takes a request's body containing a file and replaces it with a encrypted version of it.
   * it adds on the response
   */
  private async handleEncryption(request: HttpRequest<any>, next: HttpHandler) {
    const file = request.body;
    const fileKey = request.headers.get('fileKey');
    const encryptedFile = await this.encryptionHelper.handleFileEncryption(file, fileKey);
    const encryptionKeys = JSON.stringify({
      global: Sodium.to_base64(encryptedFile.key, Sodium.base64_variants.URLSAFE_NO_PADDING)
    });

    const newReq = request.clone({
      body: new Blob([encryptedFile.contents], { type: 'application/octet-stream' }),
      headers: request.headers.delete('fileKey')
    });
    return next
      .handle(newReq)
      .pipe(
        map((response: HttpEvent<any>) => {
          if (response instanceof HttpResponse) {
            response = response.clone({ body: { ...response.body, encryptionKeys } });
          }
          return response;
        })
      )
      .toPromise();
  }

  /**
   * For URL 'documents/uploads', when e2e encryption is activated, we should change the mimeType
   * of the upload request from whatever type is set to 'application/octet-stream' as this is the type of the file
   * that will be uploaded instead when the file will be encrypted
   */
  private changeUploadFileType(request: HttpRequest<any>, next: HttpHandler) {
    const newReq = request.clone({
      body: { ...request.body, mime_type: 'application/octet-stream' }
    });
    return next.handle(newReq).toPromise();
  }
}
