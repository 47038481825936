import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AccountSettingsKeys } from '@shared/types/settings-keys';
import { SettingsByKeyService } from './byKey/settings-by-key.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  constructor(private http: HttpClient, public byKey: SettingsByKeyService) {}

  /**
   * Returns all of the settings for the requested account.
   *
   * @summary `GET /accounts/{account_id}/settings`
   */
  get(accountId: string): Observable<any> {
    return this.http.get<any>(`/accounts/${accountId}/settings`);
  }

  /**
   * Sets the settings for the account. This will merge the new settings into the current settings.
   *
   * @summary `PATCH /accounts/{account_id}/settings`
   */
  patch(accountId: string, settingKey: AccountSettingsKeys, value: any): Observable<any> {
    // Creates an object to send to the api
    const settingObject: any = {};
    settingObject[settingKey] = value;

    return this.http.patch<any>(`/accounts/${accountId}/settings`, settingObject);
  }
}
