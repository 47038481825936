import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AccountStorageTotals } from '@api/types';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StorageTotalsService {
  constructor(private http: HttpClient) {}

  /**
   * Returns the storage usage for the requested account.
   *
   * @summary `GET /accounts/{account_id}/storage_totals`
   */
  get(accountId: string): Observable<any> {
    return this.http.get<AccountStorageTotals>(`/accounts/${accountId}/storage_totals`);
  }
}
