import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Account, AccountCreate, AccountsGetParams, NewAccount, PaginationGet } from '@api/types';
import { Observable } from 'rxjs';
import { HttpParamsHelper } from '../http-params-helper';
import { AccountByIdService } from './byId/account-by-id.service';
import { CardsService } from './cards.service';
import { PlansService } from './plans.service';
import { SendService } from './send/send.service';
import { TokenService } from './token/token.service';

@Injectable({
  providedIn: 'root'
})
export class AccountsService {
  constructor(
    private http: HttpClient,
    public send: SendService,
    public token: TokenService,
    public byId: AccountByIdService,
    public plans: PlansService,
    public cards: CardsService
  ) {}

  /**
   * Creates a new account, with an initial user.
   *
   * @summary `POST /accounts`
   */
  post(body: AccountCreate): Observable<NewAccount> {
    return this.http.post<NewAccount>('/accounts', body);
  }

  /**
   * Mixes in pageable and orderable. Return an array of accounts.
   *
   * @summary `GET /accounts`
   */
  get(params?: AccountsGetParams): Observable<PaginationGet<Account>> {
    return this.http.get<PaginationGet<Account>>('/accounts', {
      params: HttpParamsHelper.getHttpQueryParams(params)
    });
  }
}
