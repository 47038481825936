export enum SingleDocCreateSource {
  Scanner = 'scanner',
  Import = 'import',
  Email = 'email',
  Fax = 'fax',
  Desktop = 'desktop',
  Mobile = 'mobile'
}

/**
 * Single Document
 *
 * Represents a single document
 */
export interface SingleDocCreate {
  title?: string;
  page_ids: string[];
  parent_id?: string;
  template_id?: string;
  source: SingleDocCreateSource;
  ocr?: boolean;
}
