export enum Scope {
  AccountRead = 'account-read',
  AccountWrite = 'account-write',
  NoteRead = 'note-read',
  NoteWrite = 'note-write',
  UserRead = 'user-read',
  UserWrite = 'user-write',
  UserDelete = 'user-delete',
  FolderRead = 'folder-read',
  FolderWrite = 'folder-write',
  FolderDelete = 'folder-delete',
  FaxWrite = 'fax-write',
  IncomingEmailWrite = 'incoming-email-write',
  PageRead = 'page-read',
  PageWrite = 'page-write',
  PageDelete = 'page-delete',
  DocumentRead = 'document-read',
  DocumentWrite = 'document-write',
  DocumentDelete = 'document-delete',
  DocumentFile = 'document-file',
  SearchRead = 'search-read',
  SearchWrite = 'search-write',
  TemplateRead = 'template-read',
  TemplateWrite = 'template-write',
  TemplateDelete = 'template-delete',
  ExportAccountRead = 'export-account-read',
  ExportAccountWrite = 'export-account-write',
  ExternalAccountRead = 'external-account-read',
  ExternalAccountWrite = 'external-account-write',
  ExternalLoginRead = 'external-login-read',
  ExternalLoginWrite = 'external-login-write',
  ExportAccountConfigRead = 'export-account-config-read',
  ExportAccountConfigWrite = 'export-account-config-write',
  ExportFolderRead = 'export-folder-read',
  ExportServiceWrite = 'export-service-write',
  MasqueradeRequest = 'masquerade-request',
  MasqueradeResponse = 'masquerade-response',
  MailTemplateRead = 'mail-template-read',
  MailTemplateWrite = 'mail-template-write',
  ScannersRead = 'scanners-read',
  ArchiveWrite = 'archive-write'
}
