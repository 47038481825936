import { Injectable } from '@angular/core';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GetAuthUrlService {
  constructor(private http: HttpClient) {}

  /**
   * Get an authorization url for a specific service
   *
   * @summary `GET /export_service_config/{service}/get_auth_url`
   */
  get(
    usersId: string,
    service: string,
    connectionsRedirectUrl: string,
    params: { [key: string]: any } = {}
  ): Observable<any> {
    let httpParams = new HttpParams().set('redirect_url', connectionsRedirectUrl);

    Object.keys(params).forEach((key) => {
      httpParams = httpParams.set(key, params[key]);
    });

    const options = {
      params: httpParams
    };

    options['responseType'] = 'text';

    return this.http.get<any>(`/users/${usersId}/export_services/${service}/auth_url`, options);
  }
}
