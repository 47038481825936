import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AccountStorageUsagePipe } from './account-storage-usage.pipe';
import { CamelCaseToHumanPipe } from './camel-case-to-human.pipe';
import { CardIconPipe } from './card-icon.pipe';
import { ControlNameToHumanPipe } from './control-name-to-human.pipe';
import { FieldModalTitlePipe } from './field-modal-title.pipe';
import { InputMaskPipe } from './input-mask.pipe';
import { InputTypePipe } from './input-type.pipe';
import { MessageTypeIconPipe } from './message-type-icon.pipe';
import {
  PasswordStrengthColorPipe,
  PasswordStrengthProgressPipe,
  PasswordStrengthTextPipe
} from './password-strength.pipe';
import { SnakeCaseToHumanPipe } from './snake-case-to-human.pipe';
import { SubscriptionButtonPipe } from './subscription-button.pipe';
import { SubscriptionPlanPipe } from './subscription-plan.pipe';
import { TableCellPipe } from './table-cell.pipe';

@NgModule({
  declarations: [
    AccountStorageUsagePipe,
    CamelCaseToHumanPipe,
    CardIconPipe,
    ControlNameToHumanPipe,
    FieldModalTitlePipe,
    InputMaskPipe,
    InputTypePipe,
    MessageTypeIconPipe,
    PasswordStrengthTextPipe,
    PasswordStrengthProgressPipe,
    PasswordStrengthColorPipe,
    SnakeCaseToHumanPipe,
    SubscriptionButtonPipe,
    SubscriptionPlanPipe,
    TableCellPipe
  ],
  imports: [CommonModule],
  exports: [
    AccountStorageUsagePipe,
    CamelCaseToHumanPipe,
    CardIconPipe,
    ControlNameToHumanPipe,
    FieldModalTitlePipe,
    InputMaskPipe,
    InputTypePipe,
    MessageTypeIconPipe,
    PasswordStrengthTextPipe,
    PasswordStrengthProgressPipe,
    PasswordStrengthColorPipe,
    SnakeCaseToHumanPipe,
    SubscriptionButtonPipe,
    SubscriptionPlanPipe,
    TableCellPipe
  ]
})
export class PipesModule {}
