import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from '@api/api.service';
import { AccountE2eEncryption } from '@api/types';
import { environment } from '@env/environment';
import {
  Base64KeySet,
  EncryptedFile,
  EncryptionKeyPair,
  EncryptionKeyService
} from '@shared/services/encryption-key.service';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { EnvironmentType } from '@shared/types/environment';
import { TaggedError } from '@shared/types/tagged-error';
import * as Sodium from 'libsodium-wrappers';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EncryptionHelperService {
  accountEncryptionStatus: BehaviorSubject<AccountE2eEncryption> = new BehaviorSubject<AccountE2eEncryption>(
    AccountE2eEncryption.Inactive
  );
  accountEncryptionEnabled = AccountE2eEncryption.Inactive;

  constructor(
    private localStorage: LocalStorageService,
    private encryptionService: EncryptionKeyService,
    private api: ApiService,
    private snackbar: MatSnackBar
  ) {
    this.accountEncryptionStatus.subscribe((status) => {
      const userWithToken = this.localStorage.userWithToken;
      if (userWithToken) {
        userWithToken.account.e2e_encryption = status;
        this.localStorage.userWithToken = userWithToken;
        this.accountEncryptionEnabled = status;
      }
    });
  }

  /**
   * Checks if E2E encryption is enabled
   *
   * @returns true if  enabled
   */
  isEncryptionEnabled() {
    return this.accountEncryptionEnabled === AccountE2eEncryption.Active;
  }

  /**
   * Check if the user has keys, if it has keys save them.
   */
  public async initialize(key: string) {
    this.encryptionService.initialize(key);
  }

  /**
   * Generate Account keys for every user in the account.
   */
  public async createAndSaveAccountKeyPairs() {
    const users = await this.api.users.get_noPagination();

    await Promise.all(
      users.map(async (user) => {
        const accountKeyPairEncoded = await this.encryptionService.createAccountKeys(user.id);
        await this.api.users.byId.accountKeypair.post(user.id, accountKeyPairEncoded).toPromise();
      })
    );
  }

  /**
   * Check if all users in the account have a User key.
   */
  public async allUsersInAccountHaveUserKeys(): Promise<boolean> {
    return this.api.helper.validateAccountUserKeys().toPromise();
  }

  /**
   * Sets disable key and updates the AccountKeyPair on the API
   */
  public async setDisableKeyPair(pass: string): Promise<void> {
    const userId = this.localStorage.userWithToken.id;
    const disableKeyPairEncoded = await this.encryptionService.createDisableKeyPair(pass);
    const disableKeys: EncryptionKeyPair = { ...disableKeyPairEncoded, decrypted: true };
    await this.api.users.byId.accountKeypair.put(userId, disableKeys).toPromise();
  }

  /**
   * Updates the password Key encryption and updates the UserKeyPair on the API
   */
  public async updateUserKeypairEncryption(passwordHash: string): Promise<void> {
    await this.encryptionService.updateAndSaveUserKeyPair(passwordHash);
  }

  /**
   * Generate a new EncryptionKeySet for a new user based his new ID
   *
   * @param newUserId The new user's ID
   */
  public generateNewUserEncryptionKeys(newUserId: string): void {
    this.encryptionService.generateNewUserEncryptionKeySet(newUserId).then(
      async (newUsersEncryptionKeySet) => {
        await this.saveUserKeySet(newUserId, newUsersEncryptionKeySet).toPromise();
        this.encryptionAlert('New user created with E2E enabled', false);
      },
      (error) => {
        this.encryptionAlert(error.message, true);
        throw new TaggedError(error.message, { e2eEncryption: 'generateNewUserEncryptionKeys' });
      }
    );
  }

  /**
   *  Updates the KeyPair for the new users
   *
   * @param userId User id
   * @param password user password
   */
  public async updateNewUserUserKeyPair(userId: string, password: string) {
    const { userKeyPair } = await this.findUserKeySet(userId).toPromise();
    const newUserKeyPair = await this.encryptionService.updateNewUserUserKeyPair(userId, userKeyPair, password);
    await this.api.users.byId.userKeypair.put(userId, newUserKeyPair).toPromise();
  }

  /**
   * Persists the users encryptions key pairs in the back end
   *
   * @param userId User's ID
   * @param userKeys User KeyPair
   * @returns An Observable to persist both keyPairs for the user
   */
  saveUserKeySet(userId: string, userKeySet: Base64KeySet) {
    return combineLatest([
      this.api.users.byId.userKeypair.post(userId, userKeySet.userKeyPair),
      this.api.users.byId.accountKeypair.post(userId, userKeySet.accountKeyPair)
    ]);
  }

  /**
   * Fetches the user user KeyPair and the Account KeyPair
   *
   * @param userId Id of the user
   * @returns An Observable to retrieve the two keyPairs
   */
  findUserKeySet(userId: string): Observable<Base64KeySet> {
    return combineLatest([
      this.api.users.byId.userKeypair.get(userId),
      this.api.users.byId.accountKeypair.get(userId)
    ]).pipe(
      map(([encodedUserKeyPair, encodedAccountKeyPair]) => ({
        userKeyPair: encodedUserKeyPair,
        accountKeyPair: encodedAccountKeyPair
      })),
      catchError((error) => {
        throw new TaggedError(error.message, { e2eEncryption: 'findUserKeySet' });
      })
    );
  }

  /**
   * Encrypts a single file when E2E Encryption is enabled
   *
   * @param file File to be encrypted
   * @returns an encrypted file
   */
  async handleFileEncryption(file: File, currentFileKey?: string) {
    try {
      let decodedCurrentFileKey;
      if (currentFileKey) {
        decodedCurrentFileKey = Sodium.from_base64(currentFileKey, Sodium.base64_variants.URLSAFE_NO_PADDING);
      }
      await this.encryptionService.waitForLibSodium();
      const fileContents = await this.fileToUint8Array(file);
      const encryptedFile = await this.encryptionService.encryptDocument(fileContents, decodedCurrentFileKey);
      this.encryptionAlert('File encryption successful');
      return encryptedFile;
    } catch (error) {
      this.encryptionAlert(JSON.stringify(error), true);
      throw new TaggedError(error.message, { e2eEncryption: 'handleFileEncryption' });
    }
  }

  /**
   * Decrypts a single file when E2E Encryption is enabled
   *
   * @param file File object to be decrypted
   * @param fileType When file is encrypted, type is needed to create new Blob object
   * @param encryptionKey file encryption key
   *
   * @returns same file but as a decrypted Blob
   */
  async handleFileDecryption(file: any, fileType: string, encryptionKey: string) {
    try {
      await this.encryptionService.waitForLibSodium();
      const contents = await this.fileToUint8Array(file);
      const encryptedFile: EncryptedFile = {
        contents,
        key: Sodium.from_base64(encryptionKey, Sodium.base64_variants.URLSAFE_NO_PADDING)
      };
      const decryptedDocument = await this.encryptionService.decryptDocument(encryptedFile);
      this.encryptionAlert('File decryption successful');
      return new Blob([decryptedDocument], { type: fileType });
    } catch (error) {
      this.encryptionAlert(JSON.stringify(error), true);
      throw new TaggedError(error.message, { e2eEncryption: 'handleFileDecryption' });
    }
  }

  async testEncryptionProcess() {
    const base64StringFile = 'SGVsbG8gUmF2ZW4=';
    const uint8ArrayFile = Uint8Array.from(atob(base64StringFile), (c) => c.charCodeAt(0));
    const encryptedFile = await this.encryptionService.encryptDocument(uint8ArrayFile);
    const decryptedFile = await this.encryptionService.decryptDocument(encryptedFile);
    const base64ResultString = btoa(String.fromCharCode.apply(null, decryptedFile));

    if (!this.arrayBuffersAreEqual(uint8ArrayFile, decryptedFile) || base64StringFile !== base64ResultString) {
      this.encryptionAlert('Encryption process validation Failed', true);
      throw new TaggedError('testing encryption process failed', { e2eEncryption: 'testEncryptionProcess' });
    }
  }

  async checkCurrentE2eEncryptionStatus() {
    const userData = await this.api.users.me.get().toPromise();
    if (userData) this.accountEncryptionStatus.next(userData.account.e2e_encryption);
  }

  clearKeys() {
    this.encryptionService.clearKeys();
  }

  // compare ArrayBuffers
  private arrayBuffersAreEqual(a: Uint8Array, b: Uint8Array) {
    if (a.byteLength !== b.byteLength) return false;
    return a.every((val, i) => val === b[i]);
  }

  /**
   * Asynchronously reads a file and save it into a Uint8Array.
   *
   * @param file is the file Blob to turn into a Uint8Array
   * @returns A promise which resolves to a Uint8Array
   */
  private async fileToUint8Array(file): Promise<Uint8Array> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = () => {
        const contents = reader.result;
        let fileContents: Uint8Array;
        if (typeof contents === 'string') {
          fileContents = new Uint8Array(contents.length);
          for (let i = 0; i < contents.length; i++) {
            fileContents[i] = contents.charCodeAt(i);
          }
        } else {
          fileContents = new Uint8Array(contents);
        }
        resolve(fileContents);
      };
      reader.onerror = reject;
    });
  }

  /**
   * For non-production environments, add snackbar and console messages to help with testing
   * so that we know if encryption/decryption was successful
   *
   * @param message the message to put in snackbar and console
   * @param isError whether or not this is an error for styling
   */
  private encryptionAlert(message: string, isError = false): void {
    // only alert for non-prod environments
    if (environment.type === EnvironmentType.Production) return;

    // eslint-disable-next-line no-console
    if (isError) console.error(message);

    this.snackbar.open(message, '', {
      duration: 5000,
      panelClass: [isError ? 'alert-bg' : 'success-bg', 'bold', 'light-color']
    });
  }
}
