import { EventEmitter, Injectable } from '@angular/core';
import { Folder } from '@api/types';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  navigatedToFolder: EventEmitter<string> = new EventEmitter();
  createdFolder: EventEmitter<Folder> = new EventEmitter();
  removedFolder: EventEmitter<Folder> = new EventEmitter();
  renameFolder: EventEmitter<Folder> = new EventEmitter();

  constructor(private localStorage: LocalStorageService) {}
}
