import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Quote, QuotesGetParams } from '@api/types';
import { Observable } from 'rxjs';
import { HttpParamsHelper } from '../http-params-helper';

@Injectable({
  providedIn: 'root'
})
export class QuotesService {
  constructor(private http: HttpClient) {}

  /**
   * Retrieve quotes based on the filters
   *
   * @summary `GET /quotes`
   */
  get(params?: QuotesGetParams): Observable<Quote[]> {
    return this.http.get<Quote[]>('/quotes', { params: HttpParamsHelper.getHttpQueryParams(params) });
  }
}
