import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { AccountStorageTotals, SingleToken, UserWithToken } from '@api/types';
import { FolderNode } from '@shared/layout/sidebar-menu/folder-menu/folder-menu.datasource';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  constructor() {
    // get tokens from local storage
  }

  get clientCredentialsToken(): SingleToken {
    return LocalStorageService.getItem('clientCredentialsToken');
  }

  set clientCredentialsToken(value: SingleToken) {
    LocalStorageService.setItem('clientCredentialsToken', value);
  }

  get clientCredentialsAccessToken(): string {
    return this.clientCredentialsToken ? this.clientCredentialsToken.access_token : null;
  }

  get passwordToken(): SingleToken {
    return LocalStorageService.getItem('passwordToken');
  }

  set passwordToken(value: SingleToken) {
    LocalStorageService.setItem('passwordToken', value);
  }

  get passwordAccessToken(): string {
    return this.passwordToken ? this.passwordToken.access_token : null;
  }

  get userWithToken(): UserWithToken {
    return LocalStorageService.getItem('userWithToken');
  }

  set userWithToken(user: UserWithToken) {
    LocalStorageService.setItem('userWithToken', user);
  }

  set tablePerPage(value: number) {
    LocalStorageService.setItem('tablePerPage', value);
  }

  get tablePerPage(): number {
    switch (LocalStorageService.getItem('tablePerPage')) {
      case undefined:
        return (this.tablePerPage = 10);
      case 500:
        return (this.tablePerPage = 100);
      default:
        return LocalStorageService.getItem('tablePerPage');
    }
  }

  set folderTree(value: FolderNode[]) {
    LocalStorageService.setItem('folderTree', value);
  }

  get folderTree(): FolderNode[] {
    return LocalStorageService.getItem('folderTree');
  }

  get lastSearch(): any {
    return LocalStorageService.getItem('lastSearch');
  }

  set lastSearch(searchResult: any) {
    LocalStorageService.setItem('lastSearch', searchResult);
  }

  set previousUrl(url: string) {
    LocalStorageService.setItem('previousUrl', url.split('?')[0]);
  }

  get previousUrl(): string {
    return LocalStorageService.getItem('previousUrl');
  }

  /**
   * Gets a local storage item value and properly parses it if it's a non-string value.
   *
   * @param key Key of the local storage item to get.
   * @throw If not a SyntaxError.
   * @returns The value retrieved from the given key.
   */
  private static getItem(key: string): any {
    const value = localStorage.getItem(key);
    try {
      return JSON.parse(value);
    } catch (error) {
      if (!(error instanceof SyntaxError)) {
        throw error;
      }
    }
    return value;
  }

  /**
   * Sets a local storage item value.
   *
   * @param key Name of the local storage key.
   * @param value The value to set.
   */
  private static setItem(key: string, value: any): void {
    localStorage.setItem(key, typeof value === 'string' ? value : JSON.stringify(value));
  }

  /**
   * Removes a local storage item
   */
  private static removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  get tableAutoRefreshOnImport() {
    return LocalStorageService.getItem('tableAutoRefreshOnImport');
  }

  set tableAutoRefreshOnImport(setting: boolean) {
    LocalStorageService.setItem('tableAutoRefreshOnImport', setting);
  }

  get documentNotifications() {
    return LocalStorageService.getItem('documentNotifications');
  }

  set documentNotifications(setting: boolean) {
    LocalStorageService.setItem('documentNotifications', setting);
  }

  set isSidebarCollapsed(state: boolean) {
    LocalStorageService.setItem('isSidebarCollapsed', state);
  }

  get isSidebarCollapsed(): boolean {
    return LocalStorageService.getItem('isSidebarCollapsed');
  }

  set firstTableLoadParams(params: Params) {
    LocalStorageService.setItem('firstTableLoadParams', params);
  }

  get firstTableLoadParams(): Params {
    return LocalStorageService.getItem('firstTableLoadParams');
  }

  set accountStorageTotals(key: AccountStorageTotals) {
    LocalStorageService.setItem('accountStorageTotals', key);
  }

  get accountStorageTotals(): AccountStorageTotals {
    return LocalStorageService.getItem('accountStorageTotals');
  }

  deleteUserWithAccount(): void {
    LocalStorageService.removeItem('userWithToken');
  }

  deletePasswordToken(): void {
    LocalStorageService.removeItem('passwordToken');
  }

  deleteLastSearch(): void {
    LocalStorageService.removeItem('lastSearch');
  }

  deleteUserPreferences(): void {
    LocalStorageService.removeItem('tableAutoRefreshOnImport');
    LocalStorageService.removeItem('documentNotifications');
    LocalStorageService.removeItem('permanentDeletion');
  }
}
