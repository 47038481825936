import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { HttpParamsHelper } from '@api/http-params-helper';
import { DocumentsGetParams, PaginationGet } from '@api/types';
import { catchError } from 'rxjs/operators';

/**
 *
 * Query params to filter the results by destination recipient can use a mongo operator as a suffix.
 * These operators are exposed for the API. The current operators available are:
 *  'eq', 'ne', 'gt', 'gte', 'lt', 'lte', 'in', 'nin'.
 *
 * See more about mongo operators: https://docs.mongodb.com/manual/reference/operator/query/
 *
 */
export interface OutboxGetParams extends DocumentsGetParams {
  destination_recipient_ne?: string;
  is_hidden_eq?: boolean;
  destination_type_nin?: string;
}

@Injectable({
  providedIn: 'root'
})
export class OutboxService {
  constructor(private http: HttpClient) {}

  /**
   * Gets the outbox items
   *
   * @summary `GET /users/{userId}/outbox`
   */
  get(usersId: string, params: OutboxGetParams): Observable<PaginationGet<any>> {
    return this.http
      .get<PaginationGet<any>>(`users/${usersId}/outbox`, {
        params: HttpParamsHelper.getHttpQueryParams(params)
      })
      .pipe(catchError((err) => throwError('Error fetching data from the user outbox,  ' + err.message)));
  }
}
