export enum OauthTokenRequestGrantType {
  Password = 'password',
  ClientCredentials = 'client_credentials',
  RefreshToken = 'refresh_token'
}

/**
 * OAuth Token Request
 *
 * A Request for an OAuth Token
 */
export interface OauthTokenRequest {
  client_id: number;
  client_secret: string;
  grant_type: OauthTokenRequestGrantType;
  password_hash?: string;
  username?: string;
  password?: string;
  refresh_token?: string;
  scope: string;
  verification_code?: string;
  use_backup?: boolean;
}
