import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { MailTemplate, MailTemplateCreate, MailTemplatesGetParams, PaginationGet } from '@api/types';
import { Observable } from 'rxjs';
import { HttpParamsHelper } from '../http-params-helper';
import { MailTemplatesByIdService } from './byId/mail-templates-by-id.service';
import { MailTemplatesEventsService } from './events/mail-templates-events.service';
import { TrashService } from './trash/trash.service';

@Injectable({
  providedIn: 'root'
})
export class MailTemplatesService {
  constructor(
    private http: HttpClient,
    public trash: TrashService,
    public byId: MailTemplatesByIdService,
    public events: MailTemplatesEventsService
  ) {}

  /**
   * Adds a new mail template to the Database
   *
   * @summary `POST /mail_templates`
   */
  post(body: MailTemplateCreate): Observable<MailTemplate> {
    return this.http.post<MailTemplate>('/mail_templates', body);
  }

  /**
   * Returns list of active mail templates
   *
   * @summary `GET /mail_templates`
   */
  get(params?: MailTemplatesGetParams): Observable<PaginationGet<MailTemplate>> {
    return this.http.get<PaginationGet<MailTemplate>>('/mail_templates', {
      params: HttpParamsHelper.getHttpQueryParams(params)
    });
  }
}
