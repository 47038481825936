import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Page } from '@api/types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PagesService {
  constructor(private http: HttpClient) {}

  /**
   * Lists all the pages for the specified document.
   *
   * @summary `GET /documents/{document_id}/pages`
   */
  get(documentId: string): Observable<Page[]> {
    return this.http.get<Page[]>(`/documents/${documentId}/pages`);
  }
}
