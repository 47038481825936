import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { TestScan, TestScanCreate } from '@api/types';
import { Observable } from 'rxjs';
import { TestScanByIdService } from './byId/test-scan-by-id.service';

@Injectable({
  providedIn: 'root'
})
export class TestScansService {
  constructor(private http: HttpClient, public byId: TestScanByIdService) {}

  /**
   * Saves a new test scan and page.
   *
   * @summary `POST /test_scans`
   */
  post(body: TestScanCreate): Observable<TestScan> {
    return this.http.post<TestScan>('/test-scans', body);
  }
}
