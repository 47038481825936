import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { EnvironmentType } from '@shared/types/environment';
import { environment } from '../environments/environment';
import { TaggedError } from './shared/types/tagged-error';

/**
 * Uses Sentry to capture exceptions on production
 */
@Injectable()
export class SentryErrorHandler extends ErrorHandler {
  constructor() {
    super();
  }
  handleError(error) {
    if (environment.type === EnvironmentType.Production) {
      if (error.promise && error.rejection) {
        // Promise rejection wrapped by zone.js. Needed for cases where exception comes from an un-awaited async method
        error = error.rejection;
      }

      if (error instanceof TaggedError) {
        Sentry.withScope((scope) => {
          error.tags.map(({ tagName, tagValue }) => scope.setTag(tagName, tagValue));
          Sentry.captureException(error);
        });
      } else {
        Sentry.captureException(error);
      }
    }
    super.handleError(error);
  }

  setUserEmailToScope(email: string): void {
    Sentry.configureScope((scope) => scope.setUser({ email }));
  }
}
