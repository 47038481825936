import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Filesystem } from '@api/types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TrashByIdService {
  constructor(private http: HttpClient) {}

  /**
   * Restores deleted filesystem item
   *
   * @summary `PATCH /filesystem/trash/{filesystem_id}`
   */
  patch(filesystemId: string): Observable<Filesystem> {
    return this.http.patch<Filesystem>(`/filesystem/trash/${filesystemId}`, {});
  }

  /**
   * hard-deletes specific filesystem item
   *
   * @summary `DELETE /filesystem/trash/{filesystem_id}`
   */
  delete(filesystemId: string): Observable<object> {
    return this.http.delete(`/filesystem/trash/${filesystemId}`);
  }
}
