import { Injectable } from '@angular/core';

import { GetAuthUrlService } from './get_auth_url/get-auth-url.service';
import { VerifyCodeService } from './verify_code/verify-code.service';

@Injectable({
  providedIn: 'root'
})
export class ServiceService {
  constructor(public getAuthUrl: GetAuthUrlService, public verifyCode: VerifyCodeService) {}
}
