import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ExportService } from '@api/types';
import { ServiceService } from './service/service.service';

@Injectable({
  providedIn: 'root'
})
export class ExportServicesService {
  constructor(private http: HttpClient, public service: ServiceService) {}

  /**
   * Get the user's export accounts
   *
   * @summary `GET /users/{users_id}/export_services`
   */
  get(usersId: string): Observable<ExportService[]> {
    return this.http.get<ExportService[]>(`/users/${usersId}/export_services`);
  }
}
