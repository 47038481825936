import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { MailTemplate, MailTemplatesGetParams } from '@api/types';
import { Observable } from 'rxjs';
import { HttpParamsHelper } from '../../http-params-helper';
import { MailTemplateByIdService } from './byId/mail-template-by-id.service';

@Injectable({
  providedIn: 'root'
})
export class TrashService {
  constructor(private http: HttpClient, public byId: MailTemplateByIdService) {}

  /**
   * Returns list of trashed(soft-deleted) email templates
   *
   * @summary `GET /mail_templates/trash`
   */
  get(params?: MailTemplatesGetParams): Observable<MailTemplate[]> {
    return this.http.get<MailTemplate[]>('/mail_templates/trash', {
      params: HttpParamsHelper.getHttpQueryParams(params)
    });
  }
}
