import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { PaginationGet, Scanner, ScannersGetParams } from '@api/types';
import { Observable } from 'rxjs';
import { HttpParamsHelper } from '../http-params-helper';
import { ScannerByIdService } from './byId/scanner-by-id.service';

@Injectable({
  providedIn: 'root'
})
export class ScannersService {
  constructor(private http: HttpClient, public byId: ScannerByIdService) {}

  /**
   * Gets paginated list of the scanners. Mixes in pageable and orderable.
   *
   * @summary `GET /scanners`
   */
  get(params?: ScannersGetParams): Observable<PaginationGet<Scanner>> {
    return this.http.get<PaginationGet<Scanner>>('/scanners', {
      params: HttpParamsHelper.getHttpQueryParams(params)
    });
  }
}
