import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from '@api/api.service';
import { Document, FilesystemType, Folder } from '@api/types';
import { FolderNode } from '@shared/layout/sidebar-menu/folder-menu/folder-menu.datasource';
import { FolderReferenceService } from './folder-reference.service';
import { InboxCountService } from './inbox-count.service';
import { SidebarService } from './sidebar.service';

@Injectable({
  providedIn: 'root'
})
export class FolderActionsService {
  constructor(
    private snackbar: MatSnackBar,
    private api: ApiService,
    private folderReferenceService: FolderReferenceService,
    private inboxCount: InboxCountService,
    private sidebar: SidebarService
  ) {}

  /**
   * Moves selected files to destination folder.
   *
   * @param selectedFilesForMove the documents and folders that should be moved
   * @param destinationFolder the destination folder in which to move the files
   * @param suppressSnackbar whether or not you want to show a success/failure snackbar
   */
  async moveFiles(
    selectedFilesForMove: any[],
    destinationFolder: FolderNode | Folder,
    suppressSnackbar: boolean = false
  ) {
    const selectedFolders: Folder[] = [];
    const selectedDocuments: Document[] = [];
    selectedFilesForMove.forEach((file) => {
      if (file.type === FilesystemType.Folder) selectedFolders.push(file);
      else selectedDocuments.push(file);
    });

    const moveRequests: Promise<any>[] = [];

    selectedFolders.forEach((folder) => {
      moveRequests.push(
        this.api.folders.byId
          .patch(folder.id, { parent_id: destinationFolder.id })
          .toPromise()
          .then((newFolder) => {
            this.sidebar.removedFolder.emit(folder);
            this.sidebar.createdFolder.emit(newFolder);
          })
      );
    });

    selectedDocuments.forEach((document) => {
      moveRequests.push(this.api.documents.byId.patch(document.id, { folder_id: destinationFolder.id }).toPromise());
    });

    await Promise.all([...moveRequests, this.folderReferenceService.getInbox()]).then(
      (resolvedPromises) => {
        const inbox = resolvedPromises[resolvedPromises.length - 1];
        // If files were moved to the inbox
        if (
          destinationFolder &&
          selectedFilesForMove &&
          selectedFilesForMove[0] &&
          selectedFilesForMove[0].folder_id !== destinationFolder.id &&
          inbox &&
          inbox.id === destinationFolder.id
        ) {
          selectedFilesForMove.forEach(() => this.inboxCount.addOneToCurrentInboxCount());
        }

        if (suppressSnackbar) return;
        this.snackbar.open(
          selectedFilesForMove.length > 1
            ? selectedFilesForMove.length + ' files moved successfully.'
            : selectedFilesForMove[0].title + ' moved successfully.',
          '',
          {
            duration: 5000,
            panelClass: ['success-bg', 'bold', 'light-color']
          }
        );
      },
      (err) => {
        if (suppressSnackbar) return;
        this.snackbar.open(err && err.error.message ? err.error.message : 'File moving failed.', '', {
          duration: 5000,
          panelClass: ['alert-bg', 'bold', 'light-color']
        });
      }
    );
  }

  /**
   * Archives selected files.
   *
   * @param selectedFiles the selected documents and folders to archive
   * @param skipConfirmation whether or not confirmation dialog should be shown to user before archiving
   */
  async archiveFiles(selectedFiles: any[], skipConfirmation: boolean = false): Promise<boolean> {
    if (
      !skipConfirmation &&
      !confirm(
        // eslint-disable-next-line max-len
        'You are about to archive selected folders and their files. Archived files will remain in the Archive folder and can be restored at any time.'
      )
    ) {
      return false;
    }

    const selectedFolders: Folder[] = [];
    const selectedDocuments: Document[] = [];
    selectedFiles.forEach((file) => {
      if (file.type === FilesystemType.Folder) selectedFolders.push(file);
      else selectedDocuments.push(file);
    });

    let isSuccess = true;
    let archivedMessage = 'The files were archived.';
    const archiveRequests: Promise<any>[] = [];
    selectedFolders.forEach((folder) => {
      archiveRequests.push(
        this.api.folders.byId.archive
          .post(folder.id)
          .toPromise()
          .then(() => {
            this.sidebar.removedFolder.emit(folder);
          })
      );
    });
    selectedDocuments.forEach((document) => {
      archiveRequests.push(this.api.documents.byId.archive(document.id).toPromise());
    });
    await Promise.all(archiveRequests)
      .catch((err) => {
        isSuccess = false;
        archivedMessage = 'There was an error archiving the files, try again.';
      })
      .finally(() => {
        this.snackbar.open(archivedMessage, '', {
          duration: 5000,
          panelClass: [isSuccess ? 'success-bg' : 'alert-bg', 'bold', 'light-color']
        });
      });

    return true;
  }

  /**
   * Deletes selected files.
   *
   * @param selectedFiles the selected documents and folders to archive
   * @param skipConfirmation whether or not confirmation dialog should be shown to user before deleting
   */
  async deleteFiles(selectedFiles: any[], skipConfirmation: boolean = false) {
    if (!skipConfirmation) {
      if (
        !confirm(
          // eslint-disable-next-line max-len
          `You are about to delete selected folders and their files. Deleted files will remain in the Trash folder and can be restored for next 30 days before getting permanently deleted.`
        )
      ) {
        return false;
      }
    }

    const selectedFolders: Folder[] = [];
    const selectedDocuments: Document[] = [];
    selectedFiles.forEach((file) => {
      if (file.type === FilesystemType.Folder) selectedFolders.push(file);
      else selectedDocuments.push(file);
    });

    let isSuccess = true;
    let deletedMessage = 'The files were deleted.';
    const deleteRequests: Promise<any>[] = [];
    selectedFolders.forEach((folder) => {
      deleteRequests.push(
        this.api.folders.byId
          .delete(folder.id)
          .toPromise()
          .then(() => {
            this.sidebar.removedFolder.emit(folder);
          })
      );
    });
    selectedDocuments.forEach((document) => {
      deleteRequests.push(this.api.documents.byId.delete(document.id).toPromise());
    });
    await Promise.all(deleteRequests)
      .catch((err) => {
        isSuccess = false;
        deletedMessage = 'There was an error deleting the files, try again.';
      })
      .finally(() => {
        this.snackbar.open(deletedMessage, '', {
          duration: 5000,
          panelClass: [isSuccess ? 'success-bg' : 'alert-bg', 'bold', 'light-color']
        });
      });

    return true;
  }
}
