import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThumbService {
  constructor(private http: HttpClient) {}

  // TODO: figure out how to handle response
  /**
   *
   *
   * @summary `GET /pages/{page_id}/thumb`
   */
  get(pageId: string): Observable<any> {
    return this.http.get<any>(`/pages/${pageId}/thumb`);
  }

  // TODO: figure out how to handle response
  //       figure out what type the body should be
  /**
   * Triggers thumbnail generation.
   *
   * @summary `PATCH /pages/{page_id}/thumb`
   */
  patch(pageId: string, body: any): Observable<any> {
    return this.http.patch<any>(`/pages/${pageId}/thumb`, body);
  }
}
