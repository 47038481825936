import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpParamsHelper } from '@api/http-params-helper';
import { Note, NoteCreate, NotePaginator } from '@api/types';
import { PaginatedGetParams } from '@shared/types/paginated-get-params';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotesService {
  constructor(private http: HttpClient) {}

  /**
   * Gets all the notes for the given account.
   *
   * @summary `GET /accounts/{accountId}/notes`
   */
  get(accountId: string, params: PaginatedGetParams): Observable<NotePaginator> {
    return this.http.get<NotePaginator>(`/accounts/${accountId}/notes`, {
      params: HttpParamsHelper.getHttpQueryParams(params)
    });
  }

  /**
   * Creates a new note on the given account. The authenticated user will be marked on the note as office_user
   *
   * @summary `POST /accounts/{account_id}/notes`
   */
  post(accountId: string, note: NoteCreate): Observable<Note> {
    return this.http.post<Note>(`/accounts/${accountId}/notes`, note);
  }

  /**
   * Deletes the given note from the account.
   *
   * @summary `DELETE /accounts/{account_id}/notes/{note_id}`
   */
  delete(accountId: string, noteId: string): void {
    this.http.delete(`/accounts/${accountId}/notes/${noteId}`);
  }
}
