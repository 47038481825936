import { Injectable } from '@angular/core';

import { PublicIdService } from './public_id/public-id.service';

@Injectable({
  providedIn: 'root'
})
export class ShareService {
  constructor(public publicId: PublicIdService) {}
}
