import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { HttpParamsHelper } from '@api/http-params-helper';
import { FoldersGetParams, FolderWithPaginatedChildrenAndDocuments } from '@api/types';

@Injectable({
  providedIn: 'root'
})
export class TopFolderService {
  constructor(private http: HttpClient) {}

  /**
   * Get the user's folders
   *
   * @summary `GET /accounts/{account_id}/top_folder`
   */
  get(params: FoldersGetParams, accountId: string): Observable<FolderWithPaginatedChildrenAndDocuments> {
    return this.http.get<FolderWithPaginatedChildrenAndDocuments>(`/accounts/${accountId}/top_folder`, {
      params: HttpParamsHelper.getHttpQueryParams(params)
    });
  }
}
