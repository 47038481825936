import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Search } from '@api/types';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  constructor(private http: HttpClient) {}

  /**
   * Get an account's saved searches
   *
   * @summary `GET /accounts/{account_id}/searches`
   */
  get(accountId: string): Observable<Search> {
    return this.http.get<Search>(`/accounts/${accountId}/searches`);
  }
}
