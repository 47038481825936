import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Invoice } from '@api/types';
import { Observable } from 'rxjs';
import { InvoiceDownloadService } from './download/invoice-download.service';

@Injectable({
  providedIn: 'root'
})
export class InvoicesByIdService {
  constructor(private http: HttpClient, public download: InvoiceDownloadService) {}

  /**
   * Requests a specific invoice
   *
   * @param invoiceId The id of the invoice
   * @scope `account-write`
   */
  get(invoiceId: string): Observable<Invoice> {
    return this.http.get<Invoice>(`/invoices/${invoiceId}`);
  }
}
