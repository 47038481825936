import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Template, TemplateUpdate } from '@api/types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TemplateByIdService {
  constructor(private http: HttpClient) {}

  /**
   * Returns a template with a specified id.
   *
   * @summary `GET /templates/{template_id}`
   */
  get(templateId: string): Observable<Template> {
    return this.http.get<Template>(`/templates/${templateId}`);
  }

  /**
   * Update the given template.
   *
   * @summary `PATCH /templates/{template_id}`
   */
  patch(templateId: string, body: TemplateUpdate): Observable<Template> {
    return this.http.patch<Template>(`/templates/${templateId}`, body);
  }

  /**
   * Delete the given template.
   *
   * @summary `DELETE /templates/{template_id}`
   */
  delete(templateId: string): Observable<object> {
    return this.http.delete(`/templates/${templateId}`);
  }
}
