import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Page, PageUpdate } from '@api/types';
import { Observable } from 'rxjs';
import { PreviewService } from './preview/preview.service';
import { ThumbService } from './thumb/thumb.service';

@Injectable({
  providedIn: 'root'
})
export class PageByIdService {
  constructor(private http: HttpClient, public thumb: ThumbService, public preview: PreviewService) {}

  /**
   * Retrieves the specified page.
   *
   * @summary `GET /pages/{page_id}`
   */
  get(pageId: string): Observable<Page> {
    return this.http.get<Page>(`/pages/${pageId}`);
  }

  /**
   * If crop or rotate field is present in the request,
   * a new page will be returned. Otherwise, the current page
   * will be updated and return.
   *
   * @summary `PATCH /pages/{page_id}`
   */
  patch(pageId: string, body: PageUpdate): Observable<Page> {
    return this.http.patch<Page>(`/pages/${pageId}`, body);
  }

  /**
   * Deletes the specified page.
   *
   * @summary `DELETE /pages/{page_id}`
   */
  delete(pageId: string): void {
    this.http.delete(`/pages/${pageId}`);
  }
}
