import { Injectable } from '@angular/core';
import { SCSS_VARS } from 'src/app/scss-variables.generated';

@Injectable({
  providedIn: 'root'
})
export class MobileService {
  constructor() {}

  /**
   * Determines if the screen is a mobile display.
   *
   * @returns true if is mobile, false if not.
   */
  isMobileDisplay(): boolean {
    return window.innerWidth < parseFloat(SCSS_VARS['$medium']);
  }
}
