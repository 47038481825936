import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Folder, FolderCreate, FoldersGetParams, PaginationGet } from '@api/types';
import { Observable } from 'rxjs';
import { HttpParamsHelper } from '../http-params-helper';
import { FolderByIdService } from './byId/folder-by-id.service';
import { TrashService } from './trash/trash.service';

@Injectable({
  providedIn: 'root'
})
export class FoldersService {
  constructor(private http: HttpClient, public byId: FolderByIdService, public trash: TrashService) {}

  /**
   * Creates a new Folder for the given account.
   *
   * @summary `POST /folders`
   */
  post(body: FolderCreate): Observable<Folder> {
    return this.http.post<Folder>('/folders', body);
  }

  /**
   * Gets the logged-in user's folders.
   *
   * @summary `GET /folders`
   */
  get(params?: FoldersGetParams): Observable<PaginationGet<Folder>> {
    return this.http.get<PaginationGet<Folder>>('/folders', {
      params: HttpParamsHelper.getHttpQueryParams(params)
    });
  }
}
