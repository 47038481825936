import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const skipApi = request.headers.get('skipApi') === 'true';
    if (skipApi) {
      const newReq = request.clone({ headers: request.headers.delete('skipApi') });
      return next.handle(newReq);
    }

    const forwardSlash = request.url.charAt(0) === '/' ? '' : '/';
    const requestClone: object = { url: `${environment.api.url}${forwardSlash}${request.url}` };

    const ravenUrl = request.clone(requestClone);
    return next.handle(ravenUrl);
  }
}
