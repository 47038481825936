import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PublicIdService {
  constructor(private http: HttpClient) {}

  // TODO: figure out response type for pdf file
  /**
   *
   *
   * @summary `GET /share/{public_id}`
   */
  get(publicId: string): Observable<any> {
    return this.http.get(`/share/${publicId}`, {
      headers: new HttpHeaders({ Accept: 'application/pdf' }),
      responseType: 'blob',
      observe: 'response'
    });
  }
}
