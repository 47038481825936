import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class E2eEncryptionService {
  constructor(private http: HttpClient) {}

  /**
   * Enables encryption on the account.
   *
   * @summary `GET /accounts/{account_id}/e2e_encryption`
   */
  post(accountId: string, pass: string, password_hash: string, verification_code?: string): Observable<any> {
    const body = {
      password_hash,
      pass,
      verification_code
    };
    return this.http.post<any>(`/accounts/${accountId}/e2e_encryption`, body, { observe: 'response' });
  }

  /**
   * Disable encryption on the account.
   *
   * @summary `DELETE /accounts/{account_id}/e2e_encryption`
   */
  delete(accountId: string, pass: string, password_hash: string, verification_code?: string): Observable<any> {
    const params = {
      password_hash,
      pass,
      verification_code: ''
    };

    if (verification_code !== null) {
      params.verification_code = verification_code;
    }

    return this.http.delete<any>(`/accounts/${accountId}/e2e_encryption`, {
      observe: 'response',
      params
    });
  }
}
