export enum ExportServiceService {
  Dropbox = 'dropbox',
  Box = 'box',
  Evernote = 'evernote',
  Gdrive = 'gdrive',
  Sharepoint = 'sharepoint',
  Onedrive = 'onedrive',
  Quickbooks = 'quickbooks',
  OneNote = 'onenote',
  Neat = 'neat',
  Clio = 'clio'
}

/**
 * Export Service
 *
 * Accounts to which a Raven Cloud user can export (e.g., Google Drive, Evernote)
 */
export interface ExportService {
  id: string;
  service: ExportServiceService;
  key: string;
  code?: string;
  folder_id: string | null;
  folder_name: string | null;
  user_id: string;
  created_at: string;
  updated_at: string;
  expired?: boolean;
  signed_in_username?: string;
  scope: string;
}
