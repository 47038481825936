import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '@api/api.service';
import { FoldersGetParams, FolderWithPaginatedChildrenAndDocuments, FolderWithPaginatedDocuments } from '@api/types';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class FolderReferenceService {
  private inboxFolder: FolderWithPaginatedDocuments;
  private inboxObservable: Observable<FolderWithPaginatedDocuments>;
  private topFolder: FolderWithPaginatedChildrenAndDocuments;
  private topFolderObservable: Observable<FolderWithPaginatedChildrenAndDocuments>;

  constructor(private api: ApiService, private localStorage: LocalStorageService, private router: Router) {}

  cleanup() {
    this.inboxFolder = undefined;
    this.topFolder = undefined;
    this.inboxObservable = undefined;
    this.topFolderObservable = undefined;
  }

  /**
   * Returns reference to the inbox to be used when a quick reference is needed without causing an extra request.
   * Note that this will not load any of the folder's children or content.
   */
  getInbox(): FolderWithPaginatedDocuments | Promise<FolderWithPaginatedDocuments> {
    /* The next line is needed to avoid calling the API when the user has been logout and the account_id is not accessible
     * this happens only when E2E Encryption is enabled  and is a temporary workaround
     */
    if (!this.localStorage.userWithToken) return null;

    return (
      this.inboxFolder ||
      this.getInboxObservable()
        .toPromise()
        .then((inbox) => {
          this.inboxFolder = inbox;
          return inbox;
        })
    );
  }

  /**
   * Returns reference to the top folder to be used when a quick reference is needed without causing an extra request.
   * Note that this will not load any of the folder's children or content.
   */
  getTopFolder(): FolderWithPaginatedChildrenAndDocuments | Promise<FolderWithPaginatedChildrenAndDocuments> {
    /* The next line is needed to avoid calling the API when the user has been logout and the account_id is not accessible
     * this happens only when E2E Encryption is enabled  and is a temporary workaround
     */
    if (!this.localStorage.userWithToken) return null;

    return (
      this.topFolder ||
      this.getTopFolderObservable()
        .toPromise()
        .then((topFolder) => {
          this.topFolder = topFolder;
          return topFolder;
        })
    );
  }

  /**
   * Get the folder name from folder id via API request.
   *
   * @param folderId the id of the folder.
   */
  async getFolderName(folderId: string): Promise<string> {
    if (this.topFolder && this.topFolder.id === folderId) return this.topFolder.title;
    if (this.inboxFolder && this.inboxFolder.id === folderId) return this.inboxFolder.title;

    const params: FoldersGetParams = { per_page: 0 };
    return this.api.folders.byId
      .get(params, folderId)
      .toPromise()
      .then((folder) => folder.title);
  }

  /**
   * Navigates to the correct folder depending on the destinationFolderId.
   * Looks at the ID’s for the Inbox and Folders so that we would navigate to the ‘/inbox’ and ‘/folders’
   * routes so that we use the correct component to load the view.
   *
   * @param destinationFolderId ID of destination folder
   * @param queryParamMessage Message to display on table after navigation
   * @param failed Message type is alert or success
   */
  async navigateToFolder(destinationFolderId: string, queryParamMessage?: string, failed?: boolean) {
    let destinationFolderRoute: string;
    const inbox = await this.getInbox();
    const topFolder = await this.getTopFolder();
    switch (destinationFolderId) {
      case topFolder.id:
        destinationFolderRoute = '/folders';
        break;
      case inbox.id:
        destinationFolderRoute = '/inbox';
        break;
      default:
        destinationFolderRoute = `/folders/${destinationFolderId}`;
        break;
    }
    if (!queryParamMessage || queryParamMessage.length === 0) {
      this.router.navigate([destinationFolderRoute]);
    } else {
      this.router.navigate([destinationFolderRoute], {
        queryParams: { message: queryParamMessage, type: failed ? 'alert' : 'success' }
      });
    }
  }

  /**
   * Gets current folder id.
   */
  async getCurrentFolderId(): Promise<string> {
    try {
      if (!this.router.url.includes('inbox') && !this.router.url.includes('folders')) return;
      const urlPath = this.router.url.includes('?')
        ? this.router.url.substring(0, this.router.url.indexOf('?')).split('/')
        : this.router.url.split('/');
      if (!urlPath || urlPath.length === 0) return;
      const currentFolder = urlPath[urlPath.length - 1];
      switch (currentFolder) {
        case 'inbox':
          return (await this.getInbox()).id;
        case 'folders':
          return (await this.getTopFolder()).id;
        default:
          return currentFolder;
      }
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Returns the current observable if one already exists. If not, then creates one using the
   * share() operator and returns it. The observable will only exist as long as there is at
   * least one subscriber. It avoids making multiple identical requests to the API.
   *
   * @returns Observable of inbox request
   */
  private getInboxObservable(): Observable<FolderWithPaginatedDocuments> {
    if (this.inboxObservable) return this.inboxObservable;
    this.inboxObservable = this.api.accounts.byId.inbox
      .get(this.localStorage.userWithToken.account_id, { per_page: 0 })
      .pipe(share());
    return this.inboxObservable;
  }

  /**
   * Returns the current observable if one already exists. If not, then creates one using the
   * share() operator and returns it. The observable will only exist as long as there is at
   * least one subscriber. It avoids making multiple identical requests to the API.
   *
   * @returns Observable of the top folder request
   */
  private getTopFolderObservable(): Observable<FolderWithPaginatedChildrenAndDocuments> {
    if (this.topFolderObservable) return this.topFolderObservable;
    this.topFolderObservable = this.api.accounts.byId.topFolder
      .get({ per_page: 0 }, this.localStorage.userWithToken.account_id)
      .pipe(share());
    return this.topFolderObservable;
  }
}
