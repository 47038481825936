import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { HttpParamsHelper } from '@api/http-params-helper';
import { PaginationGet, User, UsersGetParams } from '@api/types';
import { UsersTrashService } from './users-trash.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  constructor(private http: HttpClient, public trash: UsersTrashService) {}

  /**
   * All the users for a given account.
   *
   * @summary `GET /accounts/{account_id}/users`
   */
  get(accountId: string, params: UsersGetParams): Observable<PaginationGet<User>> {
    return this.http.get<PaginationGet<User>>(`/accounts/${accountId}/users`, {
      params: HttpParamsHelper.getHttpQueryParams(params)
    });
  }
}
