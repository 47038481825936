import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BackupService } from './backup/backup.service';

export enum TwoFactorMethod {
  SMS = 'sms',
  VOICE = 'voice',
  APP = 'app'
}

export class TwoFactorRequestBody {
  method: TwoFactorMethod;
  phone_number: string;

  constructor(method: TwoFactorMethod, phone_number?: string) {
    this.method = method;
    this.phone_number = phone_number;
  }
}

export interface TwoFactorResponse {
  message: string;
  method: string;
  smsPhoneNumber?: string;
  voicePhoneNumber?: string;
}

@Injectable({
  providedIn: 'root'
})
export class TwoFactorService {
  constructor(private http: HttpClient, public backup: BackupService) {}

  /**
   * Make request to set up two factor authentication.
   *
   * @param method of twofactor authentication.
   * @param phone_number if method is sms or voice.
   */
  post(method: TwoFactorMethod, phone_number?: string): Observable<TwoFactorResponse> {
    return this.http.post<TwoFactorResponse>('/users/me/two_factor', new TwoFactorRequestBody(method, phone_number));
  }

  /**
   * verify the code that was sent to the user.
   *
   * @param verification_code sent to the user.
   */
  patch(verification_code: string): Observable<HttpResponse<any>> {
    return this.http.patch('/users/me/two_factor', { verification_code: verification_code }, { observe: 'response' });
  }
}
