import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CombineService {
  constructor(private http: HttpClient) {}

  /**
   * Combines the documents received
   *
   * @summary `POST /documents/combine`
   */
  post(masterDocumentId: string, documentIds: string[]): Observable<HttpResponse<any>> {
    return this.http.post(
      'documents/combine',
      {
        master_document_id: masterDocumentId,
        document_ids: documentIds
      },
      {
        observe: 'response'
      }
    );
  }
}
