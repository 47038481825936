import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Document, DocumentUpdate } from '@api/types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ImportService {
  constructor(private http: HttpClient) {}

  /**
   * Import a shared document into the account of the current user.
   * Can also be used to copy documents within the same account.
   * The post body is optional. If supplied, any values will overwrite current values.
   *
   * @summary `POST /documents/{document_id}/import`
   */
  post(documentId: string, body?: DocumentUpdate): Observable<Document> {
    return this.http.post<Document>(`/documents/${documentId}/import`, body);
  }
}
