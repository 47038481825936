import { Injectable } from '@angular/core';

import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { ModalService } from '@shared/modal/modal.service';
import { TokenService } from '@shared/services/token.service';
import { StatusCodes } from '@shared/types/status-codes';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
  constructor(private token: TokenService, private modalService: ModalService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        () => {},
        (error) => {
          if (request.url.endsWith('/oauth/token')) return;
          if (error instanceof HttpErrorResponse && error.status === StatusCodes.UNAUTHORIZED) this.token.logout();
          else if (error instanceof HttpErrorResponse && error.status === StatusCodes.FORBIDDEN) {
            this.modalService.open.next({
              modalId: 'authentication',
              request: next.handle(request),
              scope: error.headers.get('scope'),
              originalRequest: request
            });
          }
        }
      )
    );
  }
}
