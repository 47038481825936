import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { PaginationGet, User, UserCreate, UsersGetParams, UserWithAccount } from '@api/types';
import { Observable } from 'rxjs';
import { HttpParamsHelper } from '../http-params-helper';
import { TwoFaService } from './2fa/two-fa.service';
import { UserByIdService } from './byId/user-by-id.service';
import { MeService } from './me/me.service';
import { PasswordService } from './password/password.service';
import { RequestLoginService } from './request-login.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  constructor(
    private http: HttpClient,
    public me: MeService,
    public byId: UserByIdService,
    public password: PasswordService,
    public twoFa: TwoFaService,
    public requestLogin: RequestLoginService
  ) {}

  /**
   * Adds a new user to the Database
   *
   * @summary `POST /users`
   */
  post(body: UserCreate): Observable<User> {
    return this.http.post<User>('/users', body);
  }

  /**
   * Lists all users on the current account
   *
   * @summary `GET /users`
   */
  get(params?: UsersGetParams): Observable<PaginationGet<UserWithAccount>> {
    return this.http.get<PaginationGet<UserWithAccount>>('/users', {
      params: HttpParamsHelper.getHttpQueryParams(params)
    });
  }

  async get_noPagination(params?: UsersGetParams): Promise<UserWithAccount[]> {
    let usersPromise;
    try {
      params = { ...params, per_page: 100 };
      let usersPaginated = await this.get(params).toPromise();
      let users = usersPaginated.data;
      usersPromise = new Promise<UserWithAccount[]>(async (resolve, reject) => {
        while (usersPaginated.to < usersPaginated.total) {
          if (usersPaginated.next_page_url) {
            usersPaginated = await this.http
              .get<PaginationGet<UserWithAccount>>(usersPaginated.next_page_url, {
                headers: { skipApi: 'true' },
                params: HttpParamsHelper.getHttpQueryParams(params)
              })
              .toPromise()
              .catch(() => {
                reject();
                throw Error();
              });
            users = [...users, ...usersPaginated.data];
          } else {
            reject();
            throw Error();
          }
        }
        resolve(users);
      });
    } catch (e) {}
    return usersPromise;
  }
}
