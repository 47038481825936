export enum UserUpdateRole {
  Admin = 'admin',
  Read = 'read',
  View = 'view',
  Owner = 'owner',
  Editor = 'editor',
  Contributor = 'contributor',
  Superadmin = 'superadmin'
}

/**
 * User Update
 *
 * A request to update a Raven Cloud User
 */
export interface UserUpdate {
  first_name?: string;
  last_name?: string;
  email?: string;
  role?: UserUpdateRole;
  phone_number?: string;
  country_code?: string;
  old_pass?: string;
  pass?: string;
  pass_conf?: string;
  password_hash?: string;
}
