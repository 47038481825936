import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpParamsHelper } from '@api/http-params-helper';
import { DocumentsGetParams } from '@api/types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchByIdService {
  constructor(private http: HttpClient) {}

  /**
   * Returns search results previously requested at /documents/search
   *
   * @summary `GET /documents/search/{search_id}`
   */
  get(params: DocumentsGetParams, searchId: string): Observable<any> {
    return this.http.get<any>(`/documents/search/${searchId}`, {
      params: HttpParamsHelper.getHttpQueryParams(params)
    });
  }
}
