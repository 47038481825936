import { NgxZendeskWebwidgetConfig } from 'ngx-zendesk-webwidget';

export class ZendeskConfig extends NgxZendeskWebwidgetConfig {
  lazyLoad = true;
  accountUrl = 'starfish.zendesk.com';
  callback(zE) {
    zE('webWidget', 'setLocale', 'en');
    zE('webWidget', 'hide');
  }
}
