import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { RouterModule } from '@angular/router';
import { OnlyNumbersDirective } from '@shared/directives/only-numbers.directive';
import { HelpRolesComponent } from '@shared/help-roles/help-roles.component';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { TextMaskModule } from 'angular2-text-mask';
import { PipesModule } from '../pipes/pipes.module';
import { ConfirmPasswordValidator } from '../validator/confirm-password.validator';
import { PasswordValidator } from '../validator/password.validator';
import { FieldErrorsComponent } from './field-errors/field-errors.component';
import { FormControlComponent } from './form-control/form-control.component';
import { FormMessagesComponent } from './form-messages/form-messages.component';
import { FormComponent } from './form/form.component';
import { InputComponent } from './input/input.component';
import { PasswordComponent } from './password/password.component';
import { PhoneNumberFormatterDirective } from './phone-number/phone-number-formatter.directive';
import { PhoneNumberComponent } from './phone-number/phone-number.component';
import { SelectComponent } from './select/select.component';
import { SwitchComponent } from './switch/switch.component';
import { TextareaComponent } from './textarea/textarea.component';
import { WysiwygComponent } from './wysiwyg/wysiwyg.component';

@NgModule({
  declarations: [
    FieldErrorsComponent,
    FormComponent,
    FormControlComponent,
    FormMessagesComponent,
    InputComponent,
    PasswordComponent,
    SelectComponent,
    TextareaComponent,
    WysiwygComponent,
    HelpRolesComponent,
    SwitchComponent,
    PhoneNumberComponent,
    PhoneNumberFormatterDirective,
    OnlyNumbersDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    MatMenuModule,
    MatProgressBarModule,
    PipesModule,
    ReactiveFormsModule,
    TextMaskModule,
    RouterModule,
    MatAutocompleteModule
  ],
  providers: [ConfirmPasswordValidator, PasswordValidator],
  exports: [
    InputComponent,
    ReactiveFormsModule,
    FormsModule,
    FormComponent,
    FormMessagesComponent,
    InputComponent,
    PasswordComponent,
    ReactiveFormsModule,
    TextareaComponent,
    SelectComponent,
    PipesModule,
    TextMaskModule,
    WysiwygComponent,
    HelpRolesComponent,
    SwitchComponent,
    PhoneNumberComponent
  ]
})
export class RavenFormsModule {}
