export enum ScannerManufacturer {
  Plustek = 'Plustek',
  Avision = 'Avision',
  Unknown = 'Unknown'
}

/**
 * Scanner
 *
 * A scanner for Raven Cloud
 */
export interface Scanner {
  id: string;
  account_id?: string;
  user_id?: string;
  last_login: string;
  created_at: string;
  status: string;
  notes: string;
  model?: string;
  manufacturer: ScannerManufacturer;
}
