import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Document } from '@api/types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {
  constructor(private http: HttpClient) {}

  /**
   * Gets all documents within a folder
   *
   * @summary `GET /folders/{folder_id}/documents`
   */
  get(folderId: string): Observable<Document[]> {
    return this.http.get<Document[]>(`/folders/${folderId}/documents`);
  }
}
