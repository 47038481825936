import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HelperService {
  constructor(private http: HttpClient) {}

  /**
   * @returns true if all the users in the account have e2e encryption userKeyPairs generated.
   * Returns false otherwise.
   */
  validateAccountUserKeys(): Observable<boolean> {
    return this.http.get<any>(`/account_ready`);
  }
}
