import { Injectable } from '@angular/core';

import { HttpClient, HttpResponse } from '@angular/common/http';
import { Email, Message } from '@api/types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmailService {
  constructor(private http: HttpClient) {}

  /**
   * Users post their login email here to receive a token in their email to reset their password.
   *
   * @summary `POST /users/password/email`
   */
  post(body: Email): Observable<HttpResponse<Message>> {
    return this.http.post<Message>('/users/password/email', body, { observe: 'response' });
  }
}
