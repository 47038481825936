import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Contact } from '@api/types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContactByIdService {
  constructor(private http: HttpClient) {}

  /**
   * Gets a single contact
   *
   * @summary `GET /users/{userId}/contacts/{contactId}`
   */
  getContact(userId: string, contactId: string): Observable<Contact> {
    return this.http.get<Contact>(`/users/${userId}/contacts/${contactId}`);
  }

  /**
   * Deletes a single contact
   *
   * @summary `GET /users/{userId}/contacts/{contactId}`
   */
  delete(userId: string, contactId: string): Observable<any> {
    return this.http.delete<Contact>(`/users/${userId}/contacts/${contactId}`);
  }

  /**
   * Edits a contact
   *
   * @summary `PATCH /users/{userId}/contacts/{contactId}`
   */
  patchContact(userId: string, body: Contact, contactId: string): Observable<any> {
    return this.http.patch<Contact>(`/users/${userId}/contacts/${contactId}`, body);
  }
}
