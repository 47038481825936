import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Folder } from '@api/types';
import { Observable } from 'rxjs';
import { FolderByIdService } from './byId/folder-by-id.service';

@Injectable({
  providedIn: 'root'
})
export class TrashService {
  constructor(private http: HttpClient, public byId: FolderByIdService) {}

  /**
   * Gets a list of trashed folders
   *
   * @summary `GET /folders/trash`
   */
  get(): Observable<Folder[]> {
    return this.http.get<Folder[]>('/folders/trash');
  }
}
