export enum SearchCriteriaOperator {
  Partial = 'partial',
  Equals = '=',
  BangEquals = '!=',
  Greater = '>',
  Less = '<',
  GreaterEquals = '>=',
  LessEquals = '<=',
  In = 'in',
  BangIn = '!in',
  Range = 'range'
}

/**
 * Search Criteria
 *
 * A single criteria for search
 */
export interface SearchCriteria {
  field: string;
  operator?: SearchCriteriaOperator;
  value: any;
  or?: boolean;
}
