import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { retryBackoff } from '@shared/operators/retry-backoff.operator';
import { StatusCodes } from '@shared/types/status-codes';

import { Observable } from 'rxjs';

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(retryBackoff(3, 2000, this.shouldRetry));
  }

  /**
   * Checks the HTTP response to see if the servers responds with any 500,501,502,503 errors.
   *
   * @param error Error object
   * @return If should retry the request.
   */
  private shouldRetry(error: any): boolean {
    return (
      error instanceof HttpErrorResponse &&
      error.status >= StatusCodes.INTERNAL_SERVER_ERROR &&
      error.status < StatusCodes.GATEWAY_TIMEOUT
    );
  }
}
