import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { PaginationGet, Template, TemplateCreate, TemplatesGetParams } from '@api/types';
import { Observable } from 'rxjs';
import { HttpParamsHelper } from '../http-params-helper';
import { TemplateByIdService } from './byId/template-by-id.service';

@Injectable({
  providedIn: 'root'
})
export class TemplatesService {
  constructor(private http: HttpClient, public byId: TemplateByIdService) {}

  /**
   * Creates a new template.
   *
   * @summary `POST /templates`
   */
  post(body: TemplateCreate): Observable<Template> {
    return this.http.post<Template>('/templates', body);
  }

  /**
   * Gets the logged-in user's templates.
   *
   * @summary `GET /templates`
   */
  get(params?: TemplatesGetParams): Observable<PaginationGet<Template>> {
    return this.http.get<PaginationGet<Template>>('/templates', {
      params: HttpParamsHelper.getHttpQueryParams(params)
    });
  }
}
