import { Injectable } from '@angular/core';

import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RequestLoginService {
  constructor(private http: HttpClient) {}

  /**
   * Sends an email request for users of the same account to ask them to login in order to generate their encryption keys.
   *
   * @summary `POST /users/request_login`
   */
  post(password_hash: string): Observable<HttpResponse<string>> {
    return this.http.post<string>('/users/request_login', { password_hash }, { observe: 'response' });
  }
}
