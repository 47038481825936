export interface Environment {
  api: {
    url: string;
    client: {
      id: number;
      secret: string;
    };
  };
  connectionsRedirectUrl: string;
  contentful: {
    space: string;
    accessToken: string;
  };
  froalaEditorKey: string;
  laravelEchoHost: string;
  PDFTronLicense: string;
  stripeKey: string;
  type: EnvironmentType;
}

export enum EnvironmentType {
  Production = 'production',
  Local = 'local',
  CircleCI = 'circleCI',
  NetlifyPreview = 'netlify-preview',
  ApiStaging = 'api-staging',
  Cypress = 'cypress'
}
