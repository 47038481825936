import { Injectable } from '@angular/core';

import { RequestService } from './request/request.service';
import { ResponseService } from './response/response.service';

@Injectable({
  providedIn: 'root'
})
export class MasqueradeService {
  constructor(public request: RequestService, public response: ResponseService) {}
}
