import { Message } from './message';

export enum TwoFAResponseMethod {
  App = 'app',
  Voice = 'voice',
  Sms = 'sms'
}

/**
 * 2FA Response
 *
 * When first enabling 2FA, this will be returned. A verification code must then be sent back.
 */
export interface TwoFAResponse extends Message {
  method: TwoFAResponseMethod;
  otpauth?: string;
  qr_data?: string;
  secret?: string;
}
