export enum ScannerUpdateManufacturer {
  Plustek = 'Plustek',
  Avision = 'Avision',
  Unknown = 'Unknown'
}

/**
 * Scanner Update
 *
 * A request to update a scanner for Raven Cloud
 */
export interface ScannerUpdate {
  user_id?: string;
  last_login?: string;
  status?: string;
  notes?: string;
  model?: string;
  manufacturer?: ScannerUpdateManufacturer;
}
