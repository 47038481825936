import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { HttpParamsHelper } from '@api/http-params-helper';
import { Folder, FoldersGetParams, FolderUpdate, FolderWithPaginatedChildrenAndDocuments } from '@api/types';
import { Observable } from 'rxjs';
import { ArchiveService } from './archive/archive.service';
import { ChildrenService } from './children/children.service';
import { DocumentsService } from './documents/documents.service';

@Injectable({
  providedIn: 'root'
})
export class FolderByIdService {
  constructor(
    private http: HttpClient,
    public children: ChildrenService,
    public documents: DocumentsService,
    public archive: ArchiveService
  ) {}

  /**
   * Gets the specified folder
   *
   * @summary `GET /folders/{folder_id}`
   */
  get(params: FoldersGetParams, folderId: string): Observable<FolderWithPaginatedChildrenAndDocuments> {
    return this.http.get<FolderWithPaginatedChildrenAndDocuments>(`/folders/${folderId}`, {
      params: HttpParamsHelper.getHttpQueryParams(params)
    });
  }

  /**
   * Update the specified folder
   *
   * @summary `PATCH /folders/{folder_id}`
   */
  patch(folderId: string, body: FolderUpdate): Observable<Folder> {
    return this.http.patch<Folder>(`/folders/${folderId}`, body);
  }

  /**
   * Delete the given folder
   *
   * @summary `DELETE /folders/{folder_id}`
   */
  delete(folderId: string): Observable<object> {
    return this.http.delete(`/folders/${folderId}`);
  }
}
