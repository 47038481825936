import { Injectable } from '@angular/core';

import { HttpClient, HttpResponse } from '@angular/common/http';
import { Page, PageCreate } from '@api/types';
import { Observable } from 'rxjs';
import { PageByIdService } from './byId/page-by-id.service';
import { ExportService } from './export/export.service';

@Injectable({
  providedIn: 'root'
})
export class PagesService {
  // export is a reserved word
  constructor(private http: HttpClient, public byId: PageByIdService, public exportService: ExportService) {}

  /**
   * Send page information, and get upload URL. This endpoint will
   * accept information about the page(s) to be uploaded, including the filename,
   * and will return a URL in the X-Upload-URL header which will indicate where
   * the actual file data should be uploaded. It will also return the page id,
   * which the client will need to keep to create a document from the uploaded pages.
   *
   * @summary `POST /pages`
   */
  post(body: PageCreate): Observable<HttpResponse<Page>> {
    return this.http.post<Page>('/pages', body, { observe: 'response' });
  }
}
