import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PagesSend } from '@api/types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FaxService {
  constructor(private http: HttpClient) {}

  /**
   * Export a collection of pages to send to a fax number.
   *
   * @summary `POST /pages/export/fax`
   */
  post(body: PagesSend): Observable<any> {
    return this.http.post<any>('/pages/export/fax', body);
  }
}
