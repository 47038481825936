import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { MailTemplate } from '@api/types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MailTemplatesByIdService {
  constructor(private http: HttpClient) {}

  /**
   * Gets the content of a specific mail template
   *
   * @param id The ID of the mail template
   * @returns the content of the mail template
   */
  get(id: string): Observable<MailTemplate> {
    return this.http.get<MailTemplate>(`/mail_templates/${id}`);
  }
}
