import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SettingsByKeyService {
  constructor(private http: HttpClient) {}

  /**
   * Gets an individual setting, or group of settings.
   * Please note that this may return a single JSON-encoded value,
   * such as a single string, number, or even Boolean value, or an object or array.
   *
   * @summary `GET /accounts/{account_id}/settings/{key}`
   */
  get(accountId: string, key: string): Observable<any> {
    return this.http.get<any>(`/accounts/${accountId}/settings/${key}`);
  }

  /**
   * Sets an individual setting, or group of settings.
   *
   * @summary `PATCH /accounts/{account_id}/settings/{key}`
   */
  patch(accountId: string, key: string, body: any): Observable<any> {
    return this.http.patch<any>(`/accounts/${accountId}/settings/${key}`, body);
  }
}
