import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Message, TwoFAVerify } from '@api/types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class Verify2FAService {
  constructor(private http: HttpClient) {}

  /**
   * Verify verification code.
   *
   * @summary `POST /users/me/verify2FA`
   */
  post(body: TwoFAVerify): Observable<Message> {
    return this.http.post<Message>('/users/me/verify2FA', body);
  }
}
