import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { EnvironmentType } from '@shared/types/environment';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { DocumentsService } from '../documents/documents.service';

@Injectable({
  providedIn: 'root'
})
export class UploadsService {
  constructor(private http: HttpClient, private documents: DocumentsService) {}

  /**
   * This method takes a file and uploads it to Google Cloud Services,
   * then calls the documents service to patch the corresponding Document
   *
   * @param title the file's title
   * @param docId the Document ID
   * @param file the corresponding File (Blob type)
   * @param fileKey if E2E Encryption is enabled, this fileKey is needed to encrypt the file
   * @param shouldEncrypt if previously not encrypted, avoid encryption
   * @returns the Document instance as Observable
   */
  patch(title: string, docId: string, file: Blob, fileKey: string, shouldEncrypt = false) {
    const formData = new FormData();
    return this.getUploadUrl(title, file.type, shouldEncrypt).pipe(
      switchMap((res: any) => {
        // sends upload ID as document in form data & the new mime_type
        formData.append('document', res.id);
        formData.append('mime_type', file.type);
        return this.uploadFileToGCS(res.upload_url, res.id, file, shouldEncrypt, fileKey);
      }),
      switchMap(() => this.documents.byId.patchFormData(docId, formData))
    );
  }

  /**
   * This method takes a file and uploads it to Google Cloud Services,
   * then calls the documents service to post and create a new Document
   *
   * @param formData the data for the new Document
   * @param title  the Documents title
   * @param file the corresponding file
   * @returns the DocumentObject instance as Observable
   */
  post(formData: FormData, title: string, file: File) {
    formData.append('title', title);
    return this.getUploadUrl(title, file.type, true).pipe(
      switchMap((res: any) => {
        formData.append('document', res.id);
        return this.uploadFileToGCS(res.upload_url, res.id, file, true);
      }),
      switchMap((res) => {
        if (res && res.encryptionKeys) {
          formData.append('encrypted', 'true');
          formData.append('encryption_keys', res.encryptionKeys);
        }
        return this.documents.post(formData);
      })
    );
  }

  /**
   * Requests the backend a signed google cloud upload url, to upload a file into that url
   *
   * @returns object containing the upload_url, upload_id and expiration date
   * @summary `POST /documents/uploads`
   */
  private getUploadUrl(title: string, mime_type: string, shouldEncrypt = false): Observable<object> {
    return this.http.post(
      '/documents/uploads',
      { title, mime_type },
      {
        headers: new HttpHeaders({
          skipE2EE: (!shouldEncrypt).toString()
        })
      }
    );
  }

  /**
   * Takes a Google Cloud Services signed upload url and uploads a file to it
   *
   * @param uploadUrl the url to upload the url to
   * @param uploadId the upload id reference (only used in non productive environments)
   * @param file the file to upload, either Blob or File type
   * @param fileKey if E2E Encryption is enabled, this fileKey is needed to encrypt the file
   * @param shouldEncrypt tells if the file has to be encrypted or not when E2EE is enabled,
   * this is received by the encryption interceptor
   *
   * @returns corresponding HttpResponse
   */
  private uploadFileToGCS(
    uploadUrl: string,
    uploadId: string,
    file: any,
    shouldEncrypt: boolean,
    fileKey?: string
  ): Observable<any> {
    if (environment.type === EnvironmentType.Production || environment.type === EnvironmentType.NetlifyPreview) {
      return this.http.put(uploadUrl, file, {
        headers: new HttpHeaders({
          skipApi: 'true',
          skipAuthorization: 'true',
          skipE2EE: (!shouldEncrypt).toString(),
          ...(fileKey ? { fileKey } : {})
        })
      });
    }
    // When working on local env, update the file to a specific endpoint that works only in non-production environments
    return this.http.put(`/gcs_upload/${uploadId}`, file, {
      headers: new HttpHeaders({
        skipE2EE: (!shouldEncrypt).toString(),
        ...(fileKey ? { fileKey } : {})
      })
    });
  }
}
