import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Invoice } from '@api/types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InvoiceDownloadService {
  constructor(private http: HttpClient) {}

  /**
   * Downloads a specific invoice from stripe as a pdf
   *
   * @param invoiceId The id of the invoice
   * @scope `account-write`
   */
  get(invoiceId: string): Observable<Blob> {
    return this.http.get<Blob>(`/invoices/${invoiceId}/download`, {
      headers: new HttpHeaders({ Accept: 'application/pdf' })
    });
  }
}
