import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HashService {
  /**
   * Converts a string to a hash using the SHA-256 algorithm
   *
   * @param text Text to be hashed
   * @return hash string
   */
  public async hash(text: string): Promise<string> {
    try {
      const hash = await this.createHashBuffer(text);
      return Promise.resolve(this.createHexFromBuffer(hash));
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**
   * Creates an array buffer using the web crypto utility
   *
   * @param text text to be hashed
   */
  private createHashBuffer(text: string): PromiseLike<ArrayBuffer> {
    return new Promise(async (resolve, reject) => {
      try {
        const buffer = this.textEncoder(text);

        // It's a modern browser
        if (!window['msCrypto']) {
          const hash = await crypto.subtle.digest('SHA-256', buffer);
          resolve(hash);
        }

        // IE Polyfill
        const res = window['msCrypto'].subtle.digest('SHA-256', buffer);
        res.oncomplete = (buff) => {
          resolve(buff.target.result);
        };
      } catch (error) {
        reject(error);
      }
    });
  }

  /**
   * Encodes a text to Unit8Array
   * if the TextEncoder does not exist (IE11) executes the polyfill
   *
   * @param text Text to encode
   */
  private textEncoder(text: string): Uint8Array {
    if (window['TextEncoder']) {
      return new TextEncoder().encode(text);
    }

    // IE11 Polyfill
    const utf8 = unescape(encodeURIComponent(text));
    const result = new Uint8Array(utf8.length);

    for (let i = 0; i < utf8.length; i++) {
      result[i] = utf8.charCodeAt(i);
    }

    return result;
  }

  /**
   * Converts an string array buffer to a hexadecimal string
   *
   * @param buffer Array buffer from a string
   */
  private createHexFromBuffer(buffer: ArrayBuffer): string {
    return Array.prototype.map
      .call(new Uint8Array(buffer), (byte) => byte.toString(16))
      .join('');
  }
}
