import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {
  /**
   * Gets a session storage item value and properly parses it if it's a non-string value.
   *
   * @param key Key of the session storage item to get.
   * @throw If not a SyntaxError.
   * @returns The value retrieved from the given key.
   */
  private static getItem(key: string): any {
    const value = sessionStorage.getItem(key);
    try {
      return JSON.parse(value);
    } catch (error) {
      if (!(error instanceof SyntaxError)) {
        throw error;
      }
    }
    return value;
  }

  /**
   * Sets a session storage item value. Items remain in session storage until the browser is restarted.
   *
   * @param key Name of the session storage key.
   * @param value The value to set.
   */
  private static setItem(key: string, value: any): void {
    sessionStorage.setItem(key, typeof value === 'string' ? value : JSON.stringify(value));
  }

  /**
   * Removes a session storage item
   */
  private static removeItem(key: string): void {
    sessionStorage.removeItem(key);
  }

  get noticeBarClosed(): boolean {
    return SessionStorageService.getItem('noticeBarClosed');
  }

  set noticeBarClosed(value: boolean) {
    SessionStorageService.setItem('noticeBarClosed', value);
  }

  get previousUrl(): string {
    return SessionStorageService.getItem('previousUrl');
  }

  set previousUrl(value: string) {
    SessionStorageService.setItem('previousUrl', value);
  }

  get serviceScope(): string {
    return SessionStorageService.getItem('service_scope');
  }

  set serviceScope(value: string) {
    SessionStorageService.setItem('service_scope', value);
  }

  set privateKey(privateKey: Uint8Array) {
    SessionStorageService.setItem('privateKey', privateKey);
  }

  get privateKey(): Uint8Array {
    return SessionStorageService.getItem('privateKey');
  }

  set publicKey(publicKey: Uint8Array) {
    SessionStorageService.setItem('publicKey', publicKey);
  }

  get publicKey(): Uint8Array {
    return SessionStorageService.getItem('publicKey');
  }

  set key(key: Uint8Array) {
    SessionStorageService.setItem('key', key);
  }

  get key(): Uint8Array {
    return SessionStorageService.getItem('key');
  }

  deleteEncryptionKeys(): void {
    SessionStorageService.removeItem('privateKey');
    SessionStorageService.removeItem('publicKey');
    SessionStorageService.removeItem('key');
  }

  areKeysEmpty() {
    return !this.key || !this.privateKey || !this.publicKey;
  }
}
