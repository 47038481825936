import { Keypair } from './keypair';

export enum UserRole {
  Admin = 'admin',
  Read = 'read',
  View = 'view',
  Owner = 'owner',
  Editor = 'editor',
  Contributor = 'contributor',
  Superadmin = 'superadmin'
}

export enum UserStatus {
  Active = 'active',
  Pending = 'pending'
}

/**
 * User
 *
 * A Raven Cloud User
 */
export interface User {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  role: UserRole;
  status: UserStatus;
  phone_number?: string;
  country_code?: string;
  account_id: string;
  last_activity?: string;
  enable_2fa: boolean;
  password_updated_at?: string;
  encryption_key?: Keypair;
  created_at: string;
  updated_at: string;
}
