import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { TestScan } from '@api/types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TestScansService {
  constructor(private http: HttpClient) {}

  /**
   * Get all test scans
   *
   * @summary `GET /scanners/{id}/test_scans`
   */
  get(id: string): Observable<TestScan[]> {
    return this.http.get<TestScan[]>(`/scanners/${id}/test_scans`);
  }
}
