import { formatDate } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from '@api/api.service';
import { Document, Folder } from '@api/types';
import { StatusCodes } from '@shared/types/status-codes';
import { getFileExtension } from '@shared/utils/file-type';
import * as FileSaver from 'file-saver';
import { DeviceDetectorService } from 'ngx-device-detector';
import { EchoService } from './echo.service';
import { ImportStatusService } from './import-status.service';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {
  downloadsProcessing = 0;

  constructor(
    private localStorage: LocalStorageService,
    private api: ApiService,
    private echo: EchoService,
    private snackbar: MatSnackBar,
    private importStatusService: ImportStatusService,
    private deviceService: DeviceDetectorService
  ) {}

  /**
   * Downloads the selected documents and folders as a single zip file
   *
   * @param documents Documents to be downloaded
   * @param folders Folders to be downloaded
   */
  async download(documents: Document[], folders?: Folder[]) {
    if (documents.length === 1 && (!folders || folders.length === 0)) {
      this.downloadSingleDocument(documents[0]);
      return;
    }

    let successMessage = '';
    if (documents.length >= 10) successMessage = 'Processing large download. This may take a few minutes.';

    this.echo
      .getPrivateChannel(`users.${this.localStorage.userWithToken.id}`)
      .listen('DownloadProcessed', (response) => {
        if (--this.downloadsProcessing <= 0) this.echo.leaveChannel(`users.${this.localStorage.userWithToken.id}`);

        if (!response.response.downloadLink) {
          this.showSnackBar(false, response.response.error);
          return;
        }

        const url: string = response.response.downloadLink;
        const id = url.substring(url.lastIndexOf('/') + 1, url.length);

        this.api.documents.download.byId.get(id).subscribe(
          (results) => {
            FileSaver.saveAs(results, this.fileName + '.zip');
            this.snackbar.dismiss();
          },
          (err) => this.showSnackBar(false, err.message)
        );
      });

    this.downloadsProcessing++;
    await this.api.documents.download
      .post(
        documents ? documents.map((document) => document.id) : [],
        folders ? folders.map((folder) => folder.id) : []
      )
      .toPromise()
      .then(
        () => {
          this.showSnackBar(true, successMessage);
        },
        (err) => this.showSnackBar(false, err.message)
      );
  }

  async printSingleDocument(document: Document) {
    const blob: Blob = await this.api.documents.byId
      .download(document.id, document.mime_type, document.encrypted)
      .toPromise()
      .then(
        (response) => response,
        (err) => this.showSnackBar(false, err.message)
      );

    const url = window.URL.createObjectURL(blob);
    const newTab = window.open(url);

    // Timeout required for Firefox because of a race condition.
    setTimeout(() => {
      newTab.print();
    }, 100);
  }

  /**
   * Uses simpler route if only one document needs to be downloaded. Response is a single blob
   * rather than a zip file. Check the response code before the actual downloading.
   *
   * @param document Document to be downloaded.
   */
  async downloadSingleDocument(document: Document) {
    try {
      this.showSnackBar(true);
      const response = await this.api.documents.byId
        .download(document.id, document.mime_type, document.encrypted)
        .toPromise();
      if (response.status === StatusCodes.ACCEPTED) {
        this.echo.getPrivateChannel(`documents.${document.id}`).listen('DocumentExportCreated', async () => {
          try {
            this.echo.leaveChannel(`documents.${document.id}`);
            this.importStatusService.documentNotToShowAsImport(document.id);
            await this.downloadHelper(document);
          } catch (error) {
            this.showSnackBar(false, error.message);
          }
        });
      } else {
        this.downloadHelper(document, response);
      }
    } catch (error) {
      this.showSnackBar(false, error.message);
    }
  }

  /**
   * Actual download behavior
   *
   * @param document Document to be downloaded
   */
  async downloadHelper(document: Document, response?: HttpResponse<any>) {
    try {
      if (!response) {
        response = await this.api.documents.byId
          .download(document.id, document.mime_type, document.encrypted)
          .toPromise();
      }
      const docTitleHeader = response.headers.get('X-Document-Title');
      const docTitle = docTitleHeader ? docTitleHeader : document.title;

      const navigator = window.navigator as any;

      // for IE
      if (navigator && navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(response.body, docTitle + getFileExtension(document.mime_type));
        return Promise.resolve();
      }

      // Share Sheet or Download
      const file = new File([response.body], docTitle + getFileExtension(document.mime_type), {
        type: document.mime_type,
        lastModified: new Date().getTime()
      });

      const filesArray = [file];
      const shareData = {
        files: filesArray
      };

      // Check if it's a mobile device (phone or tablet) to use share sheet.
      const shouldUseShareSheet = this.deviceService.isMobile() || this.deviceService.isTablet();
      const { device } = this.deviceService.getDeviceInfo();

      if (shouldUseShareSheet && navigator.canShare && navigator.canShare(shareData) && device !== 'Android') {
        navigator
          .share(shareData)
          .then()
          .catch((error) => {
            if (!error.toString().includes('AbortError')) {
              // Fallback on Error
              FileSaver.saveAs(response.body, docTitle + getFileExtension(document.mime_type));
              return;
            }
          });
      } else {
        const isWithinWebView = this.isWithinWebView();
        if (isWithinWebView) {
          const reader = new FileReader();
          reader.onload = (e) => {
            const message = {
              fileData: reader.result as string,
              name: docTitle,
              event: 'share'
            };
            window.ReactNativeWebView.postMessage(JSON.stringify(message));
          };
          reader.readAsDataURL(file);
        } else {
          FileSaver.saveAs(response.body, docTitle + getFileExtension(document.mime_type));
        }
      }
    } catch (error) {
      this.showSnackBar(false, error.message);
    }
  }

  /**
   * Download a document by its version.
   *
   * @param document Document to be downloaded
   */
  async downloadVersion(document: Document, version: string) {
    try {
      const response = await this.api.documents.byId.byVersion.get(document.id, version).toPromise();
      if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
        (window.navigator as any).msSaveOrOpenBlob(response.body, `${document.title}.pdf`);
        return Promise.resolve();
      }
      this.downloadHelper(document, response);
      this.showSnackBar(true);
    } catch (error) {
      this.showSnackBar(false, error.message);
    }
  }

  /**
   * @returns the zip folder name. For example - Raven Cloud 04_03_19 143037
   */
  private get fileName() {
    return 'Raven Cloud ' + formatDate(new Date(), 'MM_dd_yy HHmmss', 'en-US');
  }

  /**
   * Displays the MatSnackBar toast at the bottom of the screen. This element does not need to exist in the DOM prior
   * to being displayed on the screen so it is convenient to use from a service.
   *
   * @param isSuccess Whether or not the download was successful. Used to display toast with red or green background.
   * @param message The message to display in the snackbar. Defaulted based on isSuccess if not input.
   */
  private showSnackBar(isSuccess: boolean, message?: string) {
    this.snackbar.open(
      message ? message : isSuccess ? 'Files accepted, preparing your download.' : 'Download failed.',
      '',
      {
        duration: 5000,
        panelClass: [isSuccess ? 'success-bg' : 'alert-bg', 'bold', 'light-color']
      }
    );
  }

  private isWithinWebView() {
    const nav = window.navigator as any;
    const standalone = nav.standalone;
    const userAgent = nav.userAgent.toLowerCase();
    const safari = /safari/.test(userAgent);
    const ios = /iphone|ipod|ipad/.test(userAgent);

    if ((ios && !standalone && !safari) || (!ios && userAgent.includes('wv'))) {
      return true;
    }
    return false;
  }
}
