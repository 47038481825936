import { Injectable } from '@angular/core';
import { Scope } from '@shared/types/scope';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class ScopeService {
  scope = Scope;

  constructor(private localStorage: LocalStorageService) {}

  get userScopes(): string[] {
    if (!this.localStorage.userWithToken) return [];
    return this.localStorage.userWithToken.accessToken.scopes;
  }

  /**
   * Determines if current user has access to the provided scope
   *
   * @param scope the scope needed for this action
   * @return true if the current user has access, false if not
   */
  hasAccess(scope: string): boolean {
    return this.userScopes.includes(scope) || scope === '*';
  }

  /**
   * Determines if the current user has access to all of the scopes, when multiple scopes are needed
   *
   * @param scopes The scopes needed for this action
   */
  hasAccesses(scopes: string[] | '*'): boolean {
    let canAccess = true;
    if (scopes === '*') return true;
    scopes.forEach((scope) => {
      if (!this.hasAccess(scope)) canAccess = false;
    });
    return canAccess;
  }
}
