import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserSettingsKeys } from '@shared/types/settings-keys';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  constructor(private http: HttpClient) {}

  /**
   * Get the user's settings
   *
   * @summary `GET /users/{user_id}/settings`
   */
  get(userId: string): Observable<any> {
    return this.http.get<any>(`/users/${userId}/settings`);
  }

  /**
   * Get the user's settings
   *
   * @summary `GET /users/{user_id}/settings/{settingKey}`
   */
  getById(userId: string, settingKey: UserSettingsKeys): Observable<any> {
    return this.http.get<any>(`/users/${userId}/settings/${settingKey}`);
  }

  /**
   * Post and update user's settings
   *
   * @summary `POST /users/{user_id}/settings`
   */
  post(userId: string, settingKey: UserSettingsKeys, value: any): Observable<any> {
    // Creates an object to send to the api
    const settingObject: any = {};
    settingObject[settingKey] = value;

    return this.http.post<any>(`/users/${userId}/settings`, settingObject);
  }
}
