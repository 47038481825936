import { Injectable } from '@angular/core';

import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Keypair } from '@api/types';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserKeypairService {
  constructor(private http: HttpClient) {}

  /**
   * Gets the keypair of the user.
   *
   * @summary `GET /users/{id}/user_keypair`
   */
  get(userId: string): Observable<Keypair> {
    return this.http.get<any>(`/users/${userId}/user_keypair`, {
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache'
      }
    });
  }

  /**
   * Updates the keys of the user.
   *
   * @summary `PUT /users/{id}/user_keypair`
   */
  put(userId: string, body: Keypair): Observable<Keypair> {
    return this.http.put<any>(`/users/${userId}/user_keypair`, body, {
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache'
      }
    });
  }

  /**
   * Creates the keys of the user.
   *
   * @summary `POST /users/{id}/user_keypair`
   */
  post(userId: string, body: Keypair): Observable<Keypair> {
    return this.http
      .post<any>(`/users/${userId}/user_keypair`, body, {
        headers: {
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache'
        }
      })
      .pipe(
        catchError((response: HttpErrorResponse) => throwError('Unable to persist User KeyPair ' + response.message))
      );
  }
}
