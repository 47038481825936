import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Search, SearchUpdate } from '@api/types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchByIdService {
  constructor(private http: HttpClient) {}

  /**
   * Returns a search with a specified id.
   *
   * @summary `GET /searches/{search_id}`
   */
  get(searchId: string): Observable<Search> {
    return this.http.get<Search>(`/searches/${searchId}`);
  }

  /**
   * Update the given search.
   *
   * @summary `PATCH /searches/{search_id}`
   */
  patch(searchId: string, body: SearchUpdate): Observable<Search> {
    return this.http.patch<Search>(`/searches/${searchId}`, body);
  }

  /**
   * Delete the given search.
   *
   * @summary `DELETE /searches/{search_id}`
   */
  delete(searchId: string): Observable<void> {
    return this.http.delete<void>(`/searches/${searchId}`);
  }
}
