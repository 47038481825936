import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PreviewService {
  constructor(private http: HttpClient, private domSanitizer: DomSanitizer) {}

  /**
   * Gets the preview image for the page
   *
   * @param pageId The ID of the page
   */
  get(pageId: string): Observable<any> {
    return this.http.get(`/pages/${pageId}/preview`, { responseType: 'blob' }).pipe(
      map((res) => {
        if (!res.size) return;
        return this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(res));
      })
    );
  }
}
