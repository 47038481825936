import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '@env/environment';
import { StatusCodes } from '@shared/types/status-codes';
import { Channel } from 'laravel-echo/dist/channel';
import Echo from 'laravel-echo';
import { AccountE2eEncryption } from './../../api/types/account';
import { SnackbarProgressComponent } from './../../main/account/snackbar-progress/snackbar-progress.component';
import { EncryptionHelperService } from './encryption-helper.service';
import { LocalStorageService } from './local-storage.service';

interface EchoChannelOptions {
  isPrivate?: boolean;
  timeout: number;
}

interface EchoChannelResponse {
  response: {
    message?: string;
    error?: string;
    status_code?: number;
  };
}

declare global {
  interface Window {
    io: any;
    ReactNativeWebView?: any;
  }
}

window.io = window.io || require('socket.io-client');

@Injectable({
  providedIn: 'root'
})
export class EchoService {
  public static emailJobQueuedMsg = 'Email has been queued and will be delivered shortly.';
  public static faxJobQueuedMsg = 'Fax sent. Please check History for final status.';
  private echo: Echo;
  private responseCounter = 0;
  private eventCounter = 0;

  constructor(
    private localStorage: LocalStorageService,
    private snackbar: MatSnackBar,
    private encryptionHelper: EncryptionHelperService
  ) {}

  /**
   * Listen on 'PageEmailSent' event on 'private-users.{id}' channel.
   *
   * @returns Resolved promised if event respond data with a message field.
   * @returns Rejected promised if event respond data with a error field.
   * @returns Rejected promised if event doesn't response in 60 seconds.
   */
  async pageEmailSent(): Promise<string> {
    this.eventCounter++;

    return this.channelEventResponse<EchoChannelResponse>(
      `users.${this.localStorage.userWithToken.id}`,
      'PageEmailSent',
      EchoService.emailJobQueuedMsg,
      {
        isPrivate: true,
        timeout: 120000
      }
    )
      .then((response) => response.response)
      .then((response) => {
        if (response.message) return Promise.resolve(response.message);
        if (response.error) return Promise.reject(response.error);
      })
      .catch((reason) => Promise.reject(reason));
  }

  /**
   * Listen for DocumentFaxStatus event on 'users.{id}' channel.
   */
  async documentFaxStatus(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        this.eventCounter++;
        const response = (
          await this.channelEventResponse<EchoChannelResponse>(
            `users.${this.localStorage.userWithToken.id}`,
            'DocumentFaxStatus',
            EchoService.faxJobQueuedMsg,
            {
              isPrivate: true,
              timeout: 120000
            }
          )
        ).response;
        if (response.status_code === StatusCodes.ACCEPTED) {
          resolve(response.message);
        }

        reject(response.message);
      } catch (error) {
        reject(error);
      }
    });
  }

  async encryptionDecryptionProgressListener(encryptionStatus: AccountE2eEncryption): Promise<any> {
    const channelName = `accounts.${this.localStorage.userWithToken.account_id}`;
    const dictionary = this.getMessageDictionary(encryptionStatus);
    const snackBarProgress = this.snackbar.openFromComponent(SnackbarProgressComponent, {
      duration: 0,
      data: {
        percentage: -1,
        message: 'Processing documents...'
      }
    });
    let eventTimeout = this.e2eEncryptionProcessTimeout(snackBarProgress, dictionary, channelName);

    this.getPrivateChannel(channelName).listen(dictionary.eventName, async (eventResponse) => {
      eventTimeout = this.e2eEncryptionProcessTimeout(snackBarProgress, dictionary, channelName);
      let percentage: number;
      switch (dictionary.eventName) {
        case 'EncryptionProgress':
          percentage = (eventResponse.encryptedDocumentsInAccount * 100) / eventResponse.totalDocumentsInAccount;
          break;
        case 'DecryptionProgress':
          percentage =
            ((eventResponse.totalEncryptedDocumentsInAccount - eventResponse.remainingEncryptedDocumentsInAccount) *
              100) /
            eventResponse.totalEncryptedDocumentsInAccount;
          break;
        default:
          break;
      }
      snackBarProgress.instance.progressSubject.next({ percentage, message: dictionary.progressMessage });

      if (percentage === 100) {
        this.finishedStateEmitter(snackBarProgress, percentage, dictionary.finishUpMessage, channelName);
      }
    });
  }

  /**
   * Create instance of echo server if it doesn't exist
   */
  create(): Echo {
    if (this.echo) return;
    this.echo = new Echo({
      broadcaster: 'socket.io',
      host: environment.laravelEchoHost,
      auth: {
        headers: {
          Authorization: 'Bearer ' + this.localStorage.passwordToken.access_token
        }
      }
    });
  }

  /**
   * Destroy instance of echo server. Happens upon logout
   */
  destroy() {
    if (!this.echo) return;
    this.echo.disconnect();
    this.echo = null;
  }

  /**
   * Connects to private channel to listen for push notifications
   *
   * @param channelName name of channel to connect to
   */
  getPrivateChannel(channelName: string): Channel {
    this.create();
    return this.echo.private(channelName);
  }

  /**
   * Connects to private channel to listen for push notifications
   *
   * @param channelName name of channel to connect to
   */
  getPublicChannel(channelName: string): Channel {
    this.create();
    return this.echo.channel(channelName);
  }

  /**
   * Leaves specified channel
   *
   * @param channelName name of channel to disconnect from
   */
  leaveChannel(channelName: string) {
    if (this.echo) this.echo.leave(channelName);
  }

  /**
   * Returns an instance of a timeout promise to be used specifically with e2e encryption processes
   */
  private e2eEncryptionProcessTimeout(snackBarProgress, dictionary, channelName) {
    return this.timeoutPromise(300000, 'timeout', async () => {
      const currentE2eEncryptionStatus = this.encryptionHelper.accountEncryptionEnabled;
      if (
        currentE2eEncryptionStatus === AccountE2eEncryption.Active ||
        currentE2eEncryptionStatus === AccountE2eEncryption.Inactive
      ) {
        this.finishedStateEmitter(snackBarProgress, 100, dictionary.finishUpMessage, channelName);
      } else {
        this.finishedStateEmitter(snackBarProgress, -1, ' The process is taking longer than expected', channelName);
      }
    });
  }

  /**
   * Returns the correct dictionary of strings needed in e2e encryption process listener
   * ( TODO: to be deleted when we internationalize the whole app )
   */
  private getMessageDictionary(encryptionStatus) {
    if (encryptionStatus === AccountE2eEncryption.Active || encryptionStatus === AccountE2eEncryption.Activating) {
      return {
        eventName: 'EncryptionProgress',
        progressMessage: 'Encrypting files in your account',
        finishUpMessage: 'Finished account files encryption'
      };
    }
    return {
      eventName: 'DecryptionProgress',
      progressMessage: 'Decrypting files in your account',
      finishUpMessage: 'Finished account files decryption'
    };
  }

  /**
   * Performs the actions needed to finish up the e2e encryption process listener
   */
  private async finishedStateEmitter(snackBarProgress, percentage: number, message: string, channelName: string) {
    snackBarProgress.instance.progressSubject.next({ percentage, message });
    await this.encryptionHelper.checkCurrentE2eEncryptionStatus();
    setTimeout(() => snackBarProgress.dismiss(), 5000);
    this.leaveChannel(channelName);
  }

  /**
   * Create echo instance, open private/public channel, listen on to an event response and leave the channel.
   *
   * @param channelName The channel name to listen
   * @param eventName The event name to listen on
   * @param options The option that can choose to open a private or public channel. And can set timeout time.
   */
  private channelEventResponse<T>(
    channelName: string,
    eventName: string,
    failureMsg: string,
    options: EchoChannelOptions
  ): Promise<T> {
    return new Promise(async (resolve, reject) => {
      try {
        if (!this.echo) this.echo = this.create();

        const timeout = this.timeoutPromise(options.timeout, failureMsg);
        const eventResponse = this.eventResponsePromise(channelName, eventName, options);

        // Awaits for the response or the timeout promises to finish, whichever finishes first will resolve.
        const response = <T>await Promise.race([eventResponse, timeout]);

        resolve(response);
      } catch (error) {
        reject(error);
      } finally {
        const privateUserChannel = `users.${this.localStorage.userWithToken.id}`;
        if (channelName === privateUserChannel) this.responseCounter++;

        // Leave the private user channel when all events from this channel have a state.
        if (channelName === privateUserChannel && this.responseCounter === this.eventCounter) {
          {
            this.responseCounter = 0;
            this.eventCounter = 0;
            this.echo.leave(channelName);
          }
        }
        // For other channel, leave the channel once the promise has a state.
        if (channelName !== privateUserChannel) {
          this.echo.leave(channelName);
        }
      }
    });
  }

  /**
   * Create a promise that will resolve when got event response from a channel.
   *
   * @param channelName The channel name to listen
   * @param eventName The event name to listen on
   * @param options The option that can choose to open a private or public channel. And can set timeout time.
   */
  private eventResponsePromise(channelName: string, eventName: string, options: EchoChannelOptions): Promise<any> {
    return new Promise((resolve, reject) => {
      this.create();
      const channel = options.isPrivate ? this.echo.private(channelName) : this.echo.channel(channelName);
      channel.listen(eventName, (eventResponse: any) => {
        resolve(eventResponse);
      });
    });
  }

  /**
   * Create a promise that will reject after certain time.
   *
   * @param ms The timeout time in ms.
   */
  private timeoutPromise(ms: number, failureMsg: string = 'Event timed out.', callback?: () => void): Promise<string> {
    return new Promise((resolve, reject) => {
      const id = setTimeout(() => {
        clearTimeout(id);
        callback();
        reject(failureMsg);
      }, ms);
    });
  }
}
