export enum TwoFASendMethod {
  App = 'app',
  Voice = 'voice',
  Sms = 'sms'
}

/**
 * 2FA Send Object
 *
 * The model object for requesting server to send a verification code to a phone number.
 */
export interface TwoFASend {
  phone_number?: string;
  method?: TwoFASendMethod;
}
