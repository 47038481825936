import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BackupService {

  constructor(private http: HttpClient) { }

  /**
   * Make a patch request to generate the backup codes for a user.
   */
  patch(): Observable<any> {
    return this.http.patch('/users/me/two_factor/backup', {});
  }
}
