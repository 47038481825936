import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { HttpParamsHelper } from '@api/http-params-helper';
import { DocumentsGetParams } from '@api/types/documents_get_params';
import { FolderWithPaginatedDocuments } from '@api/types/folder_with_paginated_documents';

@Injectable({
  providedIn: 'root'
})
export class InboxService {
  constructor(private http: HttpClient) {}

  /**
   * Get the user's inbox with its documents.
   *
   * @summary `GET /accounts/{account_id}/inbox`
   */
  get(accountId: string, params?: DocumentsGetParams): Observable<FolderWithPaginatedDocuments> {
    return this.http.get<FolderWithPaginatedDocuments>(`/accounts/${accountId}/inbox`, {
      params: HttpParamsHelper.getHttpQueryParams(params)
    });
  }
}
