import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { OauthToken } from '@api/types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TokensService {
  constructor(private http: HttpClient) {}

  /**
   * Get all current (non-revoked) access tokens for the current user.
   *
   * @summary `GET /oauth/tokens`
   */
  get(): Observable<OauthToken[]> {
    return this.http.get<OauthToken[]>('/oauth/tokens');
  }

  /**
   * Revoke an access token. This is useful for logging out.
   *
   * @summary `DELETE /oauth/tokens/{token_id}`
   */
  delete(tokenId: string): Observable<string> {
    return this.http.delete<string>(`/oauth/tokens/${tokenId}`);
  }
}
