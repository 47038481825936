import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { DocumentUploadResponse, PagesSend } from '@api/types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ServiceByIdService {
  constructor(private http: HttpClient) {}

  /**
   * Export a collection of pages to send to an external service.
   *
   * @summary `POST /pages/export/service/{service}`
   */
  post(serviceId: string, body: PagesSend): Observable<DocumentUploadResponse> {
    return this.http.post<DocumentUploadResponse>(`/pages/export/service/${serviceId}`, body);
  }
}
