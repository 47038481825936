import { Pipe, PipeTransform } from '@angular/core';

/**
 * Transforms a value with underscores (_) separating words and replaces with spaces
 */
@Pipe({
  name: 'snakeCaseToHuman'
})
export class SnakeCaseToHumanPipe implements PipeTransform {
  transform(value: any): string {
    return value.replace(/_/g, ' ');
  }
}
