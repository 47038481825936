import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { UserWithToken } from '@api/types';
import { Observable } from 'rxjs';
import { Enable2FAService } from './enable2FA/enable-2-fa.service';
import { TwoFactorService } from './twoFactor/two-factor.service';
import { Verify2FAService } from './verify2FA/verify-2-fa.service';

@Injectable({
  providedIn: 'root'
})
export class MeService {
  constructor(
    private http: HttpClient,
    public enable2Fa: Enable2FAService,
    public verify2Fa: Verify2FAService,
    public twoFactor: TwoFactorService
  ) {}

  /**
   * Returns the current logged-in user, with the access token
   *
   * @summary `GET /users/me`
   */
  get(): Observable<UserWithToken> {
    return this.http.get<UserWithToken>('/users/me');
  }
}
