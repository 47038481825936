import { HttpParams } from '@angular/common/http';

export class HttpParamsHelper {
  constructor() {}

  /**
   * Loops over properties of "GetParams" typed objects to build an HttpParams object
   * to be used by http requests.
   *
   * @param params "GetParams" typed object
   */
  static getHttpQueryParams(params?: any): HttpParams {
    let httpParams = new HttpParams();

    if (params) {
      Object.keys(params).forEach((key) => {
        httpParams = httpParams.append(key, params[key]);
      });
    }
    return httpParams;
  }
}
