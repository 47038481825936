export enum StripePlanRequestPlan {
  BasicMonthly = 'basic.monthly',
  BasicAnnually = 'basic.annually',
  ProMonthly = 'pro.monthly',
  ProAnnually = 'pro.annually'
}

/**
 * Stripe Plan Request
 *
 * The request model to send to update an account's plan
 */
export interface StripePlanRequest {
  token?: string;
  plan: StripePlanRequestPlan;
}
