import { DocumentEncryptionKeys } from './document_encryption_keys';

export enum DocumentCreateSource {
  Scanner = 'scanner',
  Import = 'import',
  Email = 'email',
  Fax = 'fax'
}

/**
 * New Document
 *
 * New Document data model
 */
export interface DocumentCreate {
  title: string;
  page_ids?: string[];
  source: DocumentCreateSource;
  description?: string;
  folder_id?: string;
  template_id?: string;
  mime_type?: string;
  ocr?: boolean;
  encryption_keys?: DocumentEncryptionKeys;
}
