import { Injectable } from '@angular/core';

import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FolderNode } from 'src/app/main/account/connections/connection-folder-select/connection-folder-select.component';

@Injectable({
  providedIn: 'root'
})
export class ListService {
  constructor(private http: HttpClient) {}


  /**
   * List the folders for a service, if parentId doesn't exist return the folders from root
   *
   * @summary `GET /export_folders/{service}/list/{parentId}`
   */
  get(service: string, parentId?: string, oneDriveSkipToken?: string): Observable<HttpResponse<FolderNode[]>> {
    const httpParams = oneDriveSkipToken ? new HttpParams().set('skip', oneDriveSkipToken) : null;

    return this.http.get<FolderNode[]>(`/export_folders/${service}/list/${parentId || ''}`, {
      params: httpParams,
      observe: 'response'
    });
  }
}
