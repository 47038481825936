import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Message, StripeCardToken } from '@api/types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CardsService {
  constructor(private http: HttpClient) {}

  /**
   * Users can update their credit card on file through this route.
   */
  post(body: StripeCardToken): Observable<Message> {
    return this.http.post<Message>('/accounts/cards', body);
  }
}
