import { Injectable } from '@angular/core';

import { DisableService } from './disable/disable.service';
import { EmailService } from './email/email.service';

@Injectable({
  providedIn: 'root'
})
export class TwoFaService {
  constructor(public email: EmailService, public disable: DisableService) {}
}
