import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Invoice, PaginationGet } from '@api/types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccountInvoicesService {
  constructor(private http: HttpClient) {}

  /**
   * Requests all invoices for the account
   *
   * @param accountId The id of the account
   * @scope account-write
   */
  get(accountId: string): Observable<PaginationGet<Invoice>> {
    return this.http.get<PaginationGet<Invoice>>(`/accounts/${accountId}/invoices`);
  }
}
