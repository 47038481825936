import { Observable, of, pipe, range, throwError, timer, UnaryFunction, zip } from 'rxjs';
import { map, mergeMap, retryWhen } from 'rxjs/operators';

/**
 * Returns an Observable that mirrors the source Observable with the exception
 * of an error. If the source Observable calls error, rather than propagating
 * the error call this method will resubscribe to the source Observable with
 * exponentially increasing interval and up to a maximum of count
 * re-subscriptions (if provided). Retrying can be cancelled at any point if
 * shouldRetry returns false.
 */
export const retryBackoff = (
  maxTries: number,
  delay: number,
  shouldRetry: (err: any) => boolean = () => true
): UnaryFunction<Observable<any>, Observable<any>> =>
  pipe(
    retryWhen((attempts) =>
      zip(range(1, maxTries + 1), attempts).pipe(
        mergeMap(([i, err]) => (i <= maxTries && shouldRetry(err) ? of(i) : throwError(err))),
        map((i) => {
          const exponential = i * i;
          // eslint-disable-next-line no-console
          console.debug(`Attempt ${i}/${maxTries}: Retrying in ${exponential * delay}ms`);
          return exponential;
        }),
        mergeMap((v) => timer(v * delay))
      )
    )
  );
