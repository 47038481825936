import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Message, TwoFASend } from '@api/types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class Enable2FAService {
  constructor(private http: HttpClient) {}

  /**
   * Post phone number and country code to server.
   *
   * @summary `POST /users/me/enable2FA`
   */
  post(body: TwoFASend): Observable<Message> {
    return this.http.post<Message>('/users/me/enable2FA', body);
  }
}
