import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { BehaviorSubject } from 'rxjs';

interface PercentageEvent {
  percentage: number;
  message: string;
}

@Component({
  selector: 'rav-snackbar-progress',
  templateUrl: './snackbar-progress.component.html',
  styleUrls: ['./snackbar-progress.component.scss']
})
export class SnackbarProgressComponent implements OnInit {
  initValue: PercentageEvent = {
    percentage: -1,
    message: 'Process is starting ...'
  };
  progressSubject: BehaviorSubject<PercentageEvent> = new BehaviorSubject(this.initValue);
  currentPercentage: number;
  currentMessage: string;

  constructor(public sbRef: MatSnackBarRef<SnackbarProgressComponent>, @Inject(MAT_SNACK_BAR_DATA) public data: any) {}

  ngOnInit() {
    if (this.data.message && this.data.percentage) this.progressSubject.next(this.data);
    this.progressSubject.subscribe((data) => {
      this.currentPercentage = Math.floor(data.percentage);
      this.currentMessage = data.message;
    });
  }
}
