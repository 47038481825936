import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { ExportServiceUpdate } from '@api/types/export_service_update';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExportServicesByIdService {
  constructor(private http: HttpClient) {}

  /**
   * Delete or disconnect an existing export service
   *
   * @summary `DELETE /export_services/{id}`
   */
  delete(id: string): Observable<object> {
    return this.http.delete(`/export_services/${id}`);
  }

  /**
   * Patch an existing export service, usually it's used to update default folder id
   *
   * @summary `PATCH /export_services/{id}`
   */
  patch(id: string, body: ExportServiceUpdate): Observable<object> {
    return this.http.patch(`/export_services/${id}`, body);
  }
}
