import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';

@Injectable()
export class ErrorTrackingService {
  constructor() {}

  /**
   * Set the user's email to Sentry's scope
   *
   * @param email User's email
   */
  setUserEmailToScope(email: string): void {
    Sentry.configureScope((scope) => {
      scope.setUser({ email });
    });
  }

  /**
   * Sets additional data to Sentry error tracking
   *
   * @param email User's email
   */
  async setup(email: string): Promise<void> {
    if (email) {
      this.setUserEmailToScope(email);
    }
    return Promise.resolve();
  }
}
