import { Injectable } from '@angular/core';

import { ServiceService } from './service/service.service';

@Injectable({
  providedIn: 'root'
})
export class ExportFoldersService {
  constructor(public service: ServiceService) {}
}
