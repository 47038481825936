import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AccountsService } from './accounts/accounts.service';
import { AdditionalAuthenticationService } from './additional_authentication/additional_authentication.service';
import { DocumentsService } from './documents/documents.service';
import { ExportFoldersService } from './export_folders/export-folders.service';
import { ExportServicesService } from './export_services/export-services.service';
import { FilesystemService } from './filesystem/filesystem.service';
import { FoldersService } from './folders/folders.service';
import { HelperService } from './helper/helper.service';
import { InvoicesService } from './invoices/invoices.service';
import { MailTemplatesService } from './mail_templates/mail-templates.service';
import { MasqueradeService } from './masquerade/masquerade.service';
import { NeatImportService } from './neat_import/neat-import.service';
import { OauthService } from './oauth/oauth.service';
import { PagesService } from './pages/pages.service';
import { QuotesService } from './quotes/quotes.service';
import { ScannersService } from './scanners/scanners.service';
import { SearchesService } from './searches/searches.service';
import { ShareService } from './share/share.service';
import { TemplatesService } from './templates/templates.service';
import { TestScansService } from './test_scans/test-scans.service';
import { UploadsService } from './uploads/uploads.service';
import { UsersService } from './users/users.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(
    public helper: HelperService,
    public exportFolders: ExportFoldersService,
    public exportServices: ExportServicesService,
    public oauth: OauthService,
    public quotes: QuotesService,
    public accounts: AccountsService,
    public scanners: ScannersService,
    public folders: FoldersService,
    public searches: SearchesService,
    public templates: TemplatesService,
    public users: UsersService,
    public pages: PagesService,
    public testScans: TestScansService,
    public documents: DocumentsService,
    public share: ShareService,
    public invoices: InvoicesService,
    public masquerade: MasqueradeService,
    public mailTemplates: MailTemplatesService,
    public neatImport: NeatImportService,
    public fileSystem: FilesystemService,
    public additionAuthentication: AdditionalAuthenticationService,
    public uploads: UploadsService
  ) {}

  /**
   * When a form has errors, the form server errors are set.
   *
   * Called when an http error response happens when submitting a form. The server errors
   * are set on the form and each control.
   *
   * @param response The response with the error data.
   * @param formGroup The form group to set errors on.
   */
  onFormError(response: HttpErrorResponse, formGroup: FormGroup) {
    const globalErrors = { server: [response.error.message] };
    const errors = response.error.errors;

    for (const error in errors) {
      if (!errors.hasOwnProperty(error)) continue;

      // Sets error to the control when exists
      if (formGroup.controls[error]) {
        formGroup.controls[error].setErrors({ server: errors[error] });
      } else {
        // Sets the error as a general error to be shown
        globalErrors.server.push(errors[error]);
      }
    }

    formGroup.setErrors(globalErrors);
  }
}
