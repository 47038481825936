import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '@env/environment';
import * as Sentry from '@sentry/browser';
import { ErrorTrackingService } from '@shared/services/error-tracking.service';
import { HttpQueueService } from '@shared/services/http-queue.service';
import { SharedModule } from '@shared/shared.module';
import { EnvironmentType } from '@shared/types/environment';
import { ZendeskConfig } from '@shared/types/zendesk';
import { InlineSVGModule } from 'ng-inline-svg';
import { NgxStripeModule } from 'ngx-stripe';
import { NgxZendeskWebwidgetModule } from 'ngx-zendesk-webwidget';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GoogleTagMangerService } from './google-tag-manager.service';
import { ApiInterceptor } from './interceptors/api.interceptor';
import { AuthorizationInterceptor } from './interceptors/authorization.interceptor';
import { EncryptionInterceptor } from './interceptors/encryption.interceptor';
import { ErrorMessageInterceptor } from './interceptors/error-message.interceptor';
import { LanguageInterceptor } from './interceptors/language.interceptor';
import { ServerErrorInterceptor } from './interceptors/server-error.interceptor';
import { UnauthorizedInterceptor } from './interceptors/unauthorized.interceptor';
import { SentryErrorHandler } from './sentry-error-handler';

if (environment.type === EnvironmentType.Production) {
  Sentry.init({ dsn: 'https://661c55825c01478b8570f4fb2c8d1483@sentry.io/1310093' });
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    InlineSVGModule.forRoot(),
    NgxStripeModule.forRoot(environment.stripeKey),
    NgxZendeskWebwidgetModule.forRoot(ZendeskConfig)
  ],
  providers: [
    GoogleTagMangerService,
    HttpQueueService,
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    /* A note on interceptors: when adding a new interceptor,
    make sure they don't interfere with the upload methods. Otherwise make sure to add implementation to skip them.
    on Angular 12 update, the interceptors skipping method will be improved. */
    { provide: HTTP_INTERCEPTORS, useClass: EncryptionInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthorizationInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: UnauthorizedInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ServerErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorMessageInterceptor, multi: true },
    ErrorTrackingService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
