import { Injectable } from '@angular/core';

import { HttpClient, HttpResponse } from '@angular/common/http';
import { OauthTokenRequest, SingleToken } from '@api/types';
import { TwoFactorMethod } from '@api/users/me/twoFactor/two-factor.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  constructor(private http: HttpClient) {}

  /**
   * Login; Get an access token for the given user/client.
   * Or refresh a token if 2FA is not enabled; Send a 2FA code to the
   * phone number for the user that the username and password find.
   *
   * @summary `POST /oauth/token`
   */
  post(body: OauthTokenRequest): Observable<HttpResponse<SingleToken | VerificationRequest>> {
    return this.http.post<SingleToken | VerificationRequest>('/oauth/token', body, {observe: 'response'});
  }
}

export interface VerificationRequest {
  message: string;
  url: string;
  method: TwoFactorMethod;
}
