export enum ExportServiceUpdateService {
  Dropbox = 'dropbox',
  Box = 'box',
  Evernote = 'evernote',
  Gdrive = 'gdrive',
  Sharepoint = 'sharepoint',
  Onedrive = 'onedrive',
  Quickbooks = 'quickbooks',
  OneNote = 'onenote',
  Neat = 'neat',
  Clio = 'clio'
}

/**
 * Export Service Update
 *
 * The update model used to patch an export service.
 */
export interface ExportServiceUpdate {
  service?: ExportServiceUpdateService;
  key?: string;
  code?: string;
  folder_id?: string;
  folder_name?: string;
  account_id?: string;
}
