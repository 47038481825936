export enum UserCreateRole {
  Admin = 'admin',
  Read = 'read',
  View = 'view',
  Owner = 'owner',
  Editor = 'editor',
  Contributor = 'contributor',
  Superadmin = 'superadmin'
}

/**
 * User Creation
 *
 * A request to create a Raven Cloud User
 */
export interface UserCreate {
  first_name: string;
  last_name: string;
  email: string;
  role: UserCreateRole;
  phone_number?: string;
  country_code?: string;
  account_id?: string;
}
