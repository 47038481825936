import { Injectable } from '@angular/core';

import { TokenService } from './token/token.service';
import { TokensService } from './tokens/tokens.service';

@Injectable({
  providedIn: 'root'
})
export class OauthService {
  constructor(public token: TokenService, public tokens: TokensService) {}
}
