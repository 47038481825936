import { Injectable } from '@angular/core';

import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { VerificationRequest } from '@api/oauth/token/token.service';
import { Message, PasswordReset } from '@api/types';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ResetService {
  constructor(private http: HttpClient) {}

  /**
   * Pass new passwords and change the password for user.
   *
   * @summary `POST /users/password/reset`
   */
  post(body: PasswordReset): Observable<HttpResponse<Message | VerificationRequest>> {
    return this.http
      .post<Message>('/users/password/reset', body, { observe: 'response' })
      .pipe(catchError((response: HttpErrorResponse) => throwError(response)));
  }
}
