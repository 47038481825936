export enum ExportServiceVerificationCodeServiceScope {
  FullAccess = 'full_access',
  AppFolder = 'app_folder'
}

/**
 * Export Service Authorization Code
 *
 * A request to exchange an Authorization token with an authorization code
 */
export interface ExportServiceVerificationCode {
  code: string;
  redirect_url: string;
  service_scope?: ExportServiceVerificationCodeServiceScope;
}
