interface SentryTags {
  tagName: string;
  tagValue: string;
}

export class TaggedError extends Error {
  tags: Array<SentryTags> = [];

  constructor(msg?: string, errorData?: object) {
    super(msg);

    // set tags
    Object.keys(errorData).map((tagName) => {
      this.tags.push({ tagName, tagValue: errorData[tagName] });
    });
  }
}
