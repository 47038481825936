import { FilesystemType } from '@api/types';
import { RavIcon } from '@shared/icon/icon';

export const enum SupportedMimeTypes {
  PDF = 'application/pdf',
  JPEG = 'image/jpeg',
  TIFF = 'image/tiff'
}

/**
 * Based on a documents mime_type, return the appropriate file type extension.
 * Ex, application/pdf -> .pdf
 *
 * @param mime_type API type of document
 * @returns file type extension for filename
 */
export const getFileExtension = (mime_type: string): string => {
  switch (mime_type) {
    case SupportedMimeTypes.JPEG:
      return '.jpeg';
    case SupportedMimeTypes.TIFF:
      return '.tiff';
    case SupportedMimeTypes.PDF:
    default:
      return '.pdf';
  }
};

/**
 * Helper function that returns the mime type of file to send to API based on the filename that is imported.
 * Ex, test.pdf -> application/pdf
 *
 * @param extension filetype extension of filename
 * @returns mime_type of document to send to API
 */
export const getMimeTypeFromExtension = (extension: string): SupportedMimeTypes => {
  switch (extension) {
    case '.jpg':
    case '.jpeg':
      return SupportedMimeTypes.JPEG;
    case '.tiff': // *** For now, convert tiffs to PDFs as PDFTron doesn't support viewing tiffs ***
    case '.pdf':
    default:
      return SupportedMimeTypes.PDF;
  }
};

/**
 * Helper function used to determine what icon should be used for a table row
 *
 * @param tableRow row we are checking type of
 * @returns icon to use for row
 */
export const getTableRowFileTypeIcon = (tableRow: any): RavIcon => {
  if (tableRow['type'] === FilesystemType.Folder) return RavIcon.Folder;
  if (tableRow['mime_type'] === SupportedMimeTypes.PDF) return RavIcon.PdfDocument;
  return RavIcon.ImageDocument;
};

/**
 * Helper function used to determine tooltip used for file types
 *
 * @param tableRow row we are checking type of
 * @returns tooltip to use for row
 */
export const getTableRowFileTypeTooltip = (tableRow: any): string => {
  if (tableRow['type'] === FilesystemType.Folder) return 'Folder';
  if (tableRow['mime_type'] === SupportedMimeTypes.PDF) return 'PDF';
  return 'Image';
};
