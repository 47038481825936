export enum StatusCodes {
  OK = 200,
  ACCEPTED = 202,
  TWO_FA_CODE_REQUIRED = 261,
  TWO_FA_SETUP_REQUIRED = 263,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  INVALID_DATA = 422,
  PASSWORD_EXPIRED = 401001,
  HOST_UNABAILABLE = 434,
  INTERNAL_SERVER_ERROR = 500,
  NOT_IMPLEMENTED = 501,
  BAD_GETAWAY = 502,
  SERVICE_UNAVAILABLE = 503,
  GATEWAY_TIMEOUT = 504
}
