import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Message, StripePlanRequest } from '@api/types';
import { Observable } from 'rxjs';
import { ResumeService } from './plans/resume.service';

@Injectable({
  providedIn: 'root'
})
export class PlansService {
  constructor(private http: HttpClient, public resume: ResumeService) {}

  /**
   * Gets all the payment plans
   */
  get(): Observable<any> {
    return this.http.get('/accounts/plans');
  }

  /**
   * Users can create or update to a new payment subscription.
   */
  post(body: StripePlanRequest): Observable<Message> {
    return this.http.post<Message>('/accounts/plans', body);
  }

  /**
   * Users can cancel their current payment subscription.
   */
  delete(): Observable<any> {
    return this.http.delete('/accounts/plans');
  }
}
