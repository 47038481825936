import { Injectable } from '@angular/core';

import { HttpClient, HttpResponse } from '@angular/common/http';
import { FaxTo, TwilioFax } from '@api/types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FaxService {
  constructor(private http: HttpClient) {}

  /**
   * Fax the current document.
   *
   * @summary `POST /documents/{document_id}/fax`
   */
  post(documentId: string, body: FaxTo): Observable<HttpResponse<TwilioFax>> {
    return this.http.post<TwilioFax>(`/documents/${documentId}/fax`, body, { observe: 'response' });
  }
}
