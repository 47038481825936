export enum FilesystemType {
  Folder = 'folder',
  Document = 'document'
}

/**
 * Filesystem
 *
 * Represents a folder or document
 */
export interface Filesystem {
  id: string;
  title: string;
  favorite?: boolean;
  account_id: string;
  parent_id?: string;
  type: FilesystemType;
  shared_account_ids?: string[];
  private_user_id?: string;
  shared_at?: string;
  created_at: string;
  updated_at: string;
  deleted_at?: string;
  folder_id?: string;
}
