import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Document, DocumentUpdate } from '@api/types';
import { Observable } from 'rxjs';
import { DocumentByVersionService } from './byVersion/document-by-version.service';
import { EmailService } from './email/email.service';
import { FaxService } from './fax/fax.service';
import { HistoryService } from './history/history.service';
import { ImportService } from './import/import.service';
import { PagesService } from './pages/pages.service';
import { PublicShareService } from './public_share/public-share.service';
import { ServicesService } from './upload/services.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentByIdService {
  constructor(
    private http: HttpClient,
    // import is a reserved word
    public importService: ImportService,
    public publicShare: PublicShareService,
    public pages: PagesService,
    public fax: FaxService,
    public email: EmailService,
    public uploadService: ServicesService,
    public history: HistoryService,
    public byVersion: DocumentByVersionService
  ) {}

  /**
   * Returns the specified document
   *
   * @summary `GET /documents/{document_id}`
   */
  get(documentId: string): Observable<Document> {
    return this.http.get<Document>(`/documents/${documentId}`);
  }

  /**
   * Updates the specified document.
   * Due to a laravel bug, we need to use a post request with method set to PATCH as a param
   *
   * @summary `POST /documents/{document_id}`
   */
  patchFormData(documentId: string, body: FormData): Observable<Document> {
    return this.http.post<Document>(`/documents/${documentId}`, body, {
      params: new HttpParams().set('_method', 'PATCH')
    });
  }

  /**
   * Updates the specified document.
   *
   * @summary `PATCH /documents/{document_id}`
   */
  patch(documentId: string, body: DocumentUpdate): Observable<Document> {
    return this.http.patch<Document>(`/documents/${documentId}`, body);
  }

  /**
   * Download the specified document
   *
   * @summary `GET /documents/{document_id}/download`
   */
  download(documentId: string, documentType: string, encrypted: boolean): Observable<any> {
    return this.http.get(`/documents/${documentId}/download`, {
      responseType: 'blob',
      observe: 'response',
      headers: new HttpHeaders({ mime_type: documentType, skipE2EE: (!encrypted).toString() })
    });
  }

  /**
   * Delete the given document.
   *
   * @summary `DELETE /accounts/{document_id}`
   */
  delete(documentId: string): Observable<object> {
    return this.http.delete(`/documents/${documentId}`);
  }

  /***
   * Archive the given document.
   */
  archive(documentId: string): Observable<Document> {
    return this.http.post<Document>(`/documents/${documentId}/archive`, {});
  }
}
