import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { AdditionalAuthRequest, Message } from '@api/types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdditionalAuthenticationService {
  constructor(private http: HttpClient) {}

  /**
   * Addition authentication endpoint for 403 response.
   *
   * @summary `POST /additional_authentication`
   */
  post(body: AdditionalAuthRequest): Observable<Message> {
    return this.http.post<Message>('/additional_authentication', body);
  }
}
