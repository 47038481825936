import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpParamsHelper } from '@api/http-params-helper';
import { HistoryGetParams } from '@shared/types/history_get_params';
import { HistoryResponse } from '@shared/types/history_response';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {
  constructor(private http: HttpClient) {}

  /**
   * Get current document version information.
   *
   * @summary `GET /documents/{document_id}/history`
   */
  get(documentId: string, historyGetParams: HistoryGetParams): Observable<HistoryResponse> {
    return this.http.get(`/documents/${documentId}/history`, {
      params: HttpParamsHelper.getHttpQueryParams(historyGetParams)
    });
  }
}
