import { Injectable } from '@angular/core';

export interface GoogleTagManagerData {
  [key: string]: any;
}

@Injectable()
/**
 * Manages events and data for the Google Tag Manager
 */
export class GoogleTagMangerService {
  constructor() {
    // Make sure the dataLayer property exists on the window object,
    // or we will not be able to communicate with GTM.
    if (!Object.hasOwnProperty.call(window, 'dataLayer')) {
      throw new Error('`window.dataLayer` is not defined.');
    }
  }

  /**
   * Pushes data to the data layer.
   *
   * @param data The data to push.
   */
  push(data: GoogleTagManagerData): void {
    window['dataLayer'].push(data);
  }

  /**
   * Triggers a event by pushing an event with data to the data layer.
   *
   * @param event The name of the event.
   * @param data The data to push.
   */
  trigger(event: string, data?: GoogleTagManagerData): void {
    this.push({
      event,
      ...data
    });
  }
}
