import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { MasqueradeRequest, Message } from '@api/types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RequestService {
  constructor(private http: HttpClient) {}

  /**
   * Broadcast masquerading request from the back office user to the requesting user.
   *
   * @summary `POST /masquerade/request`
   */
  post(body: MasqueradeRequest): Observable<Message> {
    return this.http.post<Message>('/masquerade/request', body);
  }
}
