import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ByIdService {
  constructor(private http: HttpClient) {}

  /**
   * Returns compressed documents
   *
   * @summary `GET /documents/download/{downloadId}`
   */
  get(downloadId: string): Observable<any> {
    return this.http.get(`/documents/download/${downloadId}`, {
      headers: new HttpHeaders({ Accept: 'application/zip' }),
      responseType: 'blob'
    });
  }
}
