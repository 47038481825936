import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { DocumentUpload, DocumentUploadResponse } from '@api/types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ServicesService {
  constructor(private http: HttpClient) {}

  /**
   * Upload the document to the service, service can be one of the following: box, dropbox, evernote or gdrive
   *
   * @summary `POST /documents/{document_id}/upload/{service}`
   */
  post(documentId: string, service: string, body: DocumentUpload): Observable<DocumentUploadResponse> {
    return this.http.post<DocumentUploadResponse>(`/documents/${documentId}/upload/${service}`, body);
  }
}
