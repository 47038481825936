import { Injectable } from '@angular/core';

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ExportService, ExportServiceVerificationCode } from '@api/types';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VerifyCodeService {
  constructor(private http: HttpClient) {}

  /**
   * Patch the verification code to get the authorization token
   *
   * The API will send a 409 response when the service is already verified. and the Error response
   * will have the ExportService object for the request. So catch the errors and if 409 parse the
   * response and resolve the observable to success.
   *
   * @summary `PATCH /user/{userId}/export_service/{service}`
   */
  patch(usersId: string, service: string, body: ExportServiceVerificationCode): Observable<ExportService> {
    return this.http.patch<ExportService>(
      `/users/${usersId}/export_services/${service}`,
      body).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 409) {
            const response: ExportService = error.error;
            return of(response);
          }
          throw(error);
        })
      );
  }
}
