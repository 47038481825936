import { Injectable } from '@angular/core';

import { UserRole } from '@api/types';
import { LocalStorageService } from '@shared/services/local-storage.service';

export enum UserRoleGroup {
  Admin,
  Main
}

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  constructor(private localStorage: LocalStorageService) {}

  get group(): UserRoleGroup {
    if (!this.localStorage.userWithToken) return;
    return this.getRoleGroup(this.localStorage.userWithToken.role);
  }

  getRoleGroup(role: UserRole) {
    switch (role) {
      case UserRole.Admin:
      case UserRole.Superadmin:
        return UserRoleGroup.Admin;
      case UserRole.Contributor:
      case UserRole.Editor:
      case UserRole.Owner:
      case UserRole.Read:
      case UserRole.View:
        return UserRoleGroup.Main;
    }
  }
}
