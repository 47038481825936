import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Invoice, PaginationGet } from '@api/types';
import { Observable } from 'rxjs';
import { InvoicesByIdService } from './byId/invoices-by-id.service';

@Injectable({
  providedIn: 'root'
})
export class InvoicesService {
  constructor(private http: HttpClient, public byId: InvoicesByIdService) {}

  /**
   * Gets all of the invoices
   */
  get(): Observable<PaginationGet<Invoice>> {
    return this.http.get<PaginationGet<Invoice>>('/invoices');
  }
}
