import { Injectable } from '@angular/core';

import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { environment } from '@env/environment';
import * as Sentry from '@sentry/browser';
import { EnvironmentType } from '@shared/types/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ErrorMessageInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((response: HttpErrorResponse) => {
        if (this.isDbError(response.error.message)) {
          response.error.message = `A server error occurred (${response.status})`;
          if (environment.type === EnvironmentType.Production) Sentry.captureException(response);
        }
        return throwError(response);
      })
    );
  }

  private isDbError(message: string) {
    if (message) return message.includes('SQL');
  }
}
