import { Environment, EnvironmentType } from '@shared/types/environment';

export const environment: Environment = {
  api: {
    url: 'https://api.raven.com',
    client: { id: 1, secret: 'f8nFepUoaN68ShoTEyXe4eKAZ1W0iONu8miPeAUK' }
  },
  connectionsRedirectUrl: 'https://app.raven.com/account/connections',
  contentful: {
    space: '7xnu0jr5ripu',
    accessToken: '5c61664696055a6ea25280703e29d13bd4b572a1a06cd90214ee0d82012de31c'
  },
  froalaEditorKey: 'uC3A1D7C6nD5D4B3D3C3D7E5C2A4C3alI-7vdlE4gjk==',
  laravelEchoHost: 'https://echo.raven.com',
  PDFTronLicense:
    // eslint-disable-next-line max-len
    'Starfish Technologies LLC (raven.com):OEM:Raven Cloud::B+:AMS(20230227):D8A5A14D04D7280AF360B13AC982737860610F7EB7362A038D2C6592586C70A002F431F5C7',
  stripeKey: 'pk_live_RM09AqtwS9zUQftxLW7hrjLi',
  type: EnvironmentType.Production
};
