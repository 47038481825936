<div class="page-not-found grid-y align-center text-center">
  <div class="cell">
    <h4>Oops, something went wrong!</h4>
    <img src="assets/images/404.png" class="margin-vertical-1" />
    <h4>
      {{
        fromHiddenHistory
          ? 'History has been disabled for the account. Please see your account owner.'
          : 'We couldn\'t find what you were looking for.'
      }}
    </h4>
    <button (click)="location.back()" class="button margin-top-1">Go Back</button>
  </div>
</div>
