import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TestScanByIdService {
  constructor(private http: HttpClient, private domSanitizer: DomSanitizer) {}

  // TODO: how to handle response
  /**
   * Returns the page file associated with this TestScan. Note the {test_scan_id} is
   * the id of the TestScan, and not the Page.
   *
   * @summary `GET /test_scans/{test_scan_id}/preview`
   */
  get(testScanId: string): Observable<any> {
    return this.http
      .get(`/test_scans/${testScanId}/preview`, { responseType: 'blob' })
      .pipe(map((res) => this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(res))));
  }
}
