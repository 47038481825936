import { UserCreateWithAccount } from './user_create_with_account';

export enum AccountCreateType {
  Free = 'free',
  Basic = 'basic',
  Pro = 'pro'
}

export enum AccountCreateSignupSource {
  Scanner = 'scanner',
  Web = 'web',
  Desktop = 'desktop',
  Mobile = 'mobile'
}

/**
 * Account Creation
 *
 * A Request to create a Raven Cloud Account
 */
export interface AccountCreate {
  name?: string;
  email: string;
  type?: AccountCreateType;
  signup_source: AccountCreateSignupSource;
  street1?: string;
  street2?: string;
  city?: string;
  state?: string;
  postal_code?: string;
  country?: string;
  phone_number: string;
  user: UserCreateWithAccount;
}
