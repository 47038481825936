import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Document } from '@api/types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PublicShareService {
  constructor(private http: HttpClient) {}

  /**
   * Share a document publicly
   *
   * @summary `POST /documents/{document_id}/public_share`
   */
  post(documentId: string): Observable<Document> {
    return this.http.post<Document>(`/documents/${documentId}/public_share`, {});
  }

  /**
   * Unshare a document publicly
   *
   * @summary `DELETE /documents/{document_id}/public_share`
   */
  delete(documentId: string): Observable<Document> {
    return this.http.delete<Document>(`/documents/${documentId}/public_share`);
  }
}
