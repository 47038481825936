export enum AccountType {
  Free = 'free',
  Basic = 'basic',
  Pro = 'pro'
}

export enum AccountSignupSource {
  Scanner = 'scanner',
  Web = 'web',
  Desktop = 'desktop',
  Mobile = 'mobile'
}

export enum AccountSubscriptionStatus {
  None = 'none',
  Trial = 'trial',
  ScheduledToUpgrade = 'scheduledToUpgrade',
  ScheduledToCancel = 'scheduledToCancel',
  Subscribed = 'subscribed'
}

export enum AccountE2eEncryption {
  Inactive = 'inactive',
  Active = 'active',
  Activating = 'activating',
  Deactivating = 'deactivating'
}

/**
 * Account
 *
 * A Raven Cloud Account
 */
export interface Account {
  id: string;
  name: string;
  email: string;
  street1: string;
  street2?: string;
  city: string;
  state?: string;
  postal_code: string;
  country: string;
  phone_number: string;
  incoming_email?: string;
  incoming_fax?: string;
  card_brand?: string;
  card_last_four?: string;
  type?: AccountType;
  signup_source: AccountSignupSource;
  payment?: string;
  created_at: string;
  updated_at: string;
  last_activity: string;
  document_count: number;
  trial_ends_at: string | null;
  subscription_plan: string;
  subscription_status: AccountSubscriptionStatus;
  passwords_expire_in?: number;
  e2e_encryption?: AccountE2eEncryption;
  user_2fa_required?: boolean;
}
