export enum RavIcon {
  AddressBook = 'address-book',
  ArrowDoubleLeft = 'arrow-double-left',
  ArrowDoubleRight = 'arrow-double-right',
  ArrowDown = 'arrow-down',
  ArrowDownward = 'arrow-downward',
  ArrowLeft = 'arrow-left',
  ArrowRight = 'arrow-right',
  ArrowRightLine = 'arrow-right-line',
  ArrowTopBottom = 'arrow-top-bottom',
  ArrowUpward = 'arrow-upward',
  BackwardsArrow = 'backwards-arrow',
  Box = 'box',
  BoxLogo = 'box-logo',
  Briefcase = 'briefcase',
  Calendar = 'calendar',
  Cancel = 'cancel',
  CaretDown = 'caret-down',
  CaretUp = 'caret-up',
  Check = 'check',
  Checkbox = 'checkbox',
  CheckBox = 'check-box',
  CheckMark = 'check-mark',
  CloudArrow = 'cloud-arrow',
  CogWheel = 'cog-wheel',
  Contact = 'contact',
  Contacts = 'contacts',
  Document = 'document',
  DownArrow = 'down-arrow',
  Drag = 'drag',
  DropboxLogo = 'dropbox-logo',
  EllipsisVertical = 'ellipsis-v',
  Email = 'email',
  Evernote = 'evernote',
  Eye = 'eye',
  File = 'file',
  FloppyDisk = 'floppy-disk',
  Folder = 'folder',
  GoogleDriveLogo = 'google-drive-logo',
  Graph = 'graph',
  ImageDocument = 'image-document',
  Inbox = 'inbox',
  LongArrowRight = 'long-arrow-right',
  MagnifyingGlass = 'magnifying-glass',
  Mail = 'mail',
  MobileAdd = 'mobile-add',
  MobileCheckbox = 'mobile-check-box',
  Neat = 'neat',
  NewFolder = 'new-folder',
  NewTemplate = 'new-template',
  Number = 'number',
  OpenFolder = 'open-folder',
  Upload = 'upload',
  User = 'user',
  Paypal = 'paypal',
  PdfDocument = 'pdf-document',
  PdfFile = 'pdf-file',
  Pencil = 'pencil',
  PencilLine = 'pencil-line',
  Person = 'person',
  Phone = 'phone',
  PowerOff = 'power-off',
  QuestionMark = 'question-mark',
  Quickbooks = 'quickbooks',
  OneNote = 'onenote',
  Radio = 'radio',
  Rotate = 'rotate',
  Scanner = 'scanner',
  Sharepoint = 'sharepoint',
  Star = 'star',
  Template = 'template',
  Text = 'text',
  ThreePeople = 'three-people',
  Time = 'time',
  Trash = 'trash',
  TwoPeople = 'two-people',
  Visa = 'visa',
  Warning = 'exclamation-triangle-solid',
  Website = 'website',
  EditorCancel = 'editor-cancel',
  OneDrive = 'one-drive',
  Outbox = 'outbox',
  ShoppingCart = 'shopping-cart-icon',
  ImportCsv = 'import-csv',
  UploadCsv = 'upload-csv',
  Clio = 'clio'
}

export enum RavIconOutlined {
  Add = 'add-o',
  AddFolder = 'add-folder-o',
  AddMail = 'add-mail-o',
  AddUser = 'add-user-o',
  Archive = 'archive-o',
  Binoculars = 'binoculars-o',
  Box = 'box-o',
  Briefcase = 'briefcase-o',
  Check = 'check-o',
  CheckBox = 'check-box-o',
  CogWheel = 'cog-wheel-o',
  Combine = 'combine-o',
  Delete = 'delete-o',
  Download = 'download-o',
  Duplicate = 'duplicate-o',
  EnvelopeResend = 'envelope-resend-o',
  Eye = 'eye-o',
  Filter = 'filter-o',
  Folder = 'folder-o',
  Graph = 'graph-o',
  Grid = 'grid-o',
  Inbox = 'inbox-o',
  Lock = 'lock-o',
  Mail = 'mail-o',
  Move = 'move-o',
  Note = 'note-o',
  NewTab = 'new-tab-o',
  OpenFolder = 'open-folder-o',
  Pencil = 'pencil-o',
  PencilLine = 'pencil-line-o',
  Person = 'person-o',
  Print = 'print-o',
  Scanner = 'scanner-o',
  ScreenShare = 'screen-share-o',
  Share = 'share-o',
  Star = 'star-o',
  Template = 'template-o',
  ThreePeople = 'three-people-o',
  Trash = 'trash-o',
  TwoPeople = 'two-people-o',
  Outbox = 'outbox-o'
}

export enum RavIconCircled {
  Add = 'add-c',
  ArrowDown = 'arrow-down-c',
  ArrowRight = 'arrow-right-c',
  ArrowUp = 'arrow-up-c',
  BackwardsArrow = 'backwards-arrow-c',
  Binoculars = 'binoculars-c',
  Box = 'box-c',
  Cancel = 'cancel-c',
  Check = 'check-mark-c',
  Columns = 'columns-c',
  Combine = 'combine-c',
  Download = 'download-c',
  Duplicate = 'duplicate-c',
  Ellipsis = 'ellipsis-c',
  Envelope = 'envelope-c',
  File = 'file-c',
  Filter = 'filter-c',
  Folder = 'folder-c',
  Minus = 'minus-c',
  Lock = 'lock-c',
  Move = 'move-c',
  Note = 'note-c',
  NewTab = 'new-tab-c',
  Person = 'person-c',
  Printer = 'printer-c',
  Question = 'question-c',
  ScreenShare = 'screen-share-c',
  Template = 'template-c',
  Trash = 'trash-c',
  Rotate = 'rotate-c',
  Pencil = 'pencil-c',
  Rename = 'rename-c',
  Save = 'save-c',
  ImportCsv = 'import-csv-c'
}

export enum RavIconCircledOutlined {
  Add = 'add-co',
  ArrowRight = 'arrow-right-outline',
  BackwardsArrow = 'backwards-arrow-co',
  Binoculars = 'binoculars-co',
  Box = 'box-co',
  Combine = 'combine-co',
  Currency = 'currency-co',
  DownArrow = 'down-arrow-co',
  Download = 'download-co',
  Image = 'image-co',
  Lock = 'lock-co',
  Minus = 'minus-co',
  More = 'more-co',
  Move = 'move-co',
  Note = 'note-co',
  Rotate = 'rotate-co',
  Save = 'save-co',
  ScreenShare = 'screen-share-co',
  Trash = 'trash-co',
  Pencil = 'pencil-co'
}
