import { Document } from './document';
import { User } from './user';

export enum DocumentHistoryLastAction {
  Created = 'Created',
  DataUpdated = 'Data Updated',
  Edited = 'Edited',
  Combined = 'Combined',
  VersionReverted = 'Version Reverted'
}

/**
 * DocumentHistory
 *
 * A Raven Cloud Document History version
 */
export interface DocumentHistory extends Document {
  document_id: string;
  last_actor?: User;
  last_action?: DocumentHistoryLastAction;
}
