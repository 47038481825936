import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Scanner, ScannerUpdate } from '@api/types';
import { Observable } from 'rxjs';
import { RegisteredService } from './isRegistered/registered.service';
import { TestScansService } from './test_scans/test-scans.service';

@Injectable({
  providedIn: 'root'
})
export class ScannerByIdService {
  constructor(private http: HttpClient, public testScans: TestScansService, public isRegistered: RegisteredService) {}

  /**
   * Display Scanner info. Note that this will return 404 if the scanner is not in the system, unlike isRegistered
   *
   * @summary `GET /scanners/{id}`
   */
  get(id: string): Observable<Scanner> {
    return this.http.get<Scanner>(`/scanners/${id}`);
  }

  /**
   * Updates a Scanner instance, e.g., to register it.
   *
   * @summary `PATCH /scanners/{id}`
   */
  patch(id: string, body: ScannerUpdate): Observable<Scanner> {
    return this.http.patch<Scanner>(`/scanners/${id}`, body);
  }
}
