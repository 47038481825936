import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpParamsHelper } from '@api/http-params-helper';
import { DocumentsGetParams, FolderWithPaginatedChildrenAndDocuments } from '@api/types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ArchiveService {
  constructor(private http: HttpClient) {}

  /**
   * Request all the archived documents and folders
   *
   * @summary `GET /accounts/{account_id}/archive`
   */
  get(accountId: string, params?: DocumentsGetParams): Observable<FolderWithPaginatedChildrenAndDocuments> {
    return this.http.get<FolderWithPaginatedChildrenAndDocuments>(`/accounts/${accountId}/archive`, {
      params: HttpParamsHelper.getHttpQueryParams(params)
    });
  }
}
