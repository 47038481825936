import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '@api/types';
import { AccountKeypairService } from '@api/users/byId/account_keypair/account-keypair.service';
import { Observable } from 'rxjs';
import { ContactsService } from './contacts/contacts.service';
import { ExportServicesService } from './export_services/export-services.service';
import { OutboxService } from './outbox/outbox.service';
import { SettingsService } from './settings/settings.service';
import { UserKeypairService } from './user_keypair/user-keypair.service';

@Injectable({
  providedIn: 'root'
})
export class UserByIdService {
  constructor(
    private http: HttpClient,
    public exportServices: ExportServicesService,
    public contacts: ContactsService,
    public outbox: OutboxService,
    public settings: SettingsService,
    public accountKeypair: AccountKeypairService,
    public userKeypair: UserKeypairService
  ) {}

  /**
   * Should return a user object if found
   *
   * @summary `GET /users/{userId}`
   */
  get(userId: string): Observable<User> {
    return this.http.get<User>(`/users/${userId}`);
  }

  /**
   * Update the given user.
   *
   * @summary `PATCH /users/{userId}`
   */
  patch(userId: string, body: any): Observable<User> {
    return this.http.patch<User>(`/users/${userId}`, body);
  }

  /**
   * Delete the given user.
   *
   * @summary `DELETE /users/{userId}`
   */
  delete(userId: string): Observable<object> {
    return this.http.delete(`/users/${userId}`);
  }
}
