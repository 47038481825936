import { Injectable } from '@angular/core';
import { BatchImportJob, DragImportObject, ImportJob, ImportType } from '@shared/types/import';
import { Subject } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ImportStatusService {
  createNewJobsAnnouncedSource = new Subject<BatchImportJob>();
  dragImportAnnouncedSource = new Subject<DragImportObject>();
  currentTablePageRefreshAnnouncedSource = new Subject();
  documentNotToShowAsImportAnnouncedSource = new Subject<string>();
  createNewJobsAnnounced$ = this.createNewJobsAnnouncedSource.asObservable();
  dragImportAnnounced$ = this.dragImportAnnouncedSource.asObservable();
  currentTablePageRefreshAnnouncedSource$ = this.currentTablePageRefreshAnnouncedSource.asObservable().pipe(
    // Call the API once a value is emitted here (first and last values), then ignores subsequent source
    // values for `duration` milliseconds, then repeats this process.
    // This is needed to avoid multiple API requests simultaneously within 10 seconds.
    throttleTime(10 * 1000, undefined, { leading: true, trailing: true })
  );
  documentNotToShowAsImportAnnouncedSource$ = this.documentNotToShowAsImportAnnouncedSource.asObservable();

  constructor() {}

  createNewJobs(jobs: ImportJob[], type: ImportType) {
    this.createNewJobsAnnouncedSource.next({ jobs, type });
  }

  dragImport(dragImport: DragImportObject) {
    this.dragImportAnnouncedSource.next(dragImport);
  }

  refreshCurrentTablePage() {
    this.currentTablePageRefreshAnnouncedSource.next();
  }

  /**
   * Handle a scenario when downloading a document with 202.
   *
   * @param documentId Document ID
   */
  documentNotToShowAsImport(documentId: string) {
    this.documentNotToShowAsImportAnnouncedSource.next(documentId);
  }
}
