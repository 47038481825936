import { Injectable } from '@angular/core';
import { ApiService } from '@api/api.service';
import { Keypair } from '@api/types';
import { default as Sodium } from 'libsodium-wrappers';
import { StatusCodes } from '@shared/types/status-codes';
import { TaggedError } from '@shared/types/tagged-error';
import { HashService } from './hash.service';
import { LocalStorageService } from './local-storage.service';
import { SessionStorageService } from './session-storage.service';

export interface EncryptionKeySet<T> {
  userKeyPair: T;
  accountKeyPair?: T;
  fileKey?: T;
}

export type EncryptionKeyPair = BinaryKeyPair | (Base64KeyPair & { decrypted: boolean });

export type BinaryKeyPair = Sodium.KeyPair;
export type Base64KeyPair = Sodium.StringKeyPair;

export type Base64KeySet = EncryptionKeySet<Base64KeyPair>;
export type BinaryKeySet = EncryptionKeySet<BinaryKeyPair>;

export interface EncryptedFile {
  contents: Uint8Array;
  key: Uint8Array;
}

export interface EncodedEncryptionKeys {
  encodedUserKeyPair: Base64KeyPair;
  encodedAccountKeyPair: Base64KeyPair;
}

@Injectable({
  providedIn: 'root'
})
export class EncryptionKeyService {
  private get userId() {
    return this.localStorage.userWithToken.id;
  }
  // private attribute that should be called by using 'getKeySet()' and 'setKeySet()' accordingly
  private _keySet: BinaryKeySet;

  constructor(
    private localStorage: LocalStorageService,
    private sessionStorage: SessionStorageService,
    private hashService: HashService,
    private api: ApiService
  ) {}

  async initialize(hash: string) {
    await this.getKeySet(hash);
    // if there is an error, we could prevent e2e encryption using if the keyset comes back empty
  }

  public async waitForLibSodium() {
    await Sodium.ready;
  }

  public async createBaseKeyPairs(): Promise<void> {
    this.setKeySet({
      userKeyPair: await this.createKeypair(),
      accountKeyPair: await this.createKeypair()
    });
  }

  public async createBaseAccountKeyPair(): Promise<void> {
    // to avoid any possible race condition we fist make sure that this._keySet.accountKeyPair is empty
    if (!this._keySet || !this._keySet.accountKeyPair) {
      this.setKeySet({
        accountKeyPair: await this.createKeypair()
      });
    }
  }

  public async createAccountKeys(userId: string): Promise<Base64KeyPair> {
    const userKeyPair = await this.api.users.byId.userKeypair.get(userId).toPromise();
    const accountKeyPair = (await this.getKeySet()).accountKeyPair;
    const accountKeyPairEncrypted: BinaryKeyPair = {
      keyType: accountKeyPair.keyType,
      publicKey: accountKeyPair.publicKey,
      privateKey: this.encryptPair(
        accountKeyPair.privateKey,
        Sodium.from_base64(userKeyPair.publicKey, Sodium.base64_variants.URLSAFE_NO_PADDING)
      )
    };
    return this.binaryToB64Keys(accountKeyPairEncrypted);
  }

  public async createDisableKeyPair(passwordHash: string) {
    await this.waitForLibSodium();
    this.clearKeys();
    return this.binaryToB64Keys((await this.getKeySet(passwordHash)).accountKeyPair);
  }

  public async updateAndSaveUserKeyPair(hash: string) {
    try {
      const newUserKeyPair = await this.createUserKeyPair(hash);
      await this.api.users.byId.userKeypair.put(this.userId, this.binaryToB64Keys(newUserKeyPair)).toPromise();
    } catch (e) {
      throw new TaggedError(e.message, { e2eEncryption: 'updateAndSaveUserKeyPair' });
    }
  }

  /**
   * Generate a new EncryptionKeySet for a new user based his new ID
   *
   * @param newUserId The new user's ID
   * @param ownerId The Id of the owner user (The creator of the new user)
   * @param passwordKey The Hashed password key of the owner user (The creator of the new user)
   */
  async generateNewUserEncryptionKeySet(newUserId: string): Promise<Base64KeySet> {
    const localKeySet = await this.getKeySet();
    const newUserKeypair = await this.createNewUserUserKeyPair(newUserId);

    const newUserAccountKeypairPrivate = this.encryptPair(
      localKeySet.accountKeyPair.privateKey,
      newUserKeypair.publicKey
    );

    const newUserAccountKeyPair = {
      keyType: localKeySet.accountKeyPair.keyType,
      publicKey: localKeySet.accountKeyPair.publicKey,
      privateKey: newUserAccountKeypairPrivate
    };

    return {
      userKeyPair: this.binaryToB64Keys(newUserKeypair),
      accountKeyPair: this.binaryToB64Keys(newUserAccountKeyPair)
    };
  }

  /**
   * Update the new user's KeyPair
   *
   * @param userId The user id
   * @param userKeypairEncoded The encoded Users KayPair
   * @param password The user password
   * @returns A Promise to update the users keypair
   */
  async updateNewUserUserKeyPair(userId: string, userKeypairEncoded: Keypair, password: string) {
    try {
      const userKeypair = this.b64ToBinaryKeys(userKeypairEncoded);

      const oldHash = await this.hashService.hash(userId);
      const oldPasswordKey = await this.passwordToKey(oldHash, userId);

      const privateKey = this.decryptSymmetric(userKeypair.privateKey, oldPasswordKey);

      const newHash = await this.hashService.hash(password);
      const newPasswordKey = await this.passwordToKey(newHash, userId);
      const encryptedPrivateKey = this.encryptSymmetric(privateKey, newPasswordKey);

      const newUserKeyPair: BinaryKeyPair = {
        keyType: userKeypair.keyType,
        publicKey: userKeypair.publicKey,
        privateKey: encryptedPrivateKey
      };
      return this.binaryToB64Keys(newUserKeyPair);
    } catch (e) {
      throw new TaggedError(e.message, { e2eEncryption: 'updateNewUserUserKeyPair' });
    }
  }

  /**
   * Encrypts a file using Sodium
   *
   * @param document File as Uint8Array to be encrypted
   * @param currentFileKey (optional) if present, decrypts incoming key and uses it to encrypt the file instead of creating a new one
   * @returns EncryptedFile object containing the encrypted file and the encryption key pair
   */
  public async encryptDocument(document: Uint8Array, currentFileKey?: Uint8Array): Promise<EncryptedFile> {
    const fileKey = currentFileKey
      ? await this.decryptFileKey(currentFileKey)
      : Sodium.randombytes_buf(Sodium.crypto_secretbox_KEYBYTES);

    const encryptedFile = this.encryptSymmetric(document, fileKey);
    const encryptedFileKey = this.encryptPair(fileKey, (await this.getKeySet()).accountKeyPair.publicKey);

    return {
      contents: encryptedFile,
      key: encryptedFileKey
    };
  }

  /**
   * Decrypts a file using Sodium
   *
   * @param document EncryptedFile object to be decrypted
   * @returns Decrypted File as Uint8Array
   */
  public async decryptDocument(document: EncryptedFile): Promise<Uint8Array> {
    const decryptedFileKey = await this.decryptFileKey(document.key);

    return this.decryptSymmetric(document.contents, decryptedFileKey);
  }

  public clearKeys() {
    this.sessionStorage.deleteEncryptionKeys();
    this._keySet = null;
  }

  public async clearAndFetch() {
    this.clearKeys();
    await this.getKeySet();
  }

  /** PRIVATE METHODS */

  private async passwordToKey(
    passwordHash: string,
    userId: string = this.localStorage.userWithToken.id
  ): Promise<Uint8Array> {
    if (userId) {
      return Sodium.crypto_generichash(Sodium.crypto_secretbox_KEYBYTES, passwordHash + userId);
    }
    throw new Error('Error creating user keys');
  }

  private async createKeypair() {
    return Sodium.crypto_box_keypair();
  }

  /**
   * Generate user keys.
   */
  private async createAndSaveUserKeyPair(passwordHash: string) {
    await this.createBaseKeyPairs();
    const userKeyPair = await this.createUserKeyPair(passwordHash);
    const toBase64KeyPair = this.binaryToB64Keys(userKeyPair);
    await this.api.users.byId.userKeypair.post(this.userId, toBase64KeyPair).toPromise();
    return toBase64KeyPair;
  }

  /**
   * Saves user KeyPair in Session Storage
   */
  private async setSession(keypair: BinaryKeyPair, key?: string) {
    this.sessionStorage.privateKey = keypair.privateKey;
    this.sessionStorage.publicKey = keypair.publicKey;
    if (key) this.sessionStorage.key = await this.passwordToKey(key);
  }

  /**
   * Used to check if both keyPairs of the local user exist, and return them.
   *
   * @returns the latest version of the local keySet
   */
  private async getKeySet(hash?: string): Promise<BinaryKeySet> {
    try {
      if (!this._keySet || !this._keySet.userKeyPair || hash) {
        let accountKeyPair: Base64KeyPair;
        const userKeyPair = await this.getLocalUserKeyPair(hash);
        if (userKeyPair) {
          // if userKeyPair is empty the keys are just created and accountKeyPair doesn't exist yet.
          accountKeyPair = await this.getLocalAccountKeyPair();
        }
        if (userKeyPair && accountKeyPair) {
          // enable keys for document handling only if both keyPairs are ready.
          await this.enableKeysForDocumentHandling({ userKeyPair, accountKeyPair }, hash);
        }
      }
      return this._keySet;
    } catch (e) {
      if (e.status === StatusCodes.NOT_FOUND) {
        throw new Error('AccountKeypair not found');
      } else {
        throw new TaggedError(e.message, { e2eEncryption: 'getKeySet' });
      }
    }
  }

  private async getLocalUserKeyPair(hash: string): Promise<Base64KeyPair> {
    if (this.sessionStorage.privateKey && this.sessionStorage.publicKey) {
      return {
        keyType: 'x25519',
        privateKey: Sodium.to_base64(
          new Uint8Array(Object.values(this.sessionStorage.privateKey)),
          Sodium.base64_variants.URLSAFE_NO_PADDING
        ),
        publicKey: Sodium.to_base64(
          new Uint8Array(Object.values(this.sessionStorage.publicKey)),
          Sodium.base64_variants.URLSAFE_NO_PADDING
        )
      };
    } else {
      let userKeyPair: Base64KeyPair;
      try {
        userKeyPair = await this.api.users.byId.userKeypair.get(this.userId).toPromise();
        return userKeyPair;
      } catch (e) {
        if (e.status === StatusCodes.NOT_FOUND) {
          userKeyPair = await this.createAndSaveUserKeyPair(hash);
          return;
        }
        throw new TaggedError(e.message, { e2eEncryption: 'getLocalUserKeyPair' });
      } finally {
        this.setSession(this.b64ToBinaryKeys(userKeyPair), hash);
      }
    }
  }

  private async getLocalAccountKeyPair() {
    let accountKeyPair: Base64KeyPair;
    try {
      accountKeyPair = await this.api.users.byId.accountKeypair.get(this.userId).toPromise();
      return accountKeyPair;
    } catch (e) {
      if (e.status === StatusCodes.NOT_FOUND) {
        await this.createBaseAccountKeyPair();
        return;
      }
      throw new TaggedError(e.message, { e2eEncryption: 'getLocalAccountKeyPair' });
    }
  }

  private async setKeySet(newKeySet) {
    this._keySet = { ...this._keySet, ...newKeySet };
  }

  private async createUserKeyPair(passwordHash: string): Promise<BinaryKeyPair> {
    const { userKeyPair } = await this.getKeySet();
    return this.encryptUserKeypair(passwordHash, userKeyPair);
  }

  /**
   * Encrypt User-KeyPair PrivateKey
   *
   * @param passwordHash User password
   * @param decryptedUserKeypair Decrypted User-Keypair private key
   * @returns A promise with a Base64 User-KeyPair encrypted
   */
  private async encryptUserKeypair(
    passwordHash: string,
    decryptedUserKeypair: BinaryKeyPair,
    userId?: string
  ): Promise<BinaryKeyPair> {
    const { privateKey, publicKey, keyType } = decryptedUserKeypair;
    const passwordKey = await this.passwordToKey(passwordHash, userId);
    const encryptedPrivateKey = this.encryptSymmetric(privateKey, passwordKey);

    const userKeyPairEncrypted: BinaryKeyPair = {
      keyType,
      publicKey,
      privateKey: encryptedPrivateKey
    };

    return userKeyPairEncrypted;
  }

  /**
   * creates and returns the userKeyPair for new users
   *
   * @param userId the target user's userId
   * @returns the encrypted new user's userKeyPair
   */
  private async createNewUserUserKeyPair(userId: string): Promise<BinaryKeyPair> {
    // Generate a hash based on the  User ID.
    const passwordHash = await this.hashService.hash(userId);
    const userKeypair = await this.createKeypair();
    const userKeypairEncrypted = await this.encryptUserKeypair(passwordHash, userKeypair, userId);
    return userKeypairEncrypted;
  }

  // decryptSymmetric and decryptPair will have been tested and proven compatible with back-end PHP encrypted data when
  // given the correct encryption keys. If adjusting the implementation, please verify compatibility.

  private encryptSymmetric(contents: Uint8Array, key: Uint8Array): Uint8Array {
    const nonce = Sodium.randombytes_buf(Sodium.crypto_secretbox_NONCEBYTES);
    const encrypted = Sodium.crypto_secretbox_easy(contents, nonce, key);

    const ret = new Uint8Array(nonce.byteLength + encrypted.byteLength);
    ret.set(nonce, 0);
    ret.set(encrypted, nonce.byteLength);

    return ret;
  }

  private decryptSymmetric(contentsWithNonce: Uint8Array, key: Uint8Array) {
    if (contentsWithNonce.length < Sodium.crypto_secretbox_NONCEBYTES + Sodium.crypto_secretbox_MACBYTES) {
      throw new Error('Short message');
    }

    const nonce = contentsWithNonce.slice(0, Sodium.crypto_secretbox_NONCEBYTES);
    const contents = contentsWithNonce.slice(Sodium.crypto_secretbox_NONCEBYTES);

    return Sodium.crypto_secretbox_open_easy(contents, nonce, key);
  }

  private encryptPair(contents: Uint8Array, publicKey: Uint8Array): Uint8Array {
    return Sodium.crypto_box_seal(contents, publicKey);
  }

  private decryptPair(contents: Uint8Array, publicKey: Uint8Array, privateKey: Uint8Array): Uint8Array {
    return Sodium.crypto_box_seal_open(contents, publicKey, privateKey);
  }

  private async decryptFileKey(key: Uint8Array) {
    const keySet = await this.getKeySet();
    return this.decryptPair(key, keySet.accountKeyPair.publicKey, keySet.accountKeyPair.privateKey);
  }

  /**
   * Transforms a KeyPair in binary to Base64
   */
  private binaryToB64Keys(keyPair: BinaryKeyPair): Base64KeyPair {
    return {
      keyType: keyPair.keyType,
      publicKey: Sodium.to_base64(keyPair.publicKey, Sodium.base64_variants.URLSAFE_NO_PADDING),
      privateKey: Sodium.to_base64(keyPair.privateKey, Sodium.base64_variants.URLSAFE_NO_PADDING)
    };
  }

  /**
   *  Transforms a KeyPair in Base64 to binary
   */
  private b64ToBinaryKeys(keyPair: Base64KeyPair): BinaryKeyPair {
    return {
      keyType: keyPair.keyType,
      publicKey: Sodium.from_base64(keyPair.publicKey, Sodium.base64_variants.URLSAFE_NO_PADDING),
      privateKey: Sodium.from_base64(keyPair.privateKey, Sodium.base64_variants.URLSAFE_NO_PADDING)
    };
  }

  /**
   * Loads the existing encryption keys for this user and account into the service, for use in file crypto.
   *
   * @param keys The Base64-encoded keys as retrieved from a back-end endpoint.
   * @param password The user's password for decrypting the private user key.
   */
  private async enableKeysForDocumentHandling(keySetEncoded: Base64KeySet, passwordHash?: string): Promise<void> {
    try {
      const userKeyPair = this.b64ToBinaryKeys(keySetEncoded.userKeyPair);
      const accountKeyPair = this.b64ToBinaryKeys(keySetEncoded.accountKeyPair);

      const passwordKey = passwordHash
        ? new Uint8Array(Object.values(await this.passwordToKey(passwordHash)))
        : new Uint8Array(Object.values(this.sessionStorage.key));

      const userKeyPairPrivate = this.decryptSymmetric(userKeyPair.privateKey, passwordKey);
      const accountKeyPairPrivate = this.decryptPair(
        accountKeyPair.privateKey,
        userKeyPair.publicKey,
        userKeyPairPrivate
      );

      this.setKeySet({
        userKeyPair: {
          keyType: userKeyPair.keyType,
          publicKey: userKeyPair.publicKey,
          privateKey: userKeyPairPrivate
        },
        accountKeyPair: {
          keyType: accountKeyPair.keyType,
          publicKey: accountKeyPair.publicKey,
          privateKey: accountKeyPairPrivate
        }
      });
    } catch (e) {
      throw new TaggedError(e.message, { e2eEncryption: 'enableKeysForDocumentHandling' });
    }
  }
}
