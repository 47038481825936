import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ByIdService } from './byId/by-id.service';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {
  constructor(private http: HttpClient, public byId: ByIdService) {}

  /**
   * Submit the document and folder list to be prepared for download
   *
   * @summary `POST /documents/download`
   */
  post(documentIds: string[], folderIds: string[]): Observable<string> {
    return this.http.post<string>('/documents/download', {
      documents: documentIds,
      folders: folderIds
    });
  }
}
