import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { HttpParamsHelper } from '@api/http-params-helper';
import { FolderPaginator, FoldersGetParams } from '@api/types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChildrenService {
  constructor(private http: HttpClient) {}

  /**
   * Gets all subfolders to the given folder
   *
   * @summary `GET /folders/{folder_id}/children`
   */
  get(params: FoldersGetParams, folderId: string): Observable<FolderPaginator> {
    return this.http.get<FolderPaginator>(`/folders/${folderId}/children`, {
      params: HttpParamsHelper.getHttpQueryParams(params)
    });
  }
}
