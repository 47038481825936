import { EmailList } from './email_list';
import { FtpCredentials } from './ftp_credentials';

export enum DocumentDestinationDestination {
  RavenCloud = 'raven-cloud',
  Local = 'local',
  Email = 'email',
  Fax = 'fax',
  Ftp = 'ftp',
  ExportService = 'export-service'
}

export enum DocumentDestinationSource {
  Scanner = 'scanner',
  Import = 'import',
  Email = 'email',
  Fax = 'fax'
}

/**
 * Document Destination
 *
 * A destination for documents
 */
export interface DocumentDestination {
  destination: DocumentDestinationDestination;
  parent_id?: string;
  template_id?: string;
  source?: DocumentDestinationSource;
  email?: EmailList;
  fax?: string;
  ftp?: FtpCredentials;
  service?: string;
  folder_id?: string;
}
