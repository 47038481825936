import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'rav-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent {
  fromHiddenHistory = false;

  constructor(public location: Location, private route: ActivatedRoute) {
    this.route.queryParams.subscribe((params) => {
      if (params && params.history === 'true') this.fromHiddenHistory = true;
    });
  }
}
