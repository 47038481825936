import { Injectable } from '@angular/core';

import { HttpClient, HttpResponse } from '@angular/common/http';
import { Email, Message } from '@api/types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmailService {
  constructor(private http: HttpClient) {}

  /**
   * Email the current document.
   *
   * @summary `POST /documents/{document_id}/email`
   */
  post(documentId: string, body: Email): Observable<HttpResponse<Message>> {
    return this.http.post<Message>(`/documents/${documentId}/email`, body, { observe: 'response' });
  }
}
