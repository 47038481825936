import { A11yModule } from '@angular/cdk/a11y';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule, LowerCasePipe, TitleCasePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { RouterModule } from '@angular/router';
import { RequestLoginService } from '@api/users/request-login.service';
import { RavenFormsModule } from '@forms/raven-forms.module';
import { SnakeCaseToHumanPipe } from '@pipes/snake-case-to-human.pipe';
import { TableCellPipe } from '@pipes/table-cell.pipe';
import { InlineSVGModule } from 'ng-inline-svg';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { NgxZendeskWebwidgetModule } from 'ngx-zendesk-webwidget';
import { ApiModule } from '../api/api.module';
import { ApiService } from '../api/api.service';
import { ShareDocumentComponent } from '../documents/share-document/share-document.component';
import { RouteTransformerDirective } from '../route-transformer';
import { SecurityModule } from '../security/security.module';
import { SnackbarProgressComponent } from './../main/account/snackbar-progress/snackbar-progress.component';
import { AccountDropdownComponent } from './account-dropdown/account-dropdown.component';
import { AccountInfoComponent } from './account-info/account-info.component';
import { AccountNotesComponent } from './account-notes/account-notes.component';
import { ActionsDropdownMenuComponent } from './actions-dropdown-menu/actions-dropdown-menu.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { CombineComponent } from './combine/combine.component';
import { ContactEmailComponent } from './contentful/contact-email/contact-email.component';
import { ContactPhoneComponent } from './contentful/contact-phone/contact-phone.component';
import { SocialMediaLinksComponent } from './contentful/social-media-links/social-media-links.component';
import { CreateFolderComponent } from './create-folder/create-folder.component';
import { CreateUserComponent } from './create-user/create-user.component';
import { ExtraAuthenticationComponent } from './extra-authentication/extra-authentication.component';
import { FolderSelectComponent } from './folder-select/folder-select.component';
import { GlobalSearchComponent } from './global-search/global-search.component';
import { IconComponent } from './icon/icon.component';
import { ImportFilesComponent } from './import-files/import-files.component';
import { ImportFolderComponent } from './import-folder/import-folder.component';
import { ImportManagerComponent } from './import-manager/import-manager.component';
import { ContentComponent } from './layout/content/content.component';
import { LayoutComponent } from './layout/layout.component';
import { FolderMenuComponent } from './layout/sidebar-menu/folder-menu/folder-menu.component';
import { SidebarMenuComponent } from './layout/sidebar-menu/sidebar-menu.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { LoaderComponent } from './loader/loader.component';
import { MessageComponent } from './message/message.component';
import { ModalComponent } from './modal/modal.component';
import { ModalService } from './modal/modal.service';
import { MoveComponent } from './move/move.component';
import { NewButtonComponent } from './new-button/new-button.component';
import { NoticeBarComponent } from './notice-bar/notice-bar.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { QueryParamMessageComponent } from './query-param-message/query-param-message.component';
import { ContentfulService } from './services/contentful.service';
import { DownloadService } from './services/download.service';
import { DragDropService } from './services/drag-drop.service';
import { EditorService } from './services/editor.service';
import { FolderActionsService } from './services/folder-actions.service';
import { HashService } from './services/hash.service';
import { LocalStorageService } from './services/local-storage.service';
import { MobileService } from './services/mobile.service';
import { ScopeService } from './services/scope.service';
import { TokenService } from './services/token.service';
import { ActionsComponent } from './table/actions/actions.component';
import { EditorComponent } from './table/editor/editor.component';
import { MobileActionsComponent } from './table/mobile-actions/mobile-actions.component';
import { MobileTableComponent } from './table/mobile-table/mobile-table.component';
import { NoContentComponent } from './table/no-content/no-content.component';
import { AdvancedSearchComponent } from './table/table-search/advanced-search/advanced-search.component';
import { BasicSearchComponent } from './table/table-search/basic-search/basic-search.component';
import { TableSearchComponent } from './table/table-search/table-search.component';
import { TableComponent } from './table/table.component';
import { VersionComponent } from './table/version/version.component';

@NgModule({
  imports: [
    ApiModule,
    CommonModule,
    InlineSVGModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDividerModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatSelectModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTreeModule,
    RavenFormsModule,
    RavenFormsModule,
    ReactiveFormsModule,
    RouterModule,
    SecurityModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatTooltipModule,
    InlineSVGModule,
    MatIconModule,
    MatSlideToggleModule,
    DragDropModule,
    A11yModule,
    DeviceDetectorModule.forRoot()
  ],
  declarations: [
    ActionsComponent,
    ActionsDropdownMenuComponent,
    ContentComponent,
    CreateUserComponent,
    IconComponent,
    LayoutComponent,
    PageNotFoundComponent,
    SidebarComponent,
    SidebarMenuComponent,
    TableComponent,
    QueryParamMessageComponent,
    ModalComponent,
    TableSearchComponent,
    AdvancedSearchComponent,
    ContactPhoneComponent,
    ContactEmailComponent,
    SocialMediaLinksComponent,
    GlobalSearchComponent,
    BasicSearchComponent,
    AccountDropdownComponent,
    FolderMenuComponent,
    MessageComponent,
    ChangePasswordComponent,
    NewButtonComponent,
    FolderSelectComponent,
    CreateFolderComponent,
    ImportFilesComponent,
    AccountNotesComponent,
    MoveComponent,
    AccountInfoComponent,
    NoticeBarComponent,
    RouteTransformerDirective,
    CombineComponent,
    LoaderComponent,
    EditorComponent,
    ImportFolderComponent,
    VersionComponent,
    ImportManagerComponent,
    ShareDocumentComponent,
    NoContentComponent,
    ExtraAuthenticationComponent,
    MobileTableComponent,
    MobileActionsComponent,
    SnackbarProgressComponent
  ],
  exports: [
    ActionsComponent,
    ActionsDropdownMenuComponent,
    ContentComponent,
    CreateUserComponent,
    IconComponent,
    LayoutComponent,
    PageNotFoundComponent,
    SidebarComponent,
    SidebarMenuComponent,
    TableComponent,
    MessageComponent,
    QueryParamMessageComponent,
    ContactPhoneComponent,
    ContactEmailComponent,
    SocialMediaLinksComponent,
    ModalComponent,
    RavenFormsModule,
    AdvancedSearchComponent,
    MatAutocompleteModule,
    MatButtonModule,
    MatDatepickerModule,
    MatListModule,
    MatNativeDateModule,
    MatInputModule,
    MatSelectModule,
    MatExpansionModule,
    FolderMenuComponent,
    MoveComponent,
    CreateFolderComponent,
    AccountNotesComponent,
    MatDividerModule,
    ChangePasswordComponent,
    RouteTransformerDirective,
    DragDropModule,
    CombineComponent,
    LoaderComponent,
    EditorComponent,
    VersionComponent,
    ImportManagerComponent,
    ShareDocumentComponent,
    ExtraAuthenticationComponent,
    SnackbarProgressComponent,
    NgxZendeskWebwidgetModule
  ],
  providers: [
    ApiService,
    LocalStorageService,
    TokenService,
    ContentfulService,
    ModalService,
    ScopeService,
    DownloadService,
    MobileService,
    EditorService,
    DragDropService,
    FolderActionsService,
    LowerCasePipe,
    SnakeCaseToHumanPipe,
    TitleCasePipe,
    HashService,
    TableCellPipe,
    RequestLoginService
  ],
  entryComponents: [ImportManagerComponent, SnackbarProgressComponent]
})
export class SharedModule {}
