import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class EditorService {
  editorState$: Observable<{ id: string; open: boolean; event: Event }>;
  private openEditorAction = new Subject<{ id: string; open: boolean; event: Event }>();

  constructor() {
    this.editorState$ = this.openEditorAction.asObservable();
  }

  /**
   * Open or close editor messenger
   *
   * @param id document id
   * @param open open or close
   * @param event click event
   */
  openOrCloseEditor(id: string, open: boolean, event: Event) {
    this.openEditorAction.next({ id, open, event });
  }
}
