import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MailTemplatesEventsService {
  constructor(private http: HttpClient) {}

  /**
   * Returns the available mail events.
   */
  get(): Observable<Array<string>> {
    return this.http.get<Array<string>>('/mail_templates/events');
  }
}
