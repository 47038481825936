import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  constructor(private http: HttpClient) {}

  /**
   * Users can activate their account via this route with the token they received in the email.
   *
   * @summary `PATCH /accounts/activation/{token}`
   */
  patch(tokenId: string) {
    this.http.patch(`/accounts/activation/${tokenId}`, {});
  }
}
