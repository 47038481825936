import { Injectable } from '@angular/core';

import { EmailService } from './email/email.service';
import { FaxService } from './fax/fax.service';
import { ServiceByIdService } from './service/byId/service-by-id.service';

@Injectable({
  providedIn: 'root'
})
export class ExportService {
  constructor(public serviceById: ServiceByIdService, public email: EmailService, public fax: FaxService) {}
}
