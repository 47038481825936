import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Document, DocumentCreate, DocumentObject, DocumentsGetParams, PaginationGet } from '@api/types';
import { HttpParamsHelper } from '../http-params-helper';
import { DocumentByIdService } from './byId/document-by-id.service';
import { CombineService } from './combine/combine.service';
import { DownloadService } from './download/download.service';
import { SearchService } from './search/search.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {
  constructor(
    private http: HttpClient,
    public search: SearchService,
    public byId: DocumentByIdService,
    public download: DownloadService,
    public combine: CombineService
  ) {}

  /**
   * With this endpoint, users can create a document from recently uploaded pages.
   * Documents will be placed in the Inbox, and will use the default Document template.
   *
   * @summary `POST /documents`
   */
  post(body: FormData | DocumentCreate): Observable<DocumentObject> {
    return this.http.post<DocumentObject>('/documents', body);
  }

  /**
   * Gets a paginated list of documents. Aside from the listed query parameters,
   * any valid field, standard or custom, can be used to search. E.g., specifying
   * account_id=abc123 will return only documents for that account.
   * If the logged-in user is an admin, or superadmin, it should return documents in
   * the whole system. Otherwise, it should return documents only in the account of the user.
   *
   * @summary `GET /documents`
   */
  get(params?: DocumentsGetParams): Observable<PaginationGet<Document>> {
    return this.http.get<PaginationGet<Document>>('/documents', {
      params: HttpParamsHelper.getHttpQueryParams(params)
    });
  }
}
