import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { E2eEncryptionService } from '@api/accounts/byId/e2e_encryption/e2e-encryption.service';
import { Account, AccountUpdate, AccountWithUsers } from '@api/types';
import { Observable } from 'rxjs';
import { ArchiveService } from './archive/archive.service';
import { InboxService } from './inbox/inbox.service';
import { IncomingEmailService } from './incoming_email/incoming-email.service';
import { AccountInvoicesService } from './invoices/account-invoices.service';
import { NotesService } from './notes/notes.service';
import { SearchService } from './searches/search.service';
import { SettingsService } from './settings/settings.service';
import { SharedDocsService } from './shared/shared-docs.service';
import { StorageTotalsService } from './storage_totals/storage-totals.service';
import { TopFolderService } from './top_folder/top-folder.service';
import { UsersService } from './users/users.service';
import { ValidService } from './valid/valid.service';

@Injectable({
  providedIn: 'root'
})
export class AccountByIdService {
  constructor(
    private http: HttpClient,
    public notes: NotesService,
    public valid: ValidService,
    public users: UsersService,
    public inbox: InboxService,
    public invoices: AccountInvoicesService,
    public topFolder: TopFolderService,
    public shared: SharedDocsService,
    public searches: SearchService,
    public settings: SettingsService,
    public incomingEmail: IncomingEmailService,
    public archive: ArchiveService,
    public e2e_encryption: E2eEncryptionService,
    public storageTotalsService: StorageTotalsService
  ) {}

  /**
   * Returns the currently logged-in account.
   *
   * @summary `GET /accounts/{account_id}`
   */
  get(accountId: string): Observable<AccountWithUsers> {
    return this.http.get<AccountWithUsers>(`/accounts/${accountId}`);
  }

  /**
   * Update the given account.
   *
   * @summary `PATCH /accounts/{account_id}`
   */
  patch(accountId: string, body: AccountUpdate): Observable<Account> {
    return this.http.patch<Account>(`/accounts/${accountId}`, body);
  }

  /**
   * Delete the given account.
   *
   * @summary `DELETE /accounts/{account_id}`
   */
  delete(accountId: string): Observable<object> {
    return this.http.delete(`/accounts/${accountId}`);
  }
}
