import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { DocumentsGetParams, SearchCriteria } from '@api/types';
import { Observable } from 'rxjs';
import { SearchByIdService } from './byId/search-by-id.service';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  constructor(private http: HttpClient, public byId: SearchByIdService) {}

  /**
   * With this endpoint, users can search all the documents belonging to the account.
   * Things/fields that can be searched are page OCR full-text content and document fields.
   *
   * Query parameters must be passed with the body for this route, otherwise the laravel echo server
   * doesn't return search results.
   *
   * @summary `POST /documents/query`
   */
  post(params: DocumentsGetParams, body: Array<SearchCriteria>): Observable<any> {
    return this.http.post<any>('/documents/query', { ...params, criteria: body });
  }
}
