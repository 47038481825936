import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { ExportFolder } from '@api/types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FolderByIdService {
  constructor(private http: HttpClient) {}

  /**
   * Get the folder with the given folder id
   *
   * @summary `GET /export_folders/{service}/{id}`
   */
  get(service: string, id: string): Observable<ExportFolder> {
    return this.http.get<ExportFolder>(`/export_folders/${service}/${id}`);
  }
}
