import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Email } from '@api/types';

@Injectable({
  providedIn: 'root'
})
export class IncomingEmailService {
  constructor(private http: HttpClient) {}

  /**
   * Request a new incoming email address for the account
   *
   * @summary `PATCH /accounts/{account_id}/incoming_email`
   */
  patch(accountId: string): Observable<Email> {
    return this.http.patch<Email>(`/accounts/${accountId}/incoming_email`, {});
  }
}
