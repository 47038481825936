import { Injectable } from '@angular/core';

import { FolderByIdService } from './byId/folder-by-id.service';
import { ListService } from './list/list.service';

@Injectable({
  providedIn: 'root'
})
export class ServiceService {
  constructor(public list: ListService, public byId: FolderByIdService) {}
}
