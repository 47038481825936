import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Email, Message } from '@api/types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmailService {
  constructor(private http: HttpClient) {}

  /**
   * Users post their login email here to receive a token in their email to disable 2FA.
   *
   * @summary `POST /users/2fa/email`
   */
  post(body: Email): Observable<Message> {
    return this.http.post<Message>('/users/2fa/email', body);
  }
}
