export enum QuoteType {
  Joke = 'joke',
  Word = 'word',
  Inspirational = 'inspirational'
}

/**
 * Quote
 *
 * A random joke, word or inspirational
 */
export interface Quote {
  body: string;
  type: QuoteType;
}
