import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Message, NeatImport } from '@api/types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NeatImportService {
  constructor(private http: HttpClient) {}

  /**
   * A webhook for Neat Import project to call after importing is finished
   *
   * @summary `POST /neat_import`
   */
  post(body: NeatImport): Observable<Message> {
    return this.http.post<Message>('/net_import', body);
  }
}
