import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { MasqueradeResponse, Message } from '@api/types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ResponseService {
  constructor(private http: HttpClient) {}

  /**
   * Broadcast masquerading response from the requesting user to the back office user.
   *
   * @summary `POST /masquerade/response`
   */
  post(body: MasqueradeResponse): Observable<Message> {
    return this.http.post<Message>('/masquerade/response', body);
  }
}
