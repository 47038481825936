import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { MailTemplate } from '@api/types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MailTemplateByIdService {
  constructor(private http: HttpClient) {}

  /**
   * Returns specific trashed email template
   *
   * @summary `GET /mail_templates/trash/{id}`
   */
  get(id: string): Observable<MailTemplate> {
    return this.http.get<MailTemplate>(`/mail_templates/trash/${id}`);
  }

  /**
   * Restores deleted mail template
   *
   * @summary `PATCH /mail_templates/trash/{id}`
   */
  patch(id: string): Observable<MailTemplate> {
    return this.http.patch<MailTemplate>(`/mail_templates/trash/${id}`, {});
  }

  /**
   * Hard-deletes specific mail template
   *
   * @summary `DELETE /mail_templates/trash/{id}`
   */
  delete(id: string): Observable<object> {
    return this.http.delete(`/mail_templates/trash/${id}`);
  }
}
