import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Filesystem, FilesystemType } from '@api/types';
import { FolderNode } from '@shared/layout/sidebar-menu/folder-menu/folder-menu.datasource';
import { FolderActionsService } from './folder-actions.service';
import { FolderReferenceService } from './folder-reference.service';

export enum DragDropRegion {
  TableRow = 'TableRow',
  FolderMenuNode = 'FolderMenuNode',
  OtherSidebarMenu = 'OtherSidebarMenu'
}

export interface DragDropTarget {
  region: DragDropRegion;
  // type Filesystem (parent of Document and Folder) in region TableRow
  // type FolderNode in region FolderMenuNode
  // type Inbox, Archive, or Trash in region OtherSidebarMenu
  value: Filesystem | FolderNode | 'Inbox' | 'Archive' | 'Trash';
}

@Injectable({
  providedIn: 'root'
})
export class DragDropService {
  target: DragDropTarget;
  isDragging = false;

  constructor(
    private snackbar: MatSnackBar,
    private folderActions: FolderActionsService,
    private folderReference: FolderReferenceService
  ) {}

  /**
   * Handling for when rows are dropped on to a row in the table.
   * The drop target must be a folder in order for the move action to be performed.
   * Also prevents users from moving a folder within itself.
   *
   * @param selectedRows the rows that are being drag/dropped
   */
  async dropOnTableRow(selectedRows: Filesystem[]): Promise<boolean> {
    if ((this.target.value as Filesystem).type !== FilesystemType.Folder) return false;
    if (selectedRows.includes(this.target.value as Filesystem)) {
      this.dropRejectedSnackbar('You cannot move a folder within itself.');
      return false;
    }
    await this.folderActions.moveFiles(selectedRows, this.target.value as Filesystem);
    return true;
  }

  /**
   * Handling for when rows are dropped on a folder node in the sidebar folder menu.
   * Does not allow folder rows to be dropped into another folder in the sidebar folder menu
   * because we can not be sure if the destination is a descendant of one of the folders in selectedRows.
   * We would not want to move a folder within one of its descendants because this could cause an infinite loop
   *
   * @param selectedRows the rows that are being drag/dropped
   */
  async dropOnFolderMenuNodes(selectedRows: Filesystem[]): Promise<boolean> {
    if (selectedRows.find((row) => row.type === FilesystemType.Folder)) {
      this.dropRejectedSnackbar('You cannot move a folder by dragging it to the sidebar (use the Move button).');
      return false;
    }
    await this.folderActions.moveFiles(selectedRows, this.target.value as FolderNode);
    return true;
  }

  /**
   * Handling for when rows are dropped on the Inbox, Archive, or Trash.
   * Prevent folders from being moved to the Inbox as this view does not support folders.
   * Otherwise, all other selections here are valid.
   *
   * @param selectedRows the rows that are being drag/dropped
   */
  async dropOnOtherSidebarMenu(selectedRows: Filesystem[]): Promise<boolean> {
    switch (this.target.value) {
      case 'Inbox':
        if (selectedRows.find((row) => row.type === FilesystemType.Folder)) {
          this.dropRejectedSnackbar('You cannot move a folder to the Inbox.');
          return false;
        }
        const inbox = await this.folderReference.getInbox();
        const inboxNode = new FolderNode(inbox.id, 'Inbox');
        await this.folderActions.moveFiles(selectedRows, inboxNode);
        return true;
      case 'Archive':
        return this.folderActions.archiveFiles(selectedRows, true);
      case 'Trash':
        return this.folderActions.deleteFiles(selectedRows);
    }
  }

  /**
   * Shows error snackbar with message if the drag/drop target is rejected
   *
   * @param message the error message to display
   */
  dropRejectedSnackbar(message: string) {
    this.snackbar.open(message, '', {
      duration: 5000,
      panelClass: ['alert-bg', 'bold', 'light-color']
    });
  }
}
