import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { HttpParamsHelper } from '@api/http-params-helper';
import { Document, DocumentsGetParams, PaginationGet } from '@api/types';

@Injectable({
  providedIn: 'root'
})
export class SharedDocsService {
  constructor(private http: HttpClient) {}

  /**
   * Get the documents that have been shared with this account
   *
   * @summary `GET /accounts/{account_id}/shared`
   */
  get(accountId: string, params: DocumentsGetParams): Observable<PaginationGet<Document>> {
    return this.http.get<PaginationGet<Document>>(`/accounts/${accountId}/shared`, {
      params: HttpParamsHelper.getHttpQueryParams(params)
    });
  }
}
