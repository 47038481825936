import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpParamsHelper } from '@api/http-params-helper';
import { DocumentsGetParams, Filesystem, PaginationGet } from '@api/types';
import { Observable } from 'rxjs';
import { TrashByIdService } from './byId/trash-by-id.service';

@Injectable({
  providedIn: 'root'
})
export class TrashService {
  constructor(public byId: TrashByIdService, private http: HttpClient) {}

  /**
   * Gets all the trash that has not yet been purged
   *
   * @summary `GET /filesystem/trash`
   */
  get(params?: DocumentsGetParams): Observable<PaginationGet<Filesystem>> {
    return this.http.get<PaginationGet<Filesystem>>(`/filesystem/trash`, {
      params: HttpParamsHelper.getHttpQueryParams(params)
    });
  }
}
