import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Folder } from '@api/types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FolderByIdService {
  constructor(private http: HttpClient) {}

  /**
   * Gets specific deleted folder
   *
   * @summary `GET /folders/trash/{folder_id}`
   */
  get(folderId: string): Observable<Folder> {
    return this.http.get<Folder>(`/folders/trash/${folderId}`);
  }

  /**
   * Restores deleted folder
   *
   * @summary `PATCH /folders/trash/{folder_id}`
   */
  patch(folderId: string): Observable<Folder> {
    return this.http.patch<Folder>(`/folders/trash/${folderId}`, {});
  }

  /**
   * // is this wrong? this puts it in trash
   * soft-deletes specific folder
   *
   * @summary `DELETE /folders/trash/{folder_id}`
   */
  delete(folderId: string): void {
    this.http.delete(`/folders/trash/${folderId}`);
  }
}
