import { Injectable } from '@angular/core';

import { HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { ApiService } from '@api/api.service';
import { VerificationRequest } from '@api/oauth/token/token.service';
import { OauthTokenRequestGrantType, SingleToken } from '@api/types';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '@env/environment';
import { EchoService } from './echo.service';
import { EncryptionHelperService } from './encryption-helper.service';
import { FolderReferenceService } from './folder-reference.service';
import { LocalStorageService } from './local-storage.service';
import { SessionStorageService } from './session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  private jwtHelper: JwtHelperService = new JwtHelperService();

  constructor(
    private api: ApiService,
    private localStorage: LocalStorageService,
    private sessionStorage: SessionStorageService,
    private router: Router,
    private echo: EchoService,
    private folderReference: FolderReferenceService,
    private encryptionHelper: EncryptionHelperService
  ) {}

  isExpired(token: string): boolean {
    return this.jwtHelper.isTokenExpired(token);
  }

  /**
   * Revokes the user's access and logs out of the app.
   */
  async revokeAccessAndLogout(): Promise<void> {
    this.api.oauth.tokens.delete(await this.myToken()).subscribe(() => this.logout(true));
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage('LOGOUT_REQUEST');
    }
  }

  /**
   * Logs the user out of their account by deleting storage details and navigating to the homepage.
   */
  logout(isTokenDeleted = false) {
    this.folderReference.cleanup();
    this.localStorage.deletePasswordToken();
    this.localStorage.deleteUserWithAccount();
    this.localStorage.deleteLastSearch();
    this.localStorage.deleteUserPreferences();
    this.encryptionHelper.clearKeys();
    this.echo.destroy();
    const successMsg = { message: 'Logged out successfully', type: 'success' };
    const warningMsg = { message: 'Unable to authenticate', type: 'warning' };
    this.router.navigate(['/auth/login'], {
      queryParams: isTokenDeleted ? successMsg : warningMsg
    });
  }

  /**
   * Generates the Client Credential Token
   *
   * Checking if the token doesn't already exist or is still valid.
   * A post request is made and the client credential token is stored.
   */
  async generateClientCredentialToken() {
    if (this.localStorage.clientCredentialsToken && !this.isExpired(this.localStorage.clientCredentialsAccessToken)) {
      return;
    }

    await this.api.oauth.token
      .post({
        client_id: environment.api.client.id,
        client_secret: environment.api.client.secret,
        grant_type: OauthTokenRequestGrantType.ClientCredentials,
        scope: '*'
      })
      .toPromise()
      .then((response: HttpResponse<SingleToken | VerificationRequest>) => {
        this.localStorage.clientCredentialsToken = <SingleToken>response.body;
      });
  }

  /**
   * Gets my access token I'm currently using.
   */
  private async myToken(): Promise<string> {
    if (this.localStorage.userWithToken) return this.localStorage.userWithToken.accessToken.id;
    return this.api.users.me
      .get()
      .toPromise()
      .then((me) => me.accessToken.id);
  }
}
