import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DocumentByVersionService {
  constructor(private http: HttpClient) {}

  /**
   * Download the specified document by version
   *
   * @summary `GET /documents/{document_id}/v/{version}`
   */
  get(documentId: string, version: string): Observable<any> {
    return this.http.get(`/documents/${documentId}/v/${version}`, {
      headers: new HttpHeaders({ Accept: 'application/pdf' }),
      responseType: 'blob',
      observe: 'response'
    });
  }

  /**
   * Update a document's version.
   *
   * @summary `PATCH /documents/{document_id}/v/{version}`
   */
  patch(documentId: string, version: string): Observable<object> {
    return this.http.patch(`/documents/${documentId}/v/${version}`, {});
  }
}
