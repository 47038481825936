import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';

/**
 * Validates that the password matches the confirm password field
 */
@Injectable()
export class PasswordValidator {
  static MatchPassword(control: AbstractControl) {
    const password = control.get('password').value;
    const confirmPassword = control.get('password_confirmation').value;

    if (password !== confirmPassword) {
      control.get('password_confirmation').setErrors({ confirm_password: true });
    } else {
      control.get('password_confirmation').setErrors(null);
    }
  }

  static MatchPass(control: AbstractControl) {
    const password = control.get('pass').value;
    const confirmPassword = control.get('pass_conf').value;

    if (password !== confirmPassword) {
      control.get('pass_conf').setErrors({ confirm_password: true });
    } else {
      control.get('pass_conf').setErrors(null);
    }
  }
}
