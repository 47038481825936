import { Component, Input } from '@angular/core';

type LoaderType = 'global' | 'common' | 'icon';

@Component({
  selector: 'rav-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {
  @Input() type: LoaderType = 'common';
  constructor() {}
}
