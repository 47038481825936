import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { DeletedUser } from '@api/types';

@Injectable({
  providedIn: 'root'
})
export class UsersTrashService {
  constructor(private http: HttpClient) {}

  /**
   * All deleted users for a given account.
   *
   * @summary `GET /accounts/{account_id}/users/trash`
   */
  get(accountId: string): Observable<DeletedUser[]> {
    return this.http.get<DeletedUser[]>(`/accounts/${accountId}/users/trash`);
  }
}
