import { Injectable } from '@angular/core';

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { TokenService } from '@shared/services/token.service';
import { Observable } from 'rxjs';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
  constructor(private localStorage: LocalStorageService, private token: TokenService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const skipAuthorization = request.headers.get('skipAuthorization') === 'true';
    if (skipAuthorization) {
      const newReq = request.clone({ headers: request.headers.delete('skipAuthorization') });
      return next.handle(newReq);
    }

    const clonedRequest = request.clone({
      setHeaders: {
        Authorization: `Bearer ${
          this.localStorage.passwordAccessToken || this.localStorage.clientCredentialsAccessToken
        }`
      }
    });
    return next.handle(clonedRequest);
  }
}
