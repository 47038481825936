import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Contact } from '@api/types';
import { PaginatedGetParams } from '@shared/types/paginated-get-params';
import { Observable } from 'rxjs';
import { ContactByIdService } from './byId/contact.service';

@Injectable({
  providedIn: 'root'
})
export class ContactsService {
  constructor(private http: HttpClient, public byId: ContactByIdService) {}

  /**
   * Gets the users saved contacts
   *
   * @summary `GET /users/{userId}/contacts`
   */
  getContacts(userId: string, params?: any): Observable<PaginatedGetParams[]> {
    return this.http.get<any[]>(`/users/${userId}/contacts`, { params });
  }

  /**
   * Posts a new contact
   *
   * @summary `POST /users/{userId}/contacts`
   */
  post(userId: string, body: Contact): Observable<any> {
    return this.http.post(`/users/${userId}/contacts`, body);
  }

  /**
   * POST request to upload contacts from a CSV file
   * at: /users/{userId}/contacts/import
   */
  importContacts(userId: string, csvFile: File) {
    const formData = new FormData();
    formData.append('contacts', csvFile, csvFile.name);
    return this.http.post(`/users/${userId}/contacts/import`, formData);
  }
}
