import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ValidService {
  constructor(private http: HttpClient) {}

  /**
   * Check if given account is a valid account.
   *
   * @summary `GET /accounts/{account_id}/valid`
   */
  get(accountId: string): Observable<boolean> {
    return this.http.get<boolean>(`/accounts/${accountId}/valid`);
  }
}
