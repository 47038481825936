import { DocumentTag } from './document_tag';
import { Filesystem } from './filesystem';

export enum DocumentSource {
  Scanner = 'scanner',
  Import = 'import',
  Email = 'email',
  Fax = 'fax'
}

export enum DocumentProcessingStatus {
  Completed = 'Completed',
  Error = 'Error',
  Pending = 'Pending'
}

/**
 * Document
 *
 * A Raven Cloud Document
 */
export interface Document extends Filesystem {
  from?: string;
  page_ids: string[];
  version: string;
  export?: string;
  source: DocumentSource;
  description?: string;
  processing_status: DocumentProcessingStatus;
  folder_id: string;
  control_number?: string;
  public_id?: string;
  author_id: string;
  template_id: string;
  template_name: string;
  viewed_at: string;
  ocr?: boolean;
  size_in_mb?: number;
  mime_type?: string;
  encrypted?: boolean;
  tags?: DocumentTag[];
}
