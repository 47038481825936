import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationStart,
  Router,
  RoutesRecognized
} from '@angular/router';
import { ApiService } from '@api/api.service';
import { AccountE2eEncryption, UserWithToken } from '@api/types';
import { EchoService } from '@shared/services/echo.service';
import { EncryptionHelperService } from '@shared/services/encryption-helper.service';
import { ErrorTrackingService } from '@shared/services/error-tracking.service';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { SessionStorageService } from '@shared/services/session-storage.service';
import { TokenService } from '@shared/services/token.service';
import { StatusCodes } from '@shared/types/status-codes';
import { Subscription } from 'rxjs';
import { filter, map, mergeMap, pairwise } from 'rxjs/operators';
import { GoogleTagMangerService } from './google-tag-manager.service';

@Component({
  selector: 'rav-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  loading: boolean;
  private lastUrlSubscription: Subscription;
  private titleRouterSubscription: Subscription;
  private pageViewRouterSubscription: Subscription;
  private pageLoadingSubscription: Subscription;
  private isLoggedIn = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private localStorage: LocalStorageService,
    private googleTagManager: GoogleTagMangerService,
    private router: Router,
    private title: Title,
    private token: TokenService,
    private api: ApiService,
    private errorTracking: ErrorTrackingService,
    private encryptionHelper: EncryptionHelperService,
    private sessionStorage: SessionStorageService,
    private echo: EchoService
  ) {
    window.addEventListener('keydown', this.handleFirstTab);
    this.loading = true;
    this.listenPreviousUrl();
  }

  async ngOnInit() {
    await this.token.generateClientCredentialToken(); // This token is required for the application to work.
    this.setTitle();
    this.triggerPageViewEvent();
    this.isLoggedIn = this.localStorage.userWithToken !== null;
    await this.initialize();
  }

  ngAfterViewInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.loading = true;
      } else if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
        this.loading = false;
      }
    });
  }

  ngOnDestroy() {
    if (this.lastUrlSubscription) this.lastUrlSubscription.unsubscribe();
    if (this.titleRouterSubscription) this.titleRouterSubscription.unsubscribe();
    if (this.pageViewRouterSubscription) this.pageViewRouterSubscription.unsubscribe();
    if (this.pageLoadingSubscription) this.pageLoadingSubscription.unsubscribe();
  }

  /**
   * Browser outlines are hidden via css (see 'user-is-tabbing' class use). Upon the first tab, browser outlines are shown
   * so that there are no accessibility concerns. We then stop listening for tab presses.
   *
   * @param e the key press event
   */
  private handleFirstTab(e) {
    if (e.keyCode === 9) {
      // the "I am a keyboard user" key
      document.body.classList.add('user-is-tabbing');
      window.removeEventListener('keydown', this.handleFirstTab);
    }
  }

  /**
   * Sets the HTML <title> when the route changes
   */
  private setTitle() {
    this.titleRouterSubscription = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data)
      )
      .subscribe((event) => this.title.setTitle(event['title'] ? `${event['title']} - Raven` : 'Raven'));
  }

  /**
   * Triggers page view events to the Google Tag Manager
   *
   * Uses the router events and filters out any event that is not NavigationEnd
   * and triggers a pageview event and passes along the current url.
   */
  private triggerPageViewEvent(): void {
    this.pageViewRouterSubscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.googleTagManager.trigger('app.pageview', {
          appUrl: event.urlAfterRedirects
        });
      });
  }

  /**
   * Uses the router events to get the previous URL and save it in local storage
   */
  private listenPreviousUrl(): void {
    this.lastUrlSubscription = this.router.events
      .pipe(
        filter((e: any) => e instanceof RoutesRecognized),
        pairwise(),
        map((e: [RoutesRecognized, RoutesRecognized]) => e[0].url)
      )
      .subscribe((previousUrl) => {
        this.localStorage.previousUrl = previousUrl;
      });
  }

  private async initialize(): Promise<void> {
    try {
      let userData: UserWithToken | void;
      if (this.isLoggedIn) {
        userData = await this.api.users.me
          .get()
          .toPromise()
          .catch((_error: HttpErrorResponse) => {
            if (_error.status === StatusCodes.UNAUTHORIZED || _error.status === StatusCodes.NOT_FOUND) {
              this.isLoggedIn = false;
            }
          });
        if (userData) {
          this.localStorage.userWithToken = userData;
          this.setE2eEncryptionStatus(userData.account.e2e_encryption);
        }
      }
      await this.errorTracking.setup(userData ? userData.email : undefined);
    } catch (error) {
      console.error('Error during initialization:', JSON.stringify(error));
    }
    return Promise.resolve();
  }

  /**
   * Sets the E2e Encryption feature status, if enabled it validates if the necessary keys are present,
   * otherwise it logs the user out.
   *
   * @param status encryption 'Active', 'Inactive' status
   */
  private setE2eEncryptionStatus(status: AccountE2eEncryption) {
    this.encryptionHelper.accountEncryptionStatus.next(status);
    if (status === AccountE2eEncryption.Active && this.sessionStorage.areKeysEmpty()) this.token.logout();
    if (status === AccountE2eEncryption.Activating || status === AccountE2eEncryption.Deactivating) {
      this.echo.encryptionDecryptionProgressListener(status);
    }
  }
}
