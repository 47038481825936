import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Message, TwoFADisable } from '@api/types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DisableService {
  constructor(private http: HttpClient) {}

  /**
   * Disables 2FA for the user, if the correct token is given
   *
   * @summary `PATCH /users/2fa/disable`
   */
  patch(body: TwoFADisable): Observable<Message> {
    return this.http.patch<Message>('/users/2fa/disable', body);
  }
}
