import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { Email } from '@api/types';

@Injectable({
  providedIn: 'root'
})
export class SendService {
  constructor(private http: HttpClient) {}

  // TODO- return text/html response?
  /**
   * Users can request for a new activation token for unactive account.
   *
   * @summary `POST /accounts/activation/send`
   */
  post(body: Email): void {
    this.http.post('/accounts/activation/send', body);
  }
}
