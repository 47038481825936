import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InboxCountService {
  total$: Observable<number>;
  private totalSubject: BehaviorSubject<number> = new BehaviorSubject<number>(undefined);

  constructor() {
    this.total$ = this.totalSubject.asObservable();
  }

  /**
   * Emits a change event on the number of documents in the inbox.
   *
   * @param total Total files in inbox
   */
  updateInboxCount(total: number): void {
    this.totalSubject.next(total);
  }

  /**
   * Adds one to the current count of files in the inbox.
   */
  addOneToCurrentInboxCount(): void {
    const currentValue = this.totalSubject.getValue();
    if (currentValue) this.totalSubject.next(currentValue + 1);
  }
}
