import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export enum HttpMethod {
  GET,
  POST,
  PUT,
  DELETE
}

export class PendingRequest {
  url: string;
  method: HttpMethod;
  body: any;
  options: any;
  subject: Subject<any>;

  constructor(url: string, method: HttpMethod, body: any, options: any, subject: Subject<any>) {
    this.url = url;
    this.body = body;
    this.method = method;
    this.options = options;
    this.subject = subject;
  }
}

@Injectable()
export class HttpQueueService {
  private queue: PendingRequest[] = [];
  private executionCounter = 0;
  private QUEUE_SIZE = 10;

  constructor(private http: HttpClient) {}

  /**
   * Adds http request to queue and returns subject to listen for results
   */
  invoke(url: string, method: HttpMethod, body?: any, options?: any): Subject<any> {
    return this.addRequestToQueue(url, method, body, options);
  }

  /**
   * Executes next request object from queue. Currently only implemented post methods
   * since that is all that is being used, but can later be expanded to include other http methods.
   *
   * @param request Next request object from queue to execute
   */
  private execute(request: PendingRequest): void {
    switch (request.method) {
      case HttpMethod.GET: {
        this.http.get(request.url, request.options).subscribe((response) => {
          this.onRequestFinished(request, response);
        });
        break;
      }
      case HttpMethod.POST: {
        this.http.post(request.url, request.body, request.options).subscribe((response) => {
          this.onRequestFinished(request, response);
        });
        break;
      }
    }
  }

  /**
   * When request finishes, send response to subscriptions of subject and
   * start the next request.
   */
  private onRequestFinished(request: PendingRequest, response: any) {
    const subject = request.subject;
    subject.next(response);
    this.executionCounter--;
    this.startNextRequest();
  }

  /**
   * Creates new request object and adds it to the queue
   *
   * @param url request url
   * @param method http method to perform (get, put, post, delete, etc.)
   * @param body request body
   * @param options request options
   */
  private addRequestToQueue(url: string, method: HttpMethod, body?: any, options?: any): Subject<any> {
    const subject = new Subject<any>();
    const request = new PendingRequest(url, method, body, options, subject);

    this.queue.push(request);
    this.startNextRequest();
    return subject;
  }

  /**
   * If there are less than 5 requests currently being executed, executes next request.
   * Otherwise, we wait until next request is finished to start the next request.
   */
  private startNextRequest(): void {
    if (this.executionCounter >= this.QUEUE_SIZE) return;
    const nextRequest = this.queue.shift();
    if (nextRequest) {
      this.executionCounter++;
      this.execute(nextRequest);
    }
  }
}
